import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import { FixedSizeList as List } from "react-window";
// import AutoSizer from "react-virtualized-auto-sizer";

// import "./styles.css";
import "./sublist.component.scss";
import {
  setAssetInfo,
  setAssetPayload,
} from "../../store/asset-list/asset-list.slice";
import { assetInfoActions } from "../../store/asset-list/asset-list.action";
import playIconWhite from "../../assets/images/play-button-white.svg";
import playIconDark from "../../assets/images/play-button-dark.svg";
import { useDispatch } from "react-redux";
import { useAppSelector, useAppDispatch } from "../../store";
import { setSelectedId } from "../../store/seqr-ops/seqr-ops.slice";
import { encryptUtils } from "../../utils/encryption.utils";
import { sessionStorageUtils } from "../../utils/session-storage.utils";

export type seg = {
  type: string;
  operator: string;
  value: string;
  logicalOperator: string;
};
export type Props = {
  seg: Array<seg>;
  index: number;
  segment: seg;
  removeSegment: (index: number) => void;
};
function SubListComponent(props: any) {
  let getAssetPayload = useAppSelector((state) => state.AssetInfo.assetPayload);
  const theme = useAppSelector((state) => state.seqrOps.theme);
  const selectedId = useAppSelector((state) => state.seqrOps.selectedId);
  const [selectedIdState, setSelectedIdState] = useState<string>("");
  const [buttonStatus, setButtonStatus] = useState<string>("true");
  const dispatch = useAppDispatch();
  const onButtonClick = (data: any) => {
    if (
      data?.type === "Substation" ||
      data?.type === "VoltageLevel" ||
      data?.type === "Bay"
    ) {
      dispatch(setSelectedId(data?.rid));
    }
    props.onButtonClick(data);

    sessionStorageUtils.setLocalStorage("sid", { id: data?.rid });
  };

  useEffect(() => {
    if (
      selectedId != "" &&
      sessionStorageUtils.getLocalStorage("sid") != null &&
      sessionStorageUtils.getLocalStorage("sid") != undefined &&
      ["VoltageLevel", "Bay", "Substation"].includes(
        props?.masterArray[0]?.type
      )
    ) {
      const decryptedId = sessionStorageUtils.getLocalStorage("sid");
      setSelectedIdState(decryptedId?.id);
    } else if (
      ["VoltageLevel", "Bay", "Substation"].includes(
        props?.masterArray[0]?.type
      )
    ) {
      setSelectedIdState(props?.masterArray[0].rid);
    }
  }, [selectedId]);

  // const getItemSize = (index:any) => {
  //   console.log(props.masterArray.length)
  //   if(props.masterArray.length < 5) {
  //     return 50
  //   } else {
  //     return 50

  //   }
  // };

  const queryBuild = (data: any) => {
    let list = [
      ...getAssetPayload,
      ...[
        {
          type: data.type,
          operator: "EXACT_MATCH",
          value: data.name,
          logicalOperator: "AND",
          rid: data.rid,
        },
      ],
    ];

    assetInfoActions.getAssetInfo(list).then((response) => {
      if (response.length == 1) {
        setButtonStatus("false");
      } else {
        setButtonStatus("true");
      }
      dispatch(setAssetInfo(response));
      props.onBuildClick(data);
      dispatch(setAssetPayload(list));
    });
  };
  const checkLeafnode = (type: string, index: number) => {
    const leafNodes = [
      "EnergyConsumer",
      "PowerTransformer",
      "BatteryUnit",
      "PhotoVoltaicUnit",
      "Recloser",
      "Fuse",
      "LoadBreakSwitch",
      "Breaker",
      "DisconnectingCircuitBreaker",
      "RegulatingControl",
      "ACLineSegment",
      "PowerTransformerEnd",
    ];
    if (
      leafNodes.includes(type) &&
      props?.masterArray.length == 1 &&
      props.mainListLength == 1
    ) {
      return null;
    } else {
      return (
        <span
          style={{ float: "right" }}
          onClick={(e) => {
            e.stopPropagation();
            queryBuild(props?.masterArray[index]);
          }}
        >
          <button
            className="listitem"
            style={{ backgroundColor: "transparent", border: "none" }}
          >
            <img
              src={theme ? playIconWhite : playIconDark}
              width={15}
              height={15}
            ></img>
          </button>
        </span>
      );
    }
  };
  const Row = ({ index, style }: any) => (
    <div
      className={
        selectedIdState === props?.masterArray[index].rid
          ? "sidebar-title-wrapper-active"
          : "sidebar-title-wrapper"
      }
      style={{
        ...style,
        textAlign: "left",
        padding: "1% 4%",
        marginTopS: 30,
        textWrap: "nowrap",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      onClick={() => onButtonClick(props?.masterArray[index])}
    >
      <span
        className="seqrops-query-section-text"
        style={{ fontSize: "small", cursor: "pointer" }}
      >
        {props?.masterArray[index].name?.replaceAll("tr(","").replaceAll(")","")}
      </span>
      {checkLeafnode(props?.masterArray[index].type, index)}
    </div>
  );
  return (
    <List
      className="ListItem"
      height={300}
      itemCount={props?.masterArray?.length}
      itemSize={50}
      width={"100%"}
    >
      {Row}
    </List>
  );
}
export default SubListComponent;
