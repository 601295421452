import moment from "moment";
const _properties = [
  {
    key: "total_site_electricity",
    color: "#196B24",
    labelKw: "Total kW",
    labelKvar: "Total kVar",
  },
  {
    key: "heating",
    color: "#0F9ED5",
    labelKw: "Healting kW",
    labelKvar: "Healting kVar",
  },
  {
    key: "cooling",
    color: "#A02B93",
    labelKw: "Cooling kW",
    labelKvar: "Cooling kVar",
  },
  {
    key: "lighting",
    color: "#4EA72E",
    labelKw: "Lighting kW",
    labelKvar: "Lighting kVar",
  },
  {
    key: "fans",
    color: "#8599AA",
    labelKw: "Fans kW",
    labelKvar: "Fans kVar",
  },
  {
    key: "pumps",
    color: "#EEA18A",
    labelKw: "Pumps kW",
    labelKvar: "Pumps kVar",
  },
  {
    key: "water_systems",
    color: "#869E87",
    labelKw: "Water Systems kW",
    labelKvar: "Water Systems kVar",
  },
  {
    key: "refrigeration",
    color: "#85BAE0",
    labelKw: "Refrigeration kW",
    labelKvar: "Refrigeration kVar",
  },
  {
    key: "motors",
    color: "#BC89B4",
    labelKw: "Motors kW",
    labelKvar: "Motors kVar",
  },
  {
    key: "plug_loads",
    color: "#92C089",
    labelKw: "Plug Loads kW",
    labelKvar: "Plug Loads kVar",
  },
  {
    key: "clothes_dryer",
    color: "#B5BFC8",
    labelKw: "Clothes Dryer kW",
    labelKvar: "Clothes Dryer kVar",
  },
  {
    key: "clothes_washer",
    color: "#F3C4B8",
    labelKw: "Clothes Washer kW",
    labelKvar: "Clothes Washer kVar",
  },
  {
    key: "dishwasher",
    color: "#B5D2EA",
    labelKw: "Dish Washer kW",
    labelKvar: "Dish Washer kVar",
  },
  {
    key: "stove",
    color: "#B6C2B6",
    labelKw: "Stoves kW",
    labelKvar: "Stoves kVar",
  },
];

function formatString(str: string, ...val: any[]) {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}

function getRandomColor(alpha: any) {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

function generateXAxisLabels() {
  const labels: string[] = [];
  for (let hour = 1; hour <= 24; hour++) {
    let formattedHour = hour.toString().padStart(2, "0") + ":00";
    labels.push(formattedHour);
  }
  return labels;
}
const consumerChartProperties = [
  {
    key: "total_site_electricity",
    color: "#196B24",
    labelKw: "Total kW",
    labelKvar: "Total kVar",
  },
  {
    key: "heating",
    color: "#0F9ED5",
    labelKw: "Healting kW",
    labelKvar: "Healting kVar",
  },
  {
    key: "cooling",
    color: "#A02B93",
    labelKw: "Cooling kW",
    labelKvar: "Cooling kVar",
  },
  {
    key: "lighting",
    color: "#4EA72E",
    labelKw: "Lighting kW",
    labelKvar: "Lighting kVar",
  },
  {
    key: "fans",
    color: "#8599AA",
    labelKw: "Fans kW",
    labelKvar: "Fans kVar",
  },
  {
    key: "pumps",
    color: "#EEA18A",
    labelKw: "Pumps kW",
    labelKvar: "Pumps kVar",
  },
  {
    key: "water_systems",
    color: "#869E87",
    labelKw: "Water Systems kW",
    labelKvar: "Water Systems kVar",
  },
  {
    key: "refrigeration",
    color: "#85BAE0",
    labelKw: "Refrigeration kW",
    labelKvar: "Refrigeration kVar",
  },
  {
    key: "motors",
    color: "#BC89B4",
    labelKw: "Motors kW",
    labelKvar: "Motors kVar",
  },
  {
    key: "plug_loads",
    color: "#92C089",
    labelKw: "Plug Loads kW",
    labelKvar: "Plug Loads kVar",
  },
  {
    key: "clothes_dryer",
    color: "#B5BFC8",
    labelKw: "Clothes Dryer kW",
    labelKvar: "Clothes Dryer kVar",
  },
  {
    key: "clothes_washer",
    color: "#F3C4B8",
    labelKw: "Clothes Washer kW",
    labelKvar: "Clothes Washer kVar",
  },
  {
    key: "dishwasher",
    color: "#B5D2EA",
    labelKw: "Dish Washer kW",
    labelKvar: "Dish Washer kVar",
  },
  {
    key: "stove",
    color: "#B6C2B6",
    labelKw: "Stoves kW",
    labelKvar: "Stoves kVar",
  },
];
const getCustomerChartProperties = (
  consumerLoads: any,
  selection: string[] = []
) => {
  const kwDataset = [];
  const kvarDataset = [];

  const _consumerChartProperties = [];
  if (selection.length > 0) {
    for (let i = 0; i < consumerChartProperties.length; i++) {
      if (selection.includes(consumerChartProperties[i].key)) {
        _consumerChartProperties.push(consumerChartProperties[i]);
      }
    }
  } else {
    for (let i = 0; i < consumerChartProperties.length; i++) {
      _consumerChartProperties.push(consumerChartProperties[i]);
    }
  }

  for (let i = 0; i < _consumerChartProperties.length; i++) {
    const kw: any = {
      label: _consumerChartProperties[i].labelKw,
      data: [],
      borderColor: _consumerChartProperties[i].color,
      borderWidth: 2,
      fill: false,
      pointRadius: 1,
      pointHoverRadius: 1,
    };

    const kvar: any = {
      label: _consumerChartProperties[i].labelKvar,
      data: [],
      borderColor: _consumerChartProperties[i].color,
      borderWidth: 2,
      fill: false,
      pointRadius: 1,
      pointHoverRadius: 1,
    };

    if (consumerLoads.length === 0) {
      kvar.data.push((0).toFixed(2));
      kw.data.push((0).toFixed(2));
    }

    for (let j = 0; j < consumerLoads.length; j++) {
      const kvar_value: any =
        consumerLoads[j][_consumerChartProperties[i].key + "_kvar"];
      const kw_value: any =
        consumerLoads[j][_consumerChartProperties[i].key + "_kw"];
      kvar.data.push({
        x: moment(consumerLoads[j].time).utc().local().toDate(),
        y: kvar_value.toFixed(2),
      });
      kw.data.push({
        x: moment(consumerLoads[j].time).utc().local().toDate(),
        y: kw_value.toFixed(2),
      });
    }

    kwDataset.push(kw);
    kvarDataset.push(kvar);
  }

  return { kwDataset, kvarDataset };
};
const getPvChartProperties = (pvData: any) => {
  const irradiance: any = {
    label: "PV System",
    data: [],
    borderColor: "#E97132",
    borderWidth: 2,
    fill: false,
    pointRadius: 1,
    pointHoverRadius: 1,
  };
  const temperature: any = {
    label: "PV System",
    data: [],
    borderColor: "#E97132",
    borderWidth: 2,
    fill: false,
    pointRadius: 1,
    pointHoverRadius: 1,
  };
  if (pvData.length === 0) {
    irradiance.data.push((0).toFixed(2));
    temperature.data.push((0).toFixed(2));
  }
  const irradianceDataset = [];
  const temperatureDataset = [];
  for (let i = 0; i < pvData.length; i++) {
    irradiance.data.push({
      x: moment(pvData[i].time).utc().local().toDate(),
      y: (pvData[i].irradiance || 0).toFixed(2),
    });
    temperature.data.push({
      x: moment(pvData[i].time).utc().local().toDate(),
      y: (pvData[i].temperature || 0).toFixed(2),
    });
  }
  irradianceDataset.push(irradiance);
  temperatureDataset.push(temperature);
  return { irradianceDataset, temperatureDataset };
};

const getConnectivityChartProperties = (connectivityData: any) => {
  const current: any = {
    label: "Connectivity Node",
    data: [],
    borderColor: "#156082",
    borderWidth: 2,
    fill: false,
    pointRadius: 1,
    pointHoverRadius: 1,
  };
  const voltage: any = {
    label: "Line",
    data: [],
    borderColor: "#156082",
    borderWidth: 2,
    fill: false,
    pointRadius: 1,
    pointHoverRadius: 1,
  };

  const currentDataset = [];
  const voltageDataset = [];

  if (connectivityData.length === 0) {
    current.data.push((0).toFixed(2));
    voltage.data.push((0).toFixed(2));
  }
  for (let i = 0; i < connectivityData.length; i++) {
    current.data.push({
      x: moment(connectivityData[i].time).utc().local().toDate(),
      y: (connectivityData[i].current || 0).toFixed(2),
    });
    voltage.data.push({
      x: moment(connectivityData[i].time).utc().local().toDate(),
      y: (connectivityData[i].voltage || 0).toFixed(2),
    });
  }
  currentDataset.push(current);
  voltageDataset.push(voltage);
  return { currentDataset, voltageDataset };
};
const getConsumerStorageChartProperties = (data: any) => {
  const kw: any = {
    label: "Kw",
    data: [],
    borderColor: "#0F9ED5",
    borderWidth: 2,
    fill: false,
    pointRadius: 1,
    pointHoverRadius: 1,
    yAxisID: "KW",
  };
  const kvar: any = {
    label: "kVar",
    data: [],
    borderColor: "#196B24",
    borderWidth: 2,
    fill: false,
    pointRadius: 1,
    pointHoverRadius: 1,
    yAxisID: "KVAR",
  };

  const dataset = [];

  if (data.length === 0) {
    kw.data.push((0).toFixed(2));
    kvar.data.push((0).toFixed(2));
  }
  for (let i = 0; i < data.length; i++) {
    kw.data.push({
      x: moment(data[i].time).utc().local().toDate(),
      y: (data[i].kw || 0).toFixed(2),
    });
    kvar.data.push({
      x: moment(data[i].time).utc().local().toDate(),
      y: (data[i].kVar || 0).toFixed(2),
    });
  }
  dataset.push(kw);
  dataset.push(kvar);
  return { dataset };
};

const getTransformerConnectivityChartProperties = (connectivityData: any) => {
  const currentDataset = [];
  const voltageDataset = [];
  for (let i = 0; i < connectivityData.length; i++) {
    const current: any = {
      label: connectivityData[i]?.name,
      data: [],
      borderColor: "#156082",
      borderWidth: 2,
      fill: false,
      pointRadius: 1,
      pointHoverRadius: 1,
    };
    const voltage: any = {
      label: connectivityData[i]?.name,
      data: [],
      borderColor: "#156082",
      borderWidth: 2,
      fill: false,
      pointRadius: 1,
      pointHoverRadius: 1,
    };
    for (let j = 0; j < connectivityData[i].data.length; j++) {
      const item = connectivityData[i].data[j];
      voltage.borderColor =
        _properties[_properties.length - i]?.color || "#156082";
      current.borderColor =
        _properties[_properties.length - i]?.color || "#156082";
      voltage.data.push({
        x: moment(item.time).utc().local().toDate(),
        y: (item.voltage || 0).toFixed(2),
      });
      current.data.push({
        x: moment(item.time).utc().local().toDate(),
        y: (item.current || 0).toFixed(2),
      });
    }

    voltageDataset.push(voltage);
    currentDataset.push(current);
  }
  return { currentDataset, voltageDataset };
};

const getTransformerLineChartProperties = (connectivityData: any) => {
  const currentDataset = [];

  for (let i = 0; i < connectivityData.length; i++) {
    const current: any = {
      label: connectivityData[i]?.name,
      data: [],
      borderColor: "#156082",
      borderWidth: 2,
      fill: false,
      pointRadius: 1,
      pointHoverRadius: 1,
    };
    for (let j = 0; j < connectivityData[i].data.length; j++) {
      const item = connectivityData[i].data[j];
      current.borderColor = _properties[i]?.color || "#156082";
      current.data.push({
        x: moment(item.time).utc().local().toDate(),
        y: (item.current || 0).toFixed(2),
      });
    }

    currentDataset.push(current);
  }

  return { currentDataset };
};

const getMapViewCircuit = (data: any) => {
  const properties = [
    {
      key: "totalcircuitpower",
      color: "#156082",
      label: " Total Circuit Power",
    },
    {
      key: "totalreallosseskw",
      color: "#E97132",
      label: "Total Real Losses",
    },
    {
      key: "totalrealcustomerpowerkw",
      color: "#196B24",
      label: "Total Real Customer Power",
    },
    {
      key: "totalrealcommercialpowerkw",
      color: "#0F9ED5",
      label: "Total Real Commercial Power",
    },
    {
      key: "totalrealresidentialpowerkw",
      color: "#A02B93",
      label: "Total Real Residential Power",
    },
  ];

  const dataset = [];
  for (let i = 0; i < properties.length; i++) {
    const base: any = {
      label: properties[i].label,
      data: [],
      borderColor: properties[i].color,
      borderWidth: 2,
      fill: false,
      pointRadius: 1,
      pointHoverRadius: 1,
    };

    if (data.length === 0) {
      base.data.push((0).toFixed(2));
    }

    for (let j = 0; j < data.length; j++) {
      const value: any = data[j][properties[i].key] || 0;
      base.data.push({
        x: moment(data[j].time).utc().local().toDate(),
        y: value.toFixed(2),
      });
    }
    dataset.push(base);
  }
  return {
    dataset,
  };
};

const getSubstationPercentileChartProperties = (data: any) => {
  const base: any = {
    label: "",
    data: [],
    borderColor: "#156082",
    borderWidth: 2,
    fill: false,
    pointRadius: 1,
    pointHoverRadius: 1,
  };

  const dataset = [];

  if (data.length === 0) {
    base.data.push((0).toFixed(2));
  }
  for (let i = 0; i < data.length; i++) {
    base.data.push((data[i].current || 0).toFixed(2));
  }
  dataset.push(base);
  return { dataset };
};

const getSubstationLineChartProperties = (connectivityData: any) => {
  const properties = [
    {
      key: "circuitpower",
      color: "#156082",
      label: "Circuit Power",
    },
    {
      key: "reallosseskw",
      color: "#EEA18A",
      label: "Real Losses",
    },
    {
      key: "realcustomerpower",
      color: "#196B24",
      label: "Real Customer Power",
    },
    {
      key: "realcommercialpower",
      color: "#0F9ED5",
      label: "Real Commercial Power",
    },
    {
      key: "realresidentialpower",
      color: "#A02B93",
      label: "Real Residential Power",
    },
  ];

  const currentDataset = [];

  for (let i = 0; i < properties.length; i++) {
    const base: any = {
      label: properties[i].label,
      data: [],
      borderColor: properties[i].color,
      borderWidth: 2,
      fill: false,
      pointRadius: 1,
      pointHoverRadius: 1,
    };

    for (let j = 0; j < connectivityData.length; j++) {
      const time = connectivityData[j].time;
      const value = connectivityData[j][properties[i].key] || 0;
      base.data.push({
        x: moment(connectivityData[j].time).utc().local().toDate(),
        y: value.toFixed(2),
      });
    }

    currentDataset.push(base);
  }
  const labels: any = [];

  return { currentDataset };
};

const getSubstationVoltageChartProperties = (connectivityData: any) => {
  const currentDataset = [];
  if (connectivityData && connectivityData[0]) {
    const trimmedData = connectivityData.slice(0, 1000);
    // const trimmedData = connectivityData.splice(1000,connectivityData.length-1)

    const base: any = {
      label: trimmedData[0][2],
      data: [],
      borderColor: "#196B24",
      backgroundColor: "#196B24",
      borderWidth: 5,
      fill: false,
      pointRadius: 1,
      pointHoverRadius: 1,
    };
    for (let j = 0; j < trimmedData.length; j++) {
      const value: { x: number; y: number } = {
        x: trimmedData[j][1].toFixed(4) || 0,

        y: trimmedData[j][0].toFixed(4) || 0,
      };

      base.data.push(value);
    }
    currentDataset.push(base);
  }
  return {
    currentDataset,
  };
};

function roundToNearest15(minutes: any): string {
  if (minutes === 0) return "00";

  if (minutes > 0 && minutes <= 15) {
    minutes = "00";
  } else if (minutes > 15 && minutes <= 30) {
    minutes = "15";
  } else if (minutes > 30 && minutes <= 45) {
    minutes = "30";
  } else if (minutes > 45 && minutes <= 59) {
    minutes = "45";
  }
  return minutes;
}

const getCurrentUTCTime = () => {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so we add 1
  const day = String(now.getUTCDate()).padStart(2, "0");
  const hours = String(now.getUTCHours()).padStart(2, "0");
  const minutes = String(now.getUTCMinutes()).padStart(2, "0");
  const seconds = String(now.getUTCSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${roundToNearest15(
    Number(minutes)
  )}:${"00"}`;
};

const getUTCTime = (date: string) => {
  const now = new Date(date);
  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so we add 1
  const day = String(now.getUTCDate()).padStart(2, "0");
  const hours = String(now.getUTCHours()).padStart(2, "0");
  const minutes = String(now.getUTCMinutes()).padStart(2, "0");
  const seconds = String(now.getUTCSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const commonUtils = {
  formatString,
  generateXAxisLabels,
  getRandomColor,
  getCustomerChartProperties,
  getPvChartProperties,
  getConnectivityChartProperties,
  getTransformerConnectivityChartProperties,
  getTransformerLineChartProperties,
  getMapViewCircuit,
  getSubstationPercentileChartProperties,
  getSubstationLineChartProperties,
  getSubstationVoltageChartProperties,
  getCurrentUTCTime,
  getUTCTime,
  getConsumerStorageChartProperties,
};
