import { useEffect, useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import gridSuggetions from "./grid.elements.json";
import operatorList from "./operators.json";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React from "react";
import QueryBuilderList from "../query.builder.list/query.builder.list";
import playIconWhite from "../../assets/images/play-button-white.svg";
import playIconDark from "../../assets/images/play-button-dark.svg";
import { assetInfoActions } from "../../store/asset-list/asset-list.action";
import {
  setAssetInfo,
  setAssetPayload,
} from "../../store/asset-list/asset-list.slice";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../store";
import "./query.builder.scss";
import { IAssetListPayload } from "../../store/asset-list/asset-list.interface";
import menuDark from "../../assets/images/menu-darktheme.svg";
import menuLight from "../../assets/images/menu-ligttheme.svg";
function QueryBuilder(props: any) {
  const [getText, setText] = useState("");
  const [getValue, setValue] = useState("");
  const target1 = useRef(null);
  const overlayref: any = useRef(null);
  const overlayref2: any = useRef(null);
  const overlayref3: any = useRef(null);
  const [showB, setShowB] = useState(false);
  const [showB2, setShowB2] = useState(false);
  const [showB3, setShowB3] = useState(false);
  const dispatch = useAppDispatch();
  let getAssetPayload = useAppSelector((state) => state.AssetInfo.assetPayload);
  const theme = useAppSelector((state) => state.seqrOps.theme);
  const [segments, setSegments] = useState<Array<any>>(getAssetPayload);
  useEffect(() => {
    let cc = [...getAssetPayload];
    setSegments(cc);
  }, [getAssetPayload]);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (overlayref.current && !overlayref.current.contains(event.target)) {
      setShowB(false);
    }
    if (overlayref2.current && !overlayref2.current.contains(event.target)) {
      setShowB2(false);
    }
    if (overlayref3.current && !overlayref3.current.contains(event.target)) {
      setShowB3(false);
    }
  };
  const onClickdrp2 = () => {
    addSegment();
    setValue("");
    setText("");
    setShowB(true);
    // handleChange()
  };
  const onClickdrp3 = (value: any, id: string, type: string) => {
    setShowB2(true);
    setShowB(false);

    setText(value);
    handleChange(0, type, id);
  };

  const onClickdrp4 = (value: any, id: string) => {
    setShowB2(false);
    setShowB3(true);
    setShowB(false);
    setText(getText + id);
    handleChange(0, "operator", value);
  };

  const addSegment = () => {
    const seg = segments.filter((data, index) => data.value !== "");

    setSegments([
      ...seg,
      ...[
        { type: "", operator: "", value: "", logicalOperator: "AND", rid: "" },
      ],
    ]);
  };

  const handleChange = (index: any, field: any, value: any) => {
    const updatedSegments: any = [...segments];
    let segmant = { ...updatedSegments[updatedSegments.length - 1] };

    segmant[field] = value;
    updatedSegments[updatedSegments.length - 1] = { ...segmant };
    setSegments(updatedSegments);
  };

  const onChangeSublist = (segment:any,index:number)=>{
    const updatedSegments: any = [...segments];
    updatedSegments[index]={...segment};
    setSegments(updatedSegments);
  }

  const removeSegment = (indexToRemove: any) => {
    const seg = segments.filter((_, index) => index !== indexToRemove);
    setSegments(seg);
    queryBuild(seg);
  };

  const onInputChange = (value: any) => {
    handleChange(0, "value", value);
    setText("");
    setShowB3(false);
  };
  const queryBuild = (_segment: any) => {
    let list = [..._segment];
    if (!list[list.length - 1]?.value) {
      list.pop();
    }

    if (list.length > 0) {
      dispatch(setAssetPayload(list));
      assetInfoActions.getAssetInfo(list).then((data) => {
        dispatch(setAssetInfo(data));
      });
    }
    if (list.length == 0) {
      let list1 = [
        {
          type: "Substation",
          operator: "STARTS_WITH",
          value: "",
          logicalOperator: "AND",
          rid: "",
        },
      ];

      // setText("Query here");
      dispatch(setAssetPayload(list));
      assetInfoActions.getAssetInfo(list1).then((data) => {
        dispatch(setAssetInfo(data));
      });
    }
  };
  const onTextChange = (data: string) => {
    setValue(data);
  };

  return (
    <div>
      <div
        className="seqrops-login-wrapper d-inline-flex query-builder"
        style={{ backgroundColor: "#00111c", height: "100%", width: "100%" }}
      >
        <div className="container-fluid  seqrops-query-builder-wrapper">
          <div
            className="seqrops-query-section"
            style={{
              alignItems: "left",
              padding: 10,
              width: "100%",
              backgroundColor: "#00406b",
              flex: 1,
              textAlign: "left",
              color: "white",
              fontSize: "small",
            }}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                onClickdrp2();
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  props.toggleSidebar();
                }}
              >
                <img
                  style={{ marginRight: 10 }}
                  src={theme ? menuDark : menuLight}
                  width={25}
                  height={25}
                ></img>
              </div>
              <div
                id="outer-div"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {segments?.map((seg, index) => {
                  return seg.value ? (
                    <QueryBuilderList
                    onChangeSublist={onChangeSublist}
                      removeSegment={removeSegment}
                      segment={seg}
                      seg={segments}
                      index={index}
                      key={"mm" + index}
                    />
                  ) : (
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        onClickdrp2();
                      }}
                    >
                      {getText}
                    </span>
                  );
                })}
                {segments.length == 0 ? (
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      onClickdrp2();
                    }}
                  >
                    <i>Search grid elements</i>
                  </span>
                ) : null}

                <span id="oo" ref={target1}></span>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  queryBuild(segments);
                }}
              >
                <img
                  src={theme ? playIconWhite : playIconDark}
                  width={25}
                  height={25}
                ></img>
              </div>
            </div>
          </div>
          <Overlay
            ref={overlayref}
            target={target1.current}
            show={showB}
            placement="left"
          >
            {({
              placement: _placement,
              arrowProps: _arrowProps,
              show: _show,
              popper: _popper,
              hasDoneInitialMeasure: _hasDoneInitialMeasure,
              ...props
            }) => (
              <div
                id={theme ? "dark" : "light"}
                {...props}
                style={{
                  position: "absolute",
                  backgroundColor: "transparent",
                  padding: "30px 1px",
                  color: "white",
                  borderRadius: 3,
                  zIndex: 99999,
                  ...props.style,
                }}
              >
                <Dropdown.Menu
                  className="seqrops-query-section"
                  style={{
                    marginTop: 5,
                    borderRadius: 5,

                    border: "1px solid #003d66",
                  }}
                  show
                >
                  <Dropdown.Header style={{ color: "white" }}>
                    <div>
                      <span>
                        <b>Suggetions</b>
                      </span>
                    </div>
                  </Dropdown.Header>
                  {gridSuggetions.map((data) => {
                    return (
                      <Dropdown.Item
                        className="seqrops-query-builder-list"
                        style={{
                          // color: "white",
                          // borderBottom: "1px solid #494950",
                          fontSize: "small",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          onClickdrp3(data.value, data.id, "type");
                        }}
                        eventKey={data.id}
                      >
                        {data.value}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </div>
            )}
          </Overlay>
          <Overlay
            ref={overlayref2}
            target={target1.current}
            show={showB2}
            placement="left"
          >
            {({
              placement: _placement,
              arrowProps: _arrowProps,
              show: _show,
              popper: _popper,
              hasDoneInitialMeasure: _hasDoneInitialMeasure,
              ...props
            }) => (
              <div
                {...props}
                id={theme ? "dark" : "light"}
                style={{
                  position: "absolute",
                  backgroundColor: "tranparent",
                  padding: "30px 1px",
                  color: "white",
                  borderRadius: 3,
                  zIndex: 99999,
                  ...props.style,
                }}
              >
                <Dropdown.Menu
                  className="seqrops-query-section"
                  style={{
                    marginTop: 5,
                    borderRadius: 5,

                    border: "1px solid #d0d3df",
                  }}
                  show
                >
                  <Dropdown.Header style={{ color: "white" }}>
                    <div>
                      <span>
                        <b>Operators</b>
                      </span>
                    </div>
                  </Dropdown.Header>
                  {operatorList.map((data) => {
                    return (
                      <Dropdown.Item
                        className="seqrops-query-builder-list"
                        style={{
                          // color: "white",
                          // borderBottom: "1px solid #d0d3df",
                          fontSize: "small",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          onClickdrp4(data.operator, data.id);
                        }}
                        eventKey={data.id}
                      >
                        {data.value}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </div>
            )}
          </Overlay>
          <Overlay
            ref={overlayref3}
            target={target1.current}
            show={showB3}
            placement="bottom-start"
          >
            {({
              placement: _placement,
              arrowProps: _arrowProps,
              show: _show,
              popper: _popper,
              hasDoneInitialMeasure: _hasDoneInitialMeasure,
              ...props
            }) => (
              <div
                {...props}
                id={theme ? "dark" : "light"}
                style={{
                  position: "absolute",
                  backgroundColor: "transparent",
                  padding: "0px",
                  color: "white",
                  borderRadius: 3,
                  zIndex: 99999,
                  display: "flex",
                  flexDirection: "row",
                  ...props.style,
                }}
              >
                {/* <Autocomplete
                autoComplete={true}
                  onSelect={(e) => e.stopPropagation()}
                  className="seqrops-query-section"
                  onChange={onInputChange}
                  style={{
                    width: "200px",
                  }}
                  
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  options={gridSuggetions.map((value) => value.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
              
                      label="Search input"
                      
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                /> */}
                <div
                  className="seqrops-query-section"
                  style={{ display: "flex" }}
                >
                  <TextField
                    placeholder="Enter a value"
                    focused={true}
                    className="seqrops-query-builder-wrapper"
                    onChange={(event) => onTextChange(event.target.value)}
                    //  value={(event:any)=>onInputChange(event)}
                  />
                  <div
                    onClick={() => onInputChange(getValue)}
                    className="seqrops-query-builder-wrapper"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 9,
                      cursor: "pointer",
                    }}
                  >
                    <span className="seqrops-query-section-text">save</span>
                  </div>
                </div>
              </div>
            )}
          </Overlay>
        </div>
      </div>
      {/* <div
        
      >
        <span className="seqrops-query-section-text">{props.sidebarValue}</span>
      </div> */}
    </div>
  );
}
export default QueryBuilder;
