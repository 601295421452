import axios from "axios";
import { DTECH_ASSEST_URL } from "../../config/default.config";
import { IAssetListPayload } from "./asset-list.interface";

const getAssetInfo = async (requestData: Array<IAssetListPayload>) => {
  const response = await axios.post(
    DTECH_ASSEST_URL.GET_ALL_ASSET,
    requestData
  );
  return response;
};
export const assetInfoService = {
  getAssetInfo,
};
