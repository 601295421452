import React, { useEffect, useReducer, useState } from "react";
import { render } from "react-dom";
import { VariableSizeList as List } from "react-window";
// import AutoSizer from "react-virtualized-auto-sizer";

// import "./styles.css";
import "./list.component.scss";
import SubListComponent from "../sublist.component/sublist.component";
import darkarrow from "../../assets/images/dark-arrow-up.svg";
export type seg = {
  type: string;
  operator: string;
  value: string;
  logicalOperator: string;
};
export type Props = {
  seg: Array<seg>;
  index: number;
  segment: seg;
  removeSegment: (index: number) => void;
};
function ListComponent(props: any) {
  const [listItem, setList] = useState(props?.masterArray);
  const [, forceUpdate] = useReducer((x) => {
    return x + 1;
  }, 0);

  useEffect(() => {
    setList(props?.masterArray);
    // setusedArray(props.list)
  }, [props?.masterArray]);

  // const [toggle,settoggle]=
  const onButtonClick = (data: any) => {
    props.onButtonClick(data);
  };

  const getItemSize = (index: any) => {
    return 370;
  };

  const onBuildClick = (data: any) => {
    props.onBuildClick(data);
  };
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Row = ({ index, style }: any) => (
    <div
      className="left-section-selection-wrapper"
      style={{ ...style, ...{ marginTop: 0, padding: "8px" } }}
    >
      <div>
        <div
          style={{
            backgroundColor: "#2e374b",
            textAlign: "left",
            padding: "10px 15px",

            borderRadius: "7px 7px 0px 0px",
          }}
        >
          <span style={{ color: "white" }}>{listItem[index]?.type}</span>
        </div>
        <SubListComponent
          masterArray={listItem[index]?.assets}
          mainListLength={listItem?.length}
          onButtonClick={onButtonClick}
          onBuildClick={onBuildClick}
        ></SubListComponent>
      </div>
      <br></br>
    </div>
  );
  return (
    <List
      className="List"
      style={{ border: "none", position: "relative", display: "flex" }}
      height={windowHeight - 200}
      itemCount={listItem?.length || 0}
      itemSize={(index) => getItemSize(index)}
      width={"100%"}
    >
      {Row}
    </List>
  );
}
export default ListComponent;
