import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/themes/saga-blue/theme.css";

import seqrOpsSlice from "./seqr-ops/seqr-ops.slice";
import assetKeycloakSlice from "./keycloak/asset-keycloak.slice";
import assestInfoSlice from "./assest-info/assest-info.slice";
import substationSlice from "./substation/substation.slice";
import AssetInfoSlice from "./asset-list/asset-list.slice";
import customerSlice from "./customer/customer.slice";
import transformerSlice from "./transformer/transformer.slice";
import alarmInfoSlice from "./alarm-info/alarm-info.slice";
import mapInfoSlice from "./map-info/map-info.slice";
import sidebarSlice from "./sidebar/sidebar.slice";
import queryBuilderSlice from "./query-builder/query-builder.slice";
export const store = configureStore({
  reducer: {
    seqrOps: seqrOpsSlice,
    assetKeycloak: assetKeycloakSlice,
    mapInfoSlice: mapInfoSlice,
    assestInfo: assestInfoSlice,
    substationSlice: substationSlice,
    customerSlice: customerSlice,
    AssetInfo: AssetInfoSlice,
    transformerSlice: transformerSlice,
    alarmInfo: alarmInfoSlice,
    sidebarSlice: sidebarSlice,
    queryBuilderSlice: queryBuilderSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
