import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IQBTab,
  IQWeatherData,
  IQueryBuilder,
} from "./query-builder.interface";
import { LayerControlType, generateUUID } from "../../modules/gis/hooks/useGIS";
const initialState: IQueryBuilder = {
  tabs: [],
  activeTab: {
    label: "map",
    type: "",
    rid: "",
    value: "map",
  },
  filterVisibility: false,
  zoomControl: {
    zoom: 12,
    latlng: [36.20937712409333, -79.61071014404297],
    maxZoom: 19,
  },
  filterSelection: {
    checked: [
      LayerControlType.NETWORK_LINE_ABC_HV,
      LayerControlType.NETWORK_LINE_ABC_MV,
      LayerControlType.NETWORK_LINE_ABC_LV,
      LayerControlType.NETWORK_LINE_A_MV,
      LayerControlType.NETWORK_LINE_A_LV,
      LayerControlType.NETWORK_LINE_B_MV,
      LayerControlType.NETWORK_LINE_B_LV,
      LayerControlType.NETWORK_LINE_C_MV,
      LayerControlType.NETWORK_LINE_C_LV,
      LayerControlType.HEATMAP_VOLTAGE,
    ],
    prevChecked: [],
  },
  weatherData: {
    name: "",
    irradiance: 0,
    kw: 0,
    kvar: 0,
    time: "",
    dhi: 0,
    dni: 0,
    ghi: 0,
    windspeed: 0,
    temperature: 0,
  },
};

const queryBuilderSlice = createSlice({
  name: "queryBuilderSlice",
  initialState: initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<IQBTab>) => {
      if (action.payload) {
        state.activeTab = action.payload;
      }
    },
    addTab: (state, action: PayloadAction<IQBTab>) => {
      const findIndex = state.tabs.findIndex(
        (x) => x.value === action.payload.value
      );
      if (findIndex === -1 && action.payload) {
        state.tabs.push(action.payload);
      }
      state.activeTab = action.payload;
    },
    removeTab: (state, action: PayloadAction<IQBTab>) => {
      const index = state.tabs.findIndex(
        (x) => x.value === action.payload.value
      );
      let newValue = state.activeTab;

      if (index !== -1) {
        const newTabs = [...state.tabs];
        newTabs.splice(index, 1);
        state.tabs = [...newTabs];
        if (action.payload.value === state.activeTab.value) {
          if (newTabs.length > 0) {
            newValue =
              index === newTabs.length ? newTabs[index - 1] : newTabs[index];
          } else {
            newValue = initialState.activeTab;
          }
        }
      }
      if (
        state.tabs.length === 0 &&
        !["alarm", "map"].includes(newValue.value)
      ) {
        state.activeTab = {
          label: "map",
          type: "",
          rid: "",
          value: "map",
        };
      } else {
        state.activeTab = newValue;
      }
    },
    setMapFilterVisibility: (state, action: PayloadAction<boolean>) => {
      state.filterVisibility = action.payload;
    },
    setMapZoom: (
      state,
      action: PayloadAction<{ zoom: number; latlng: number[] }>
    ) => {
      state.zoomControl.zoom = action.payload.zoom;
      state.zoomControl.latlng = action.payload.latlng;
    },
    setMapFilterSelection: (
      state,
      action: PayloadAction<{ checked: string[]; prevChecked: string[] }>
    ) => {
      console.log(action.payload);
      state.filterSelection.checked = action.payload.checked;
      state.filterSelection.prevChecked = action.payload.prevChecked;
    },
    setWeatherData: (state, action: PayloadAction<IQWeatherData>) => {
      state.weatherData = action.payload;
    },
  },
});
export const {
  setActiveTab,
  addTab,
  removeTab,
  setMapFilterVisibility,
  setMapZoom,
  setMapFilterSelection,
  setWeatherData,
} = queryBuilderSlice.actions;
export default queryBuilderSlice.reducer;
