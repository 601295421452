import { useDispatch } from "react-redux";
import { AppThunk, store, useAppSelector } from "../index";
import { IMapInfoPayload } from "./map-info.interface";
import { mapInfoService } from "./map-info.service";
import {
  setLinesMapData,
  setMapData,
  setSingleMapData,
  setTransformersMapData,
  setenergyConsumersMapData,
  setBayMapData,
  setsubstationsMapData,
  setswitchingDevices,
  setChartData,
} from "./map-info.slice";
import { IDataRecordReq } from "../customer/customer.interface";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { DTECH_ASSEST_URL, SESSION_KEY } from "../../config/default.config";
import { brtWasmModule } from "../../modules/gis/utils/WasmLoader";

const endpointActions: any = {
  VOLTAGE_REGULATORS: setLinesMapData,
  TRANSFORMERS: setTransformersMapData,
  ENERGY_CONSUMERS: setenergyConsumersMapData,
  BAYS: setBayMapData,
  SUBSTATIONS: setsubstationsMapData,
  SWITCHING_DEVICES: setswitchingDevices,
};

const getSingleMapData = async (IMapInfoPayload: IMapInfoPayload) => {
  const params: IMapInfoPayload = IMapInfoPayload;
  try {
    const response = await mapInfoService.getSingleMapInfo(params);
    if (response.status) {
      return response.data.data;
      //  store.dispatch(setSingleMapData(response.data.data))
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

const getAllMapData = () => {
  try {
    const response: any = mapInfoService.getAllMapInfo();
    return response;
  } catch (err) {}
};

const getCircuitData = (circuitDataReq: IDataRecordReq): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await mapInfoService.getCircuitData(circuitDataReq);

      if (response?.data?.success) {
        if (response.status === 200) {
          dispatch(setChartData(response?.data?.data));
        }
      } else if (!response.data.success) {
      }
    } catch (error) {}
  };
};

const getGisData = (data: any): Promise<any> => {
  let tokenData: any = sessionStorageUtils.getLocalStorage(
    SESSION_KEY.LOCAL_STORAGE_KEY
  );
  if (tokenData !== null) {
    const token = tokenData?.token?.auth_token;
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    if (token) {
      return new Promise((resolve, reject) => {
        brtWasmModule
          .gisDataProvider()
          .then((instance) => {
            instance.getGisData(
              DTECH_ASSEST_URL.GET_GIS_DATA_BY_TYPE,
              headers,
              data,
              (successData: any) => {
                resolve(successData);
              },
              (error: any) => {
                reject(error);
              }
            );
          })
          .catch((error: any) => {
            console.error("Error obtaining datasets instance:", error);
            reject(error);
          });
      });
    } else {
      return Promise.resolve({});
    }
  } else {
    return Promise.resolve({});
  }
};

const deleteGisData = () => {
  brtWasmModule.gisDataProvider().then((instance) => {
    instance.deleteGisData();
  });
};

const getLineVoltageGisData = (data: any): Promise<any> => {
  let tokenData: any = sessionStorageUtils.getLocalStorage(
    SESSION_KEY.LOCAL_STORAGE_KEY
  );
  if (tokenData !== null) {
    const token = tokenData?.token?.auth_token;
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    if (token) {
      return new Promise((resolve, reject) => {
        brtWasmModule
          .gisDataProvider()
          .then((instance) => {
            instance.getLineVoltageGisData(
              DTECH_ASSEST_URL.GET_GIS_DATA_BY_TYPE,
              headers,
              data,
              (successData: any) => {
                resolve(successData);
              },
              (error: any) => {
                reject(error);
              }
            );
          })
          .catch((error: any) => {
            console.error("Error obtaining datasets instance:", error);
            reject(error);
          });
      });
    } else {
      return Promise.resolve({});
    }
  } else {
    return Promise.resolve({});
  }
};

const getLineLoadingGisData = (data: any): Promise<any> => {
  let tokenData: any = sessionStorageUtils.getLocalStorage(
    SESSION_KEY.LOCAL_STORAGE_KEY
  );
  if (tokenData !== null) {
    const token = tokenData?.token?.auth_token;
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    if (token) {
      return new Promise((resolve, reject) => {
        brtWasmModule
          .gisDataProvider()
          .then((instance) => {
            instance.getLineLoadingGisData(
              DTECH_ASSEST_URL.GET_GIS_DATA_BY_TYPE,
              headers,
              data,
              (successData: any) => {
                resolve(successData);
              },
              (error: any) => {
                reject(error);
              }
            );
          })
          .catch((error: any) => {
            console.error("Error obtaining datasets instance:", error);
            reject(error);
          });
      });
    } else {
      return Promise.resolve({});
    }
  } else {
    return Promise.resolve({});
  }
};

export const mapInfoActions = {
  getAllMapData,
  getSingleMapData,
  getCircuitData,
  getGisData,
  getLineLoadingGisData,
  getLineVoltageGisData,
  deleteGisData,
};
