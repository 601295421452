import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../alarm.component/alarm.scss";
import { useAppSelector } from "../../store";
import { IAlarmData } from "../../store/alarm-info/alarm-info.interface";
import { ICustomerPropertyBody } from "../../store/customer/customer.interface";

interface Row {
  alarm_title: string;
  dateAndTime: number;
  equipment: string;
  substation: string;
  vl: string;
  bay: string;
}

const CustomerDataTableWithStickyHeader = ({
  data,
  columnsToShow,
  showConnectivity,
}: {
  data: ICustomerPropertyBody[];
  columnsToShow: string[];
  showConnectivity: boolean;
}) => {
  const [alarmDatas, setAlarmDatas] = useState<IAlarmData[]>();
  const getAlarmInfo: any = useAppSelector(
    (state) => state.alarmInfo.alarmInfo
  );

  //   const _data = [
  //     {
  //       name: "clothesDryerkvar",
  //       value: 1,
  //       unit: "6",
  //     },
  //     {
  //       name: "clothesDryerkw",
  //       value: 2,
  //       unit: "5",
  //     },
  //     {
  //       name: "clothesDryerkw",
  //       value: 2,
  //       unit: "5",
  //     },
  //     {
  //       name: "clothesDryerkw",
  //       value: 2,
  //       unit: "5",
  //     },
  //     {
  //       name: "clothesDryerkw",
  //       value: 2,
  //       unit: "5",
  //     },

  //     {
  //       name: "clothesDryerkw",
  //       value: 2,
  //       unit: "5",
  //     },
  //   ];

  //   useEffect(() => {
  //     if (props?.alarmData != null && props?.alarmData != undefined) {
  //       setAlarmDatas(props?.alarmData);
  //     }
  //   }, [props]);
  return (
    <>
      <div className="table-responsive customer-table">
        <TableContainer
          className="table-container"
          sx={{ maxHeight: "15vh", minHeight: "15vh" }}
          component={Paper}
        >
          <Table
            sx={{ color: "red" }}
            stickyHeader
            size="small"
            aria-label="a dense table"
          >
            <TableHead sx={{ borderBottom: "1px solid #d0d3df" }}>
              <TableRow>
                {columnsToShow.map((columnName: string, index: any) => (
                  <TableCell className="table-header">{columnName}</TableCell>
                ))}
                {/* <TableCell className="table-header">Alarm</TableCell>
                <TableCell className="table-header" align="right">Time</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row: any) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      border: "1px solid #dddddd",
                    }}
                  >
                    <TableCell
                      className="table-row"
                      color="red"
                      align="left"
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    {showConnectivity ? (
                      <TableCell className="table-row" color="red" align="left">
                        {row.Connectivity}
                      </TableCell>
                    ) : null}
                    <TableCell className="table-row" color="red" align="left">
                      {row.kW}
                    </TableCell>
                    <TableCell className="table-row" color="red" align="left">
                      {row.kVar}
                    </TableCell>
                    <TableCell className="table-row" color="red" align="left">
                      {row.state}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
export default CustomerDataTableWithStickyHeader;
