import axios from "axios";
import {
  GRID_MONITORING_API,
  SIMULATION_URLS,
} from "../../config/default.config";
import {
  IAlarmsPayload,
  IGetAllAlarmsPayload,
} from "../../components/alarm.list.component/alarm.interface";

const getAlarmInfo = async () => {
  const response = await axios.get("url");
  const dummyResponse = [
    {
      alarm_title: "Alarm 1",
      dateAndTime: 1634721900000,
      equipment: "Equipment 1",
      substation: "Substation 1",
      vl: "VL 1",
      bay: "Bay 1",
    },
    {
      alarm_title: "Alarm 2",
      dateAndTime: 1634722900000,
      equipment: "Equipment 2",
      substation: "Substation 2",
      vl: "VL 2",
      bay: "Bay 2",
    },
    {
      alarm_title: "Alarm 3",
      dateAndTime: 1634722900000,
      equipment: "Equipment 3",
      substation: "Substation 3",
      vl: "VL 3",
      bay: "Bay 3",
    },
    {
      alarm_title: "Alarm 4",
      dateAndTime: 1634722900000,
      equipment: "Equipment 4",
      substation: "Substation 4",
      vl: "VL 4",
      bay: "Bay 4",
    },
    {
      alarm_title: "Alarm 5",
      dateAndTime: 1634722900000,
      equipment: "Equipment 5",
      substation: "Substation 5",
      vl: "VL 5",
      bay: "Bay 5",
    },
  ];
  return dummyResponse;
};

const getCustomerAlarmInfo = async () => {
  const response = await axios.get("url");
  const dummyResponse = [
    {
      alarm_title: "Alarm 1",
      dateAndTime: 1634721900000,
      equipment: "Equipment 1",
      substation: "Substation 1",
      vl: "VL 1",
      bay: "Bay 1",
    },
    {
      alarm_title: "Alarm 2",
      dateAndTime: 1634722900000,
      equipment: "Equipment 2",
      substation: "Substation 2",
      vl: "VL 2",
      bay: "Bay 2",
    },
    {
      alarm_title: "Alarm 3",
      dateAndTime: 1634722900000,
      equipment: "Equipment 3",
      substation: "Substation 3",
      vl: "VL 3",
      bay: "Bay 3",
    },
    {
      alarm_title: "Alarm 4",
      dateAndTime: 1634722900000,
      equipment: "Equipment 4",
      substation: "Substation 4",
      vl: "VL 4",
      bay: "Bay 4",
    },
    {
      alarm_title: "Alarm 5",
      dateAndTime: 1634722900000,
      equipment: "Equipment 5",
      substation: "Substation 5",
      vl: "VL 5",
      bay: "Bay 5",
    },
  ];
  return dummyResponse;
};
const getSubstationAlarmInfo = async () => {
  const response = await axios.get("url");
  const dummyResponse = [
    {
      alarm_title: "Alarm 1",
      dateAndTime: 1634721900000,
      equipment: "Equipment 1",
      substation: "Substation 1",
      vl: "VL 1",
      bay: "Bay 1",
    },
    {
      alarm_title: "Alarm 2",
      dateAndTime: 1634722900000,
      equipment: "Equipment 2",
      substation: "Substation 2",
      vl: "VL 2",
      bay: "Bay 2",
    },
    {
      alarm_title: "Alarm 3",
      dateAndTime: 1634722900000,
      equipment: "Equipment 3",
      substation: "Substation 3",
      vl: "VL 3",
      bay: "Bay 3",
    },
    {
      alarm_title: "Alarm 4",
      dateAndTime: 1634722900000,
      equipment: "Equipment 4",
      substation: "Substation 4",
      vl: "VL 4",
      bay: "Bay 4",
    },
    {
      alarm_title: "Alarm 5",
      dateAndTime: 1634722900000,
      equipment: "Equipment 5",
      substation: "Substation 5",
      vl: "VL 5",
      bay: "Bay 5",
    },
  ];
  return dummyResponse;
};
const getTransformerAlarmInfo = async () => {
  const response = await axios.get("url");
  const dummyResponse = [
    {
      alarm_title: "Alarm 1",
      dateAndTime: 1634721900000,
      equipment: "Equipment 1",
      substation: "Substation 1",
      vl: "VL 1",
      bay: "Bay 1",
    },
    {
      alarm_title: "Alarm 2",
      dateAndTime: 1634722900000,
      equipment: "Equipment 2",
      substation: "Substation 2",
      vl: "VL 2",
      bay: "Bay 2",
    },
    {
      alarm_title: "Alarm 3",
      dateAndTime: 1634722900000,
      equipment: "Equipment 3",
      substation: "Substation 3",
      vl: "VL 3",
      bay: "Bay 3",
    },
    {
      alarm_title: "Alarm 4",
      dateAndTime: 1634722900000,
      equipment: "Equipment 4",
      substation: "Substation 4",
      vl: "VL 4",
      bay: "Bay 4",
    },
    {
      alarm_title: "Alarm 5",
      dateAndTime: 1634722900000,
      equipment: "Equipment 5",
      substation: "Substation 5",
      vl: "VL 5",
      bay: "Bay 5",
    },
  ];
  return dummyResponse;
};

const getAllAlarms = async (alarmPayload: IGetAllAlarmsPayload) => {
  const response = await axios.post(
    SIMULATION_URLS.GET_ALL_ALARMS,
    alarmPayload
  );
  return response.data;
};

const getAlarms = async (alarmPayload: IAlarmsPayload) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_ALARMS,
    alarmPayload
  );
  return response.data;
};
export const alarmInfoService = {
  getAlarmInfo,
  getCustomerAlarmInfo,
  getSubstationAlarmInfo,
  getTransformerAlarmInfo,
  getAllAlarms,
  getAlarms,
};
