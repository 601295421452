import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISubstationDataRecord } from "./substation.interface";

const initialState: ISubstationDataRecord = {
  substation: [],
  circuitPower: [],
  percentile: [],
};
const substationSlice = createSlice({
  name: "substationSlice",
  initialState: initialState,
  reducers: {
    setSubstationDataRecords: (
      state,
      action: PayloadAction<ISubstationDataRecord>
    ) => {
      state.substation = action?.payload?.substation;
      state.percentile = action?.payload.percentile;
    },
    setSubstationCircuitDataRecords: (
      state,
      action: PayloadAction<ISubstationDataRecord>
    ) => {
      state.circuitPower = action?.payload?.circuitPower;
    },
  },
});

export const { setSubstationDataRecords, setSubstationCircuitDataRecords } =
  substationSlice.actions;
export default substationSlice.reducer;
