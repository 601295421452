import axios from "axios";
import {
  ASSET_AUTH_API,
  GRID_MONITORING_API,
} from "../../config/default.config";
import { IGetModelDetailsReq, IModelData } from "./transformer.interface";

const getTransformerData = async (getTransformerReq: IGetModelDetailsReq) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_TRANSFORMER_DATA_ENERGY_CONSUMER_RECORDS,
    getTransformerReq
  );

  return response;
};

const getTransformerBateryUnitDataRecords = async (
  getTransformerReq: IGetModelDetailsReq
) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_TRANSFORMER_BATTERY_UNIT_DATA_RECORDS,
    getTransformerReq
  );

  return response;
};

const getTransformerPvUnitDataRecords = async (
  getTransformerReq: IGetModelDetailsReq
) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_TRANSFORMER_PV_UNIT_DATA_RECORDS,
    getTransformerReq
  );

  return response;
};

const getTransformerBusesDataRecords = async (
  getTransformerReq: IGetModelDetailsReq
) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_TRANSFORMER_DATA_BUSES_RECORDS,
    getTransformerReq
  );

  return response;
};

const getTransformerLinesDataRecords = async (
  getTransformerReq: IGetModelDetailsReq
) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_TRANSFORMER_DATA_LINES_RECORDS,
    getTransformerReq
  );

  return response;
};
export const transformerService = {
  getTransformerData,
  getTransformerBateryUnitDataRecords,
  getTransformerPvUnitDataRecords,
  getTransformerBusesDataRecords,
  getTransformerLinesDataRecords,
};
