import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import ChartComponent from "../../../components/chart.component/chart";
import { commonUtils } from "../../../utils/common.utils";
import { ISubsstationDatas } from "../../../store/sidebar/sidebar.interface";
import { IDataRecordReq } from "../../../store/customer/customer.interface";
import { sidebarActions } from "../../../store/sidebar/sidebar.action";
import { substationService } from "../../../store/substation/substation.services";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { TabList, TabPanel } from "@mui/lab";
import { useAppDispatch } from "../../../store";
import { queryBuilderActions } from "../../../store/query-builder/query-builder.actions";

const initialPeakValues = {
  id: 0,
  realcustomerpower: 0.0,
  peakloadminuteofhour: 0.0,
  circuitpower: 0.0,
  realcommercialpower: 0.0,
  substation: "",
  realresidentialpower: 0.0,
  reallosseskw: 0.0,
  peakloadhourofday: 0.0,
  reallossespercentage: 0.0,
  time: "",
};

const initialCircuitPower = {
  id: 0,
  totalrealresidentialpowerkw: 0,
  totalcircuitpower: 0,
  totalrealcustomerpowerkw: 0,
  totalreallossespercentage: 0,
  totalrealcommercialpowerkw: 0,
  totalreallosseskw: 0,
  time: "0",
};
const SubstationPopupComponent = ({
  substationRid,
  substationType,
  substationName,
  showNetwork,
}: {
  substationRid: string;
  substationType: string;
  substationName: string;
  showNetwork: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [peaktimeValues, setPeakTimeValues] = useState<{
    id: number;
    realcustomerpower: number;
    peakloadminuteofhour: number;
    circuitpower: number;
    realcommercialpower: number;
    substation: string;
    realresidentialpower: number;
    reallosseskw: number;
    peakloadhourofday: number;
    reallossespercentage: number;
    time: string;
  }>(initialPeakValues);
  const [circuitPower, setCircuitPower] = useState<{
    id: number;
    totalrealresidentialpowerkw: number;
    totalcircuitpower: number;
    totalrealcustomerpowerkw: number;
    totalreallossespercentage: number;
    totalrealcommercialpowerkw: number;
    totalreallosseskw: number;
    time: string;
  }>(initialCircuitPower);
  const [value, setValue] = React.useState("1");

  const [substation, setSubstation] = useState<ISubsstationDatas>({
    name: "-",
    instance: {
      customers: 0,
      voltageLevels: [],
    },
    mRID: "-",
    schema: [],
    rid: "-",
    type: "Substation",
  });
  const [dataset, setDataSet] = useState<any[]>([]);
  const labels: any = commonUtils.generateXAxisLabels();

  const getDataRecords = async (rid: string, type: string, name: string) => {
    const reqDto: IDataRecordReq = {
      rid: rid,
      type: type,
      name: name,
      time: commonUtils.getCurrentUTCTime(),
    };

    const response = sidebarActions.getAllDataByListItem(reqDto);
    response.then((data: any) => {
      if (data?.Substation?.instance) {
        setSubstation(data.Substation);
      }
    });

    try {
      const promise1 = substationService.getSubstationDataRecords(reqDto);
      const promise2 =
        substationService.getSubstationCircuitDataRecords(reqDto);
      Promise.all([promise1, promise2])
        .then((values) => {
          if (values[0]?.data?.success) {
            if (values[0]?.status === 200) {
              setPeakTimeValues(
                values[0]?.data?.data?.substation?.[0] || initialPeakValues
              );
              const _dataset = commonUtils.getSubstationLineChartProperties(
                values[0]?.data?.data?.substation || []
              );
              setDataSet(_dataset.currentDataset);
            }
          }
          if (values[1]?.data?.success) {
            if (values[1]?.status === 200) {
              setCircuitPower(
                values[1]?.data?.data?.circuitPower?.[0] || initialCircuitPower
              );
            }
          }
        })
        .catch((error) => {});
    } catch (error) {
      console.error("Error fetching data records:", error);
    }
  };

  useEffect(() => {
    if (substationRid) {
      getDataRecords(substationRid, substationType, substationName);
    }
  }, [substationRid]);

  const onDetailsClick = () => {
    let _name = substationName;
    let _type = substationType;
    let _rid = substationRid;
    dispatch(
      queryBuilderActions.addTab({
        value: _type + "_" + _rid,
        label: _name,
        rid: _rid,
        type: _type,
      })
    );
  };

  const renderInfo = () => {
    return (
      <>
        <div className="v-card-text">
          <div
            className="v-list card-list"
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <div className="v-list-item w-50">
              <div className="v-list-item__content mb-1">
                <div className="v-list-item-subtitle">Substation</div>
                <div className="v-list-item-title">
                  {substation?.name?.replaceAll("tr(", "").replaceAll(")", "")}
                </div>
              </div>
            </div>

            <div className="v-list-item w-50">
              <div className="v-list-item__content mb-1">
                <div className="v-list-item-subtitle">Consumers</div>
                <div className="v-list-item-title">
                  {substation?.instance?.customers}
                </div>
              </div>
            </div>
          </div>
          <div className="v-list card-list">
            <div className="v-list-item">
              <div className="v-list-item__content mb-1">
                <div className="v-list-item-subtitle">Voltage Levels</div>
                <div className="v-list-item-title">
                  {(substation?.instance.voltageLevels || [])
                    .map((level: any) => level.replace("VoltageLevel ", ""))
                    .join(", ") || "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="v-list card-list">
            <div className="v-list-item">
              <div className="v-list-item__content mb-1">
                {substation.name !== "-" ? (
                  <i
                    className="fa fa-info-circle v-list-item-details-icon"
                    onClick={() => {
                      onDetailsClick();
                    }}
                    title="View Details"
                  ></i>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderActions = () => {
    return (
      <div
        style={{
          display: "flex",
          marginTop: "4px",
        }}
      >
        <i
          className="fa fa-home"
          style={{
            fontSize: "25px",
            padding: "5px",
            display: "flex",
            cursor: "pointer",
            border: "1px solid #ccc",
          }}
          onClick={() => {
            // showNetwork();
          }}
        ></i>
      </div>
    );
  };

  return (
    <>
      <div className="dtech-subsstation-section-wrapper  static-section">
        <div className=" " style={{}}>
          {renderInfo()}
          {/* {renderActions()} */}
          <TabContext value={value}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="gis-component-popup-tab"
            >
              <TabList
                onChange={(event: React.SyntheticEvent, newValue: string) => {
                  setValue(newValue);
                }}
              >
                <Tab
                  label="Load Curve"
                  value="1"
                  sx={{
                    textTransform: "none !important",
                  }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className="row gis-component-popup-chart dtech-substation-footer-chart-data-section">
                <div className="middle-chart-div">
                  <ChartComponent
                    labels={labels}
                    datasets={dataset}
                    title={"Load Curve"}
                    yAxisTitle={"kW Total"}
                    xAxisTitle={"Hour of the Day"}
                    height={"36vh"}
                    labelPosition={"bottom"}
                    labelAlign={"start"}
                  />
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </>
  );
};
export default SubstationPopupComponent;
