import React, { useEffect, useMemo, useRef, useState } from "react";
import Chart, {
  ChartOptions,
  ScriptableContext,
  TimeScaleTimeOptions,
} from "chart.js/auto";
import "chartjs-adapter-moment";
import moment from "moment";
import { bounds } from "leaflet";

const ChartComponent = (props: any) => {
  const chartRef = useRef<any>(null);
  const chartInstanceRef = useRef<any>(null);
  const now = moment().minute(0).second(0).millisecond(0).add(1, "hour");
  const currentHourStart = now.clone().add(1, "hour");

  let [resolutioneight, setResolutionHeight] = useState<string>("100%");
  function getLast24HoursFrom() {
    const nextTime: any = moment().startOf("hour").add(2, "hour");
    const last24Hours = [];
    const currentTime = moment(nextTime);
    for (let i = 0; i < 24; i++) {
      last24Hours.push(
        currentTime
          .clone()
          .subtract(i, "hours")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      );
    }
    return last24Hours;
  }

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 1200 && props?.title?.includes("Day Load Curve")) {
      setResolutionHeight("52vh");
    }
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }
    const labels = getLast24HoursFrom();
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      const newChartInstance = new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: props.datasets,
        },
        options: {
          animation: false,
          responsive: true,
          maintainAspectRatio: false,
          events: ["mousemove", "mouseout", "click", "touchstart", "touchmove"],
          interaction: {
            mode: "nearest",
          },
          layout: {
            padding: {
              right: 0,
            },
          },
          scales: {
            x: {
              type: "time",
              time: {
                parser: "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
                unit: "hour",
                displayFormats: {
                  hour: "HH:mm",
                },
                min: labels[labels.length - 1],
                max: labels[0],
                stepSize: 1,
                unitStepSize: 1,
              } as any,
              title: {
                display: true,
                text: props.xAxisTitle,
                color: "#59595a",
                position: "top",
                align: "start",
                font: {
                  weight: "bold",
                },
                padding: 9,
                fullSize: true,
              } as any,
              ticks: {
                maxTicksLimit: 24,
                maxRotation: 0,
                // autoSkipPadding: 10,
                source: "auto",
                autoSkip: false,
              },
            },
            y: {
              title: {
                display: true,
                text: props.yAxisTitle,
              },
              suggestedMax: props?.suggestedMax,
            },
          },
          plugins: {
            title: {
              display: true,
              text: props?.title,
            },
            legend: {
              position: props.labelPosition ? props.labelPosition : "right",
              align: props.labelAlign ? props.labelAlign : "center",
            },
            tooltip: {
              enabled: true,
              intersect: false,
            },
          },
        },
      });

      chartInstanceRef.current = newChartInstance;
    }

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [props.datasets]);

  return (
    <span
      className="chart-span"
      style={{
        height: props.height
          ? props.height
          : props?.title?.includes("Day Load Curve")
          ? resolutioneight
          : "45vh",
        display: "block",
      }}
    >
      <canvas ref={chartRef} id="myChart" />
    </span>
  );
};

export default ChartComponent;
