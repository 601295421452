import React, { useEffect, useState } from "react";
import { Tab, Box, Menu, MenuItem, TextField } from "@mui/material";
import { IconButton } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { TabList } from "@mui/lab";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { queryBuilderActions } from "../../store/query-builder/query-builder.actions";
import { useAppDispatch, useAppSelector } from "../../store";
import { IQBTab } from "../../store/query-builder/query-builder.interface";
import { generateUUID } from "../../modules/gis/hooks/useGIS";
import { useNavigate } from "react-router-dom";
import FilterIcon from "../../assets/images/filter_icon.svg";
import { encryptUtils } from "../../utils/encryption.utils";

const DynamicTabs: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tabs__ = useAppSelector((state) => state.queryBuilderSlice.tabs);
  const value__ = useAppSelector((state) => state.queryBuilderSlice.activeTab);

  ////////////////////////////////////////////////
  const customerData = useAppSelector(
    (state) => state.sidebarSlice.EnergyConsumer
  );
  const transformerData = useAppSelector((state) => state.sidebarSlice.Bay);

  const transformerDataCheck = useAppSelector(
    (state) => state.transformerSlice.transformerDataCheck
  );
  const cunsumerDataCheck = useAppSelector(
    (state) => state.customerSlice.cunsumerDataCheck
  );
  const consumersData = useAppSelector(
    (state) => state.transformerSlice.consumerData
  );

  ////////////////////////////////////////////

  const filterVisibility__ = useAppSelector(
    (state) => state.queryBuilderSlice.filterVisibility
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (event: React.SyntheticEvent, item: IQBTab) => {
    setAnchorEl(null);
    dispatch(queryBuilderActions.setActiveTab(item));
  };

  const onFilterClick = async () => {
    dispatch(queryBuilderActions.setMapFilterVisiblity(!filterVisibility__));
  };

  useEffect(() => {
    switch (value__.value) {
      case "map":
        navigate("/");
        break;
      case "alarm":
        navigate("/alarms");
        break;
      default:
        if (value__.value.startsWith("Substation_")) {
          var __param = encryptUtils.encryptURL(value__);
          navigate("/substation/" + __param);
        } else if (value__.value.startsWith("PowerTransformer_")) {
          var __param = encryptUtils.encryptURL(value__);
          navigate("/transformer/" + __param);
        } else if (value__.value.startsWith("EnergyConsumer_")) {
          var __param = encryptUtils.encryptURL(value__);
          navigate("/consumer/" + __param);
        }
        break;
    }
  }, [value__.value]);

  const removeTab = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: IQBTab
  ) => {
    event.stopPropagation();
    dispatch(queryBuilderActions.removeTab(item));
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="dynamic-tabs-container">
      <TabContext value={value__.value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            position: "relative",
            width: "100%",
            display: "inline-flex",
          }}
        >
          <Tab
            label="Map"
            value="map"
            sx={{
              borderBottom: `2px solid ${
                value__.value === "map" ? "#1976d2" : "transparent"
              }`,
              opacity: 1,
              textTransform: "none",
            }}
            onClick={(e) => {
              handleChange(e, {
                label: "map",
                value: "map",
                type: "",
                rid: "",
              });
            }}
          />
          {/* <Tab
            label="Substation"
            value="Substation_"
            sx={{
              borderBottom: `2px solid ${
                value__.value === "Substation_" ? "#1976d2" : "transparent"
              }`,
              opacity: 1,
              textTransform: "none",
            }}
            onClick={(e) => {
              handleChange(e, {
                label: "Substation_",
                value: "Substation_",
                type: "",
                rid: "",
              });
            }}
          />
          <Tab
            hidden={
              transformerData == null || !transformerDataCheck ? true : false
            }
            label="Bay"
            value="PowerTransformer_"
            sx={{
              borderBottom: `2px solid ${
                value__.value === "PowerTransformer_"
                  ? "#1976d2"
                  : "transparent"
              }`,
              opacity: 1,
              textTransform: "none",
            }}
            onClick={(e) => {
              handleChange(e, {
                label: "PowerTansformer_",
                value: "PowerTansformer_",
                type: "",
                rid: "",
              });
            }}
          />
          <Tab
            hidden={customerData == null || !cunsumerDataCheck ? true : false}
            label="EnergyConsumer"
            value="EnergyConsumer_"
            sx={{
              borderBottom: `2px solid ${
                value__.value === "EnergyConsumer_" ? "#1976d2" : "transparent"
              }`,
              opacity: 1,
              textTransform: "none",
            }}
            onClick={(e) => {
              handleChange(e, {
                label: "EnergyConsumer_",
                value: "EnergyConsumer_",
                type: "",
                rid: "",
              });
            }}
          /> */}
          <Tab
            label="Alarm"
            value="alarm"
            sx={{
              borderBottom: `2px solid ${
                value__.value === "alarm" ? "#1976d2" : "transparent"
              }`,
              opacity: 1,
              textTransform: "none",
            }}
            onClick={(e) => {
              handleChange(e, {
                label: "alarm",
                value: "alarm",
                type: "",
                rid: "",
              });
            }}
          />
          <TabList
            variant="scrollable"
            scrollButtons={"auto"}
            allowScrollButtonsMobile
            aria-label="tabs"
            style={{
              width: "calc(100% - 350px)",
            }}
          >
            {tabs__.map((item: IQBTab) => (
              <Tab
                key={item.value}
                label={
                  <span>
                    <span> {item.label}</span>
                    <IconButton
                      onClick={(e) => {
                        removeTab(e, item);
                      }}
                      className="remove-tab-icon"
                    >
                      <CloseIcon className="tab-close-icon" />
                    </IconButton>
                  </span>
                }
                value={item.value}
                onClick={(e) => {
                  handleChange(e, item);
                }}
                sx={{
                  textTransform: "none",
                }}
              />
            ))}
          </TabList>
          <div style={{ position: "absolute", right: "0px" }}>
            {tabs__.length > 0 ? (
              <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
            ) : null}
            {location.pathname === "/" ? (
              <button
                style={{
                  border: "0px",
                  background: "transparent",
                  padding: 0,
                }}
                className="gis-filter-toggle"
                type="button"
                onClick={onFilterClick}
              >
                <img className="gis-filter-toggle" src={FilterIcon}></img>
              </button>
            ) : null}
          </div>
        </Box>
      </TabContext>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className="dynamic-tabs-autocomplete-container"
      >
        <MenuItem>
          <TextField
            label="Search"
            variant="standard"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </MenuItem>
        {tabs__
          .filter((tab) =>
            tab.label.toLowerCase().includes(searchValue.toLowerCase())
          )
          .map((tab) => (
            <MenuItem
              key={tab.value}
              onClick={(e) => {
                handleChange(e, tab);
                handleMenuClose();
              }}
            >
              {tab.label}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default DynamicTabs;
