import "./map.scss";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { memo, useEffect, useRef, useState } from "react";
import axios from "axios";
import JSZip from "jszip";
import { DTECH_ASSEST_URL } from "../../config/default.config";
import { mapInfoService } from "../../store/map-info/map-info.service";
import { store, useAppDispatch, useAppSelector } from "../../store";
import Alarm from "../alarm.component/alarm";
import ChartComponent from "../chart.component/chart";
import "leaflet/dist/leaflet.css";
import {
  setAlarmData,
  setSingleMapData,
} from "../../store/map-info/map-info.slice";
import { IDataRecordReq } from "../../store/customer/customer.interface";
import { mapInfoActions } from "../../store/map-info/map-info.action";
import { ICircuitChartData } from "../../store/map-info/map-info.interface";
import { commonUtils } from "../../utils/common.utils";
import { handleMqttMessages } from "../../utils/MqttLIstner";
import { IAlarmsPayload } from "../alarm.list.component/alarm.interface";
import { alarmInfoActions } from "../../store/alarm-info/alarm-info.actions";
import { IAlarmData } from "../../store/alarm-info/alarm-info.interface";

var bayLayer: any;
var energyConsumerLayer: any;
var transformerLayer: any;
var switchigDevicesLayer: any;
var voltageRegulatorLayer: any;
var substationLayer: any;

const baseData = {
  type: "FeatureCollection",
  features: [],
};

var markerOptions = {
  radius: 2.0,
  fillColor: "#000000",
  color: "#FFFFFF",
  weight: 0.26,
  opacity: 1,
  fillOpacity: 0.8,
};

const LeafletComponent = (props: any) => {
  const mapContainerRef: any = useRef(null);
  const map: any = useRef(null);
  const getMapInfo = useAppSelector((state) => state.mapInfoSlice.allMapData);
  const chartRecords = useAppSelector((state) => state.mapInfoSlice.chartData);
  const checkedValue = useAppSelector(
    (state) => state.mapInfoSlice.checkedValue
  );
  const checkedItem = useAppSelector((state) => state.mapInfoSlice.checkedItem);
  const seledtedSubstation = useAppSelector(
    (state) => state.sidebarSlice.Substation
  );
  const [dataset, setDatasets] = useState<any>({
    dataset: [],
  });
  const alarmStoreData = useAppSelector(
    (state) => state.mapInfoSlice.alarmData
  );

  const [lng, setLng] = useState(-79.612907953);
  const [lat, setLat] = useState(36.198103838);
  const [zoom] = useState(13);
  const [load, setLoad] = useState(true);

  const [energyConsumerData, setEnergyConsumerData] = useState<any>(
    getMapInfo["ENERGY_CONSUMERS"]
  );
  const [voltageRegulatorData, setVoltageRegulatorData] = useState<any>(
    getMapInfo["VOLTAGE_REGULATORS"]
  );
  const [bayData, setBayData] = useState<any>(getMapInfo["BAYS"]);
  const [transformerData, setTransformerData] = useState<any>(
    getMapInfo["TRANSFORMERS"]
  );
  const [substationData, setSubstationData] = useState<any>(
    getMapInfo["SUBSTATIONS"]
  );
  const [switchingDeviceData, setSwitchingDeviceData] = useState<any>(
    getMapInfo["SWITCHING_DEVICES"]
  );

  const lanlogInfo: any = useAppSelector(
    (state) => state.mapInfoSlice.geoInformationState.singleMapData
  );
  const listDataItem: any = useAppSelector(
    (state) => state.mapInfoSlice.listData
  );
  const checkboxData: any = useAppSelector(
    (state) => state.mapInfoSlice.checkBoxData
  );

  const dispatch: any = useAppDispatch();

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [total_peakcircuitpower, setTotal_peakcircuitpower] = useState<
    number[]
  >([0]);
  const [total_peakrealcustomerpower_kw, setTotal_peakrealcustomerpower_kw] =
    useState<number[]>([0]);
  const [
    total_peakrealresidentialpower_kw,
    setTotal_peakrealresidentialpower_kw,
  ] = useState<number[]>([0]);
  const [total_peakreallosseskw, setTotal_peakreallosseskw] = useState<
    number[]
  >([0]);
  const [total_peakreallossespercentage, setTotal_peakreallossespercentage] =
    useState<number[]>([0]);
  const [
    total_peakrealcommercialpower_kw,
    setTotal_peakrealcommercialpower_kw,
  ] = useState<number[]>([0]);
  const label: any = commonUtils.generateXAxisLabels();
  const initialPaginationData: IAlarmsPayload = {
    totalRecords: 0,
    totalFilteredRecords: 0,
    pageNo: 1,
    pageSize: 10,
    sortKey: "",
    type: "",
    name: "",
    rid: "",
    time: commonUtils.getCurrentUTCTime(),
    sortOrder: "",
    data: {},
  };
  const [alarms, setAlarms] = useState<IAlarmData[]>();
  const [totalRecords, setTotalRecords] = useState<number>(5);

  useEffect(() => {
    const payload = initialPaginationData;
    alarmInfoActions.getAlarms(payload).then((response: any) => {
      setAlarms(response?.data?.alarms);
      setTotalRecords(response?.data?.totalRecords);
    });
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    let sampleData = { name: "P1RLV99", type: "ENERGY_CONSUMERS" };
    store.dispatch(setAlarmData(sampleData));
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(()=>{
  //   const layerMappings: any = {
  //     ENERGY_CONSUMERS: energyConsumerLayer,
  //     VOLTAGE_REGULATORS: voltageRegulatorLayer,
  //     BAYS: bayLayer,
  //     SUBSTATIONS: substationLayer,
  //     SWITCHING_DEVICES: switchigDevicesLayer,
  //     TRANSFORMERS: transformerLayer,
  //   };
  //   console.log("getMapInfo",getMapInfo["SUBSTATIONS"]);

  //   let alarmType =  alarmStoreData.type
  //   let layerData = layerMappings[alarmType]
  //   let features:any =getMapInfo[alarmType];
  //   console.log("features",features);

  //   if(layerData){
  //   updateItemColor(alarmStoreData.name,"red",layerData)

  //   //   for (const feature of features) {
  //   //     if (feature.properties.name === alarmStoreData.name) {
  //   //        extractLatLong(feature.geometry.coordinates)
  //   //         console.log("feature.geometry.coordinates",feature.geometry.coordinates);
  //   //         //  feature.geometry.coordinates;
  //   //     }

  //   // }

  //   }

  // })
  // getMapInfo.alarmStoreData.type

  // },[alarmStoreData,checkedItem])

  function createLayer(data: any, type: string = "") {
    return L.geoJSON(data, {
      pointToLayer: function (feature, latlng) {
        const fillColor = feature.properties.color;

        if (type === "SUBSTATION") {
          const iconSize = feature.properties.name === "RTS0" ? 20 : 15;
          return createTriangleMarker(latlng, {
            fillColor: fillColor,
            color: markerOptions.color,
            weight: markerOptions.weight,
            opacity: markerOptions.opacity,
            fillOpacity: markerOptions.fillOpacity,
            radius: markerOptions.radius,
            iconSize,
          });
        }
        return L.circleMarker(latlng, {
          fillColor: fillColor,
          color: markerOptions.color,
          weight: markerOptions.weight,
          opacity: markerOptions.opacity,
          fillOpacity: markerOptions.fillOpacity,
          radius: markerOptions.radius,
        });
      },
      style: function (feature: any) {
        if (feature.geometry.type === "MultiLineString") {
          const lineColor = feature.properties.color || "blue";
          return {
            color: lineColor,
            weight: 3,
            opacity: 1,
          };
        }

        return {};
      },
    }).addTo(map.current);
  }

  function createTriangleMarker(latlng: any, options: any) {
    var defaultOptions = {
      fillColor: "red",
      rotation: 0,
    };

    var mergedOptions = { ...defaultOptions, ...options };

    var icon = L.divIcon({
      html:
        '<div class="triangle" style="width: 0; height: 0; border-left:' +
        options.iconSize +
        "px solid transparent; border-right: " +
        options.iconSize +
        "px solid transparent; border-bottom: " +
        options.iconSize * 2 +
        "px solid " +
        mergedOptions.fillColor +
        "; transform: rotate(" +
        mergedOptions.rotation +
        'deg);"></div>',
    });

    return L.marker(latlng, { icon: icon });
  }

  const labels: any = [
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
    "24:00",
  ];

  const rand = () =>
    Array.from({ length: 10 }, () => Math.floor(Math.random() * 100));
  const randData = rand();

  const chartData: any = {
    labels: labels,
    datasets: [
      {
        label: "Total Peak Circuit power",
        data: total_peakcircuitpower,
        backgroundColor: commonUtils.getRandomColor(0.13),
        borderColor: commonUtils.getRandomColor(2),
        borderWidth: 1,
        fill: "start",
      },
      {
        label: "Total Peak Real Commercial Power",
        data: total_peakrealcommercialpower_kw,
        backgroundColor: commonUtils.getRandomColor(0.13),
        borderColor: commonUtils.getRandomColor(3),
        borderWidth: 1,
        fill: "start",
      },
      {
        label: "Total Peak Real Customer Power kw",
        data: total_peakrealcustomerpower_kw,
        backgroundColor: commonUtils.getRandomColor(0.33),
        borderColor: commonUtils.getRandomColor(1),
        borderWidth: 1,
        fill: "start",
      },
      {
        label: "Total Peak Real Losses kw",
        data: total_peakreallosseskw,
        backgroundColor: commonUtils.getRandomColor(0.17),
        borderColor: commonUtils.getRandomColor(6),
        borderWidth: 1,
        fill: "start",
      },

      {
        label: "Total Peak Real Losses Percentage",
        data: total_peakreallossespercentage,
        backgroundColor: commonUtils.getRandomColor(0.63),
        borderColor: commonUtils.getRandomColor(8),
        borderWidth: 1,
        fill: "start",
      },
      {
        label: "Total Peak Real Residential Power kw",
        data: total_peakrealresidentialpower_kw,
        backgroundColor: commonUtils.getRandomColor(0.73),
        borderColor: commonUtils.getRandomColor(9),
        borderWidth: 1,
        fill: "start",
      },
    ],
  };

  useEffect(() => {
    if (chartRecords?.length > 0) {
      const total_peakcircuitpower = chartRecords?.map(
        (record: ICircuitChartData) =>
          parseFloat(record?.total_peakcircuitpower)
      );
      const total_peakrealcommercialpower_kw = chartRecords?.map(
        (record: ICircuitChartData) =>
          parseFloat(record?.total_peakrealcommercialpower_kw)
      );
      const total_peakrealcustomerpower_kw = chartRecords?.map(
        (record: ICircuitChartData) =>
          parseFloat(record?.total_peakrealcustomerpower_kw)
      );
      const total_peakreallosseskw = chartRecords?.map(
        (record: ICircuitChartData) =>
          parseFloat(record?.total_peakreallosseskw)
      );
      const total_peakreallossespercentage = chartRecords?.map(
        (record: ICircuitChartData) =>
          parseFloat(record?.total_peakreallossespercentage)
      );
      const total_peakrealresidentialpower_kw = chartRecords?.map(
        (record: ICircuitChartData) =>
          parseFloat(record?.total_peakrealresidentialpower_kw)
      );
      setTotal_peakcircuitpower(total_peakcircuitpower);
      setTotal_peakrealcommercialpower_kw(total_peakrealcommercialpower_kw);
      setTotal_peakrealcustomerpower_kw(total_peakrealcustomerpower_kw);
      setTotal_peakreallosseskw(total_peakreallosseskw);
      setTotal_peakreallossespercentage(total_peakreallossespercentage);
      setTotal_peakrealresidentialpower_kw(total_peakrealresidentialpower_kw);
    }
  }, [chartRecords]);

  useEffect(() => {
    const getDataRecords = () => {
      const circuitDataReq: IDataRecordReq = {
        rid: seledtedSubstation?.rid,
        type: seledtedSubstation?.type,
        name: seledtedSubstation?.name,
        time: commonUtils.getCurrentUTCTime(),
      };
      dispatch(mapInfoActions.getCircuitData(circuitDataReq));
    };

    getDataRecords();

    const intervalId = setInterval(getDataRecords, 300000);

    return () => clearInterval(intervalId);
  }, [seledtedSubstation]);

  const layerMappings: any = {
    ENERGY_CONSUMERS: energyConsumerLayer,
    VOLTAGE_REGULATORS: voltageRegulatorLayer,
    BAYS: bayLayer,
    SUBSTATIONS: substationLayer,
    SWITCHING_DEVICES: switchigDevicesLayer,
    TRANSFORMERS: transformerLayer,
  };

  useEffect(() => {
    console.log("oncheckBoxClicked", checkedItem, checkedValue);

    if (checkedItem) {
      const layerToAddOrRemove = layerMappings[checkedItem];

      if (map?.current && layerToAddOrRemove) {
        if (checkedValue) {
          layerToAddOrRemove.addTo(map.current);
        } else {
          map.current.removeLayer(layerToAddOrRemove);
        }
      }
    }
  }, [checkedItem, checkedValue]);

  const extractLatLong = (coordinatesArray: string | any[]) => {
    console.log("coordinatesArrayb", coordinatesArray);

    if (Array.isArray(coordinatesArray)) {
      if (Array.isArray(coordinatesArray[0])) {
        const [lat, long] = coordinatesArray[0][0];
        let updatedLatLong: any = {
          lat: lat,
          long: long,
          zoom: lanlogInfo.zoom,
        };
        if (lanlogInfo.lat !== lat) {
          // map.current.flyTo([lat, long], 14);

          dispatch(setSingleMapData(updatedLatLong));
        }
      } else if (coordinatesArray.length === 2) {
        const [lat, long] = coordinatesArray;
        let updatedLatLong: any = {
          lat: lat,
          long: long,
          zoom: lanlogInfo.zoom,
        };
        if (lanlogInfo.lat !== lat) {
          dispatch(setSingleMapData(updatedLatLong));
        }
      }
    }
  };
  useEffect(() => {
    let arrayTypesToCheck = checkboxData;
    let listData = listDataItem;
    const typeMapping: any = {
      ACLineSegment: "BAYS",
      BaseVoltage: "VOLTAGE_REGULATORS",
      Breaker: "BAYS",
      DisconnectingCircuitBreaker: "BAYS",
      EnergyConsumer: "ENERGY_CONSUMERS",
      Substation: "SUBSTATIONS",
      VoltageLevel: "BAYS",
      Bay: "BAYS",
      PowerTransformer: "TRANSFORMERS",
      PowerTransformerEnd: "TRANSFORMERS",
    };

    let listDataType = listData && listData.type;
    const correspondingKey = typeMapping[listDataType];
    for (const arrayType of arrayTypesToCheck) {
      if (arrayType.checked && arrayType.value === correspondingKey) {
        const currentArray = getMapInfo[correspondingKey];
        if (currentArray && currentArray.length > 0) {
          extractLatLong(currentArray[0].geometry.coordinates);
        }
      } else {
        console.log("uncheck");

        const currentArray = getMapInfo["SUBSTATIONS"];
        if (currentArray && currentArray.length > 0) {
          extractLatLong(currentArray[0].geometry.coordinates);
        }
      }
    }
  }, [listDataItem, checkboxData]);

  useEffect(() => {
    if (chartRecords.length > 0) {
      const _dataset = commonUtils.getMapViewCircuit(chartRecords);
      setDatasets({
        dataset: _dataset.dataset,
      });
    }
  }, [chartRecords]);

  useEffect(() => {
    const fetchData = async () => {
      if (Object.keys(getMapInfo).length !== 0 && load) {
        const mapData = getMapInfo;

        setEnergyConsumerData({
          ...baseData,
          features: mapData["ENERGY_CONSUMERS"],
        });
        setVoltageRegulatorData({
          ...baseData,
          features: mapData["VOLTAGE_REGULATORS"],
        });
        setSwitchingDeviceData({
          ...baseData,
          features: mapData["SWITCHING_DEVICES"],
        });
        setBayData({
          ...baseData,
          features: mapData["BAYS"],
        });
        setSubstationData({
          ...baseData,
          features: mapData["SUBSTATIONS"],
        });
        setTransformerData({
          ...baseData,
          features: mapData["TRANSFORMERS"],
        });
        setLoad(false);
      }
      // })
    };
    const mapOptions = {
      preferCanvas: true,
    };

    map.current = L.map(mapContainerRef.current, mapOptions).setView(
      [
        lanlogInfo.long ? lanlogInfo.long : lng,
        lanlogInfo.lat ? lanlogInfo.lat : lat,
      ],
      lanlogInfo.zoom ? lanlogInfo.zoom : zoom
    );
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        "&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors",
    }).addTo(map.current);
    let currentZoom = map.current.getZoom();

    map.current.on("zoomend", () => {
      currentZoom = map.current.getZoom();
      console.log("Current Zoom Level:", currentZoom);
    });

    map.current.on("moveend", () => {
      const currentCenter: any = map.current.getCenter();
      console.log("Current Latitude:", currentCenter.lat);
      console.log("Current Longitude:", currentCenter.lng);
      let updatedLatLong: any = {
        lat: currentCenter.lng,
        long: currentCenter.lat,
        zoom: currentZoom,
      };
      console.log("zoom", zoom, currentZoom);
      if (zoom !== currentZoom) {
        dispatch(setSingleMapData(updatedLatLong));
      }
    });

    transformerLayer && transformerLayer.remove();
    energyConsumerLayer && energyConsumerLayer.remove();
    voltageRegulatorLayer && voltageRegulatorLayer.remove();
    bayLayer && bayLayer.remove();
    substationLayer && substationLayer.remove();
    switchigDevicesLayer && switchigDevicesLayer.remove();

    checkboxData.forEach((item: { checked: any; value: any }) => {
      if (item.checked) {
        switch (item.value) {
          case "VOLTAGE_REGULATORS":
            voltageRegulatorLayer = createLayer(voltageRegulatorData);
            break;
          case "TRANSFORMERS":
            transformerLayer = createLayer(transformerData);
            break;
          case "ENERGY_CONSUMERS":
            energyConsumerLayer = createLayer(energyConsumerData);
            break;
          case "BAYS":
            bayLayer = createLayer(bayData);
            break;
          case "SUBSTATIONS":
            substationLayer = createLayer(substationData, "SUBSTATION");
            break;
          case "SWITCHING_DEVICES":
            switchigDevicesLayer = createLayer(switchingDeviceData);
            break;
          default:
            break;
        }
      }
    });

    map.current.on("load", fetchData());

    return () => {
      // map.current.remove();
      // transformerLayer.remove();
      // energyConsumerLayer.remove();
      // voltageRegulatorLayer.remove();
      // bayLayer.remove();
      // substationLayer.remove();
      // switchigDevicesLayer.remove();
      map.current.off("zoomend");
      map.current.off("moveend");

      // // Remove layers and map
      transformerLayer = null;
      energyConsumerLayer = null;
      voltageRegulatorLayer = null;
      bayLayer = null;
      substationLayer = null;
      switchigDevicesLayer = null;

      if (map.current) {
        map.current.off("scroll");
        map.current.remove();
        map.current = null;
      }
    };
  }, [
    transformerData,
    energyConsumerData,
    voltageRegulatorData,
    bayData,
    substationData,
    switchingDeviceData,
    load,
    getMapInfo,
    lanlogInfo,
  ]);

  const updateItemColor = (name: string, newColor: string, layerData: any) => {
    const featureToUpdate = layerData?.getLayers().find((layer: any) => {
      return (
        layer.feature.properties.name.toLowerCase() === name.toLocaleLowerCase()
      );
    });

    if (featureToUpdate) {
      featureToUpdate?.setStyle({
        fillColor: newColor,
        radius: 10,
      });
    } else {
      console.log("else");
    }
  };

  return (
    <div
      className="map-alarm-chart-container map-wrapper"
      style={{
        height: windowHeight - 220 + "px",
        overflowY: "auto",
        display: props?.isMapTab ? "" : "none",
      }}
    >
      <div
        className="map-container w-100 col-md-12"
        style={{ height: windowHeight / 2.4 + "px" }}
        ref={mapContainerRef}
      />
      <div
        className={`${
          windowWidth < 1200
            ? "col-sm-12 dtech-map-footer-chart-data-section flex-direction-column"
            : "col-sm-12 dtech-map-footer-chart-data-section flex-direction-row"
        }`}
      >
        <div
          className={`${
            windowWidth < 1200 ? "col-sm-12" : "col-md-6 pe-1 col-sm-6"
          }`}
        >
          <div className="dtech-map-data-section">
            <ChartComponent
              labels={labels}
              datasets={dataset?.dataset}
              yAxisTitle={"kW Total"}
              xAxisTitle={"Hour of the Day"}
            />
          </div>
        </div>
        <div
          className={`${
            windowWidth < 1200 ? "col-sm-12" : "col-md-6 ps-1 col-sm-6"
          }`}
        >
          <div className="dtech-map-data-section" style={{ height: "360px" }}>
            <Alarm alarmData={alarms} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LeafletComponent);
