import { IDataRecordReq } from "../customer/customer.interface";
import { AppThunk } from "../index";
import { seqrOpsActions } from "../seqr-ops/seqr-ops.action";
import {
  IGetModelDetailsReq,
  ISubstationDataRecord,
} from "./substation.interface";
import { substationService } from "./substation.services";
import {
  setSubstationCircuitDataRecords,
  setSubstationDataRecords,
} from "./substation.slice";

const getSubstationData = (getSubststionReq: IGetModelDetailsReq): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await substationService.getSubstationData(
        getSubststionReq
      );

      if (response?.data?.success) {
        if (response.status === 200) {
          const modelData: ISubstationDataRecord = {
            substation: response?.data?.data?.substation,
            circuitPower: response?.data?.data?.circuitPower,
            percentile: response?.data?.data?.percentile,
          };
          dispatch(setSubstationDataRecords(modelData));
        }
      } else if (!response.data.success) {
      }
    } catch (error) {}
  };
};

const getSubstationDataAllRecords = (
  getSubststionReq: IDataRecordReq
): AppThunk => {
  
  return async (dispatch, getState) => {
    dispatch(seqrOpsActions.setLoader(true));
    try {

      const promise1 = substationService.getSubstationDataRecords(
        getSubststionReq
      );
      const promise2 = substationService.getSubstationCircuitDataRecords(
        getSubststionReq
      );
      Promise.all([promise1, promise2]).then((values)=> {
        if (values[0]?.data?.success) {
              if (values[0]?.status === 200) {
                dispatch(setSubstationDataRecords(values[0]?.data?.data));
              }
            }
            if (values[1]?.data?.success) {
              if (values[1]?.status === 200) {
                dispatch(setSubstationCircuitDataRecords(values[1]?.data?.data));
              }
            }
            dispatch(seqrOpsActions.setLoader(false));
       
      }).catch((error)=>{
        dispatch(seqrOpsActions.setLoader(false));

      });

    } catch (error) {
      dispatch(seqrOpsActions.setLoader(false));
      return error;
    }
  };
};
const getSubstationDataRecords = (
  getSubststionReq: IDataRecordReq
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await substationService.getSubstationDataRecords(
        getSubststionReq
      );

      if (response?.data?.success) {
        if (response.status === 200) {
          dispatch(setSubstationDataRecords(response?.data?.data));
        }
      } else if (!response.data.success) {
      }
    } catch (error) {}
  };
};

const getSubstationCircuitDataRecords = (
  getSubststionReq: IDataRecordReq
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await substationService.getSubstationCircuitDataRecords(
        getSubststionReq
      );

      if (response?.data?.success) {
        if (response.status === 200) {
          dispatch(setSubstationCircuitDataRecords(response?.data?.data));
        }
      } else if (!response.data.success) {
      }
    } catch (error) {}
  };
};

export const substationActions = {
  getSubstationData,
  getSubstationDataRecords,
  getSubstationDataAllRecords,
  getSubstationCircuitDataRecords,
};
