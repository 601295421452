import { useEffect, useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import gridSuggetions from "../query.builder/grid.elements.json";
import operatorList from "../query.builder/operators.json";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import closeIcon from "../../assets/images/close_FILL0_wght400_GRAD0_opsz24.svg";
import closeIconldark from "../../assets/images/close_FILL0_wght400_GRAD0_opsz24_dark.svg";

import React from "react";
import { useAppSelector } from "../../store";
export type seg = {
  type: string;
  operator: string;
  value: string;
  logicalOperator: string;
};
export type Props = {
  seg: Array<seg>;
  index: number;
  segment: seg;
  removeSegment: (index: number) => void;
  onChangeSublist:(segment:any,index:number)=>void
};
function QueryBuilderList(props: Props) {
  const [segments, setSegments] = useState(props.seg);
  const [segment, setSegment] = useState(props.segment);

  const [getText, setText] = useState("query here");
  const [getValue, setValue] = useState("");
  const target1 = useRef(null);
  const overlayref4: any = useRef(null);
  const overlayref5: any = useRef(null);
  const overlayref6: any = useRef(null);
  const [showB, setShowB] = useState(false);
  const [showB2, setShowB2] = useState(false);
  const [showB3, setShowB3] = useState(false);
  const theme = useAppSelector((state) => state.seqrOps.theme);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setSegments(props.seg)
  }, [props.seg]);

  useEffect(() => {
    setSegment(props.segment)
  }, [props.segment]);

  const handleClickOutside = (event: any) => {
    if (overlayref4.current && !overlayref4.current.contains(event.target)) {
      setShowB(false);
    }
    if (overlayref5.current && !overlayref5.current.contains(event.target)) {
      setShowB2(false);
    }
    if (overlayref6.current && !overlayref6.current.contains(event.target)) {
      setShowB3(false);
    }
  };
  const onClickdrp2 = () => {
    setShowB(true);
    // handleChange()
  };
  const onClickdrp3 = (value: any, id: string, type: string) => {
    setShowB2(true);
    setShowB(false);
    setText(value);
    handleChange(0, type, id);
    // setFirstVal("hhh")
  };
  const onClickdrp4 = (value: any, id: string) => {
    setShowB2(false);
    setShowB3(true);
    setShowB(false);
    setText(getText + id);
    handleChange(0,"operator",value)
    // setSecondVal("pppp")
  };

  const handleChange = (index: any, field: any, value: any) => {
    const _segment :any = {...segment}
    _segment[field] = value;
    setSegment(_segment);
    props.onChangeSublist(_segment,props.index);

  };

  const removeSegment = (indexToRemove: any) => {
    props.removeSegment(indexToRemove);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      // addSegment();
    }
  };
  const onInputChange = (value: any) => {
    setValue(value);
    handleChange(0, "value", value);
    //   addSegment();
    setText("");
    setShowB3(false);
  };
  const onTextChange = (data: string) => {
    setValue(data);
  };

  return (
    <div ref={target1} key={"kk" + props.index} style={{ marginRight: 5 }}>
      <div
        className="query-span"
        style={{
          // backgroundColor: "#00111c",
          borderRadius: "17px",
          padding: 7,
          textWrap: "nowrap",
          whiteSpace: "nowrap",
        }}
      >
        <span
          onClick={(e) => {
            e.stopPropagation();
            onClickdrp2();
          }}
        >
          {segment.type +
            operatorList.find((op) => op.operator === segment.operator)
              ?.id +
            segment.value?.replaceAll("tr(","").replaceAll(")","")}
        </span>
        <span
          style={{ marginLeft: 2 }}
          onClick={(e) => {
            e.stopPropagation();
            removeSegment(props.index);
          }}
        >
          {theme ? (
            <img src={closeIcon} height={10} width={10}></img>
          ) : (
            <img src={closeIconldark} height={10} width={10}></img>
          )}
        </span>
      </div>

      <Overlay
        ref={overlayref4}
        target={target1.current}
        show={showB}
        placement="left"
      >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            id={theme ? "dark" : "light"}
            {...props}
            style={{
              position: "absolute",
              backgroundColor: "transparent",
              padding: "30px 1px",
              color: "white",
              borderRadius: 3,
              zIndex: 99999,
              ...props.style,
            }}
          >
            <Dropdown.Menu
              className="seqrops-query-section"
              style={{
                marginTop: 5,
                borderRadius: 5,

                border: "1px solid #003d66",
              }}
              show
            >
              <Dropdown.Header style={{ color: "white" }}>
                <div>
                  <span>
                    <b>Suggetions</b>
                  </span>
                </div>
              </Dropdown.Header>
              {gridSuggetions.map((data) => {
                return (
                  <Dropdown.Item
                    className="seqrops-query-builder-list"
                    style={{
                      fontSize: "small",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickdrp3(data.value, data.id, "type");
                    }}
                    eventKey={data.id}
                  >
                    {data.value}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </div>
        )}
      </Overlay>
      <Overlay
        ref={overlayref5}
        target={target1.current}
        show={showB2}
        placement="left"
      >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            id={theme ? "dark" : "light"}
            style={{
              position: "absolute",
              backgroundColor: "tranparent",
              padding: "30px 1px",
              color: "white",
              borderRadius: 3,
              zIndex: 99999,
              ...props.style,
            }}
          >
            <Dropdown.Menu
              className="seqrops-query-section"
              style={{
                marginTop: 5,
                borderRadius: 5,

                border: "1px solid #003d66",
              }}
              show
            >
              <Dropdown.Header style={{ color: "white" }}>
                <div>
                  <span>
                    <b>Operators</b>
                  </span>
                </div>
              </Dropdown.Header>
              {operatorList.map((data) => {
                return (
                  <Dropdown.Item
                    className="seqrops-query-builder-list"
                    style={{
                      fontSize: "small",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClickdrp4(data.operator, data.id);
                    }}
                    eventKey={data.id}
                  >
                    {data.value}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </div>
        )}
      </Overlay>
      <Overlay
        ref={overlayref6}
        target={target1.current}
        show={showB3}
        placement="bottom-start"
      >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            id={theme ? "dark" : "light"}
            style={{
              position: "absolute",
              backgroundColor: "transparent",
              padding: "0px",
              color: "white",
              borderRadius: 3,
              zIndex: 99999,
              display: "flex",
              flexDirection: "row",
              ...props.style,
            }}
          >
            {/* <Autocomplete
                autoComplete={true}
                  onSelect={(e) => e.stopPropagation()}
                  className="seqrops-query-section"
                  onChange={onInputChange}
                  style={{
                    width: "200px",
                  }}
                  
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  options={gridSuggetions.map((value) => value.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
              
                      label="Search input"
                      
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                /> */}
            <div className="seqrops-query-section" style={{ display: "flex" }}>
              <TextField
                onClick={(e) => {
                  e.stopPropagation();
                }}
                placeholder="Enter a value"
                focused={true}
                className="seqrops-query-builder-wrapper"
                onChange={(event) => onTextChange(event.target.value)}
                //  value={(event:any)=>onInputChange(event)}
              />
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onInputChange(getValue);
                }}
                className="seqrops-query-builder-wrapper"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 9,
                  cursor: "pointer",
                }}
              >
                <span className="seqrops-query-section-text">save</span>
              </div>
            </div>
          </div>
        )}
      </Overlay>
    </div>
  );
}
export default QueryBuilderList;
