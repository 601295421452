import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  ASSET_SUITE_LOGIN_URL,
  COOKIE_DETAILS,
  SESSION_KEY,
} from "../../config/default.config";
import {
  IUserAuthorizations,
  IUserTokenDetails,
} from "../../store/keycloak/asset-keycloak.interface";
import Navbar from "../../components/navbar.component/navbar";
import { setTokens } from "../../store/keycloak/asset-keycloak.slice";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { encryptUtils } from "../../utils/encryption.utils";
import { cookieUtils } from "../../utils/cookie.utils";

import { setTheme } from "../../store/seqr-ops/seqr-ops.slice";

function Dashboard() {
  const authorozedScopes = useAppSelector((state) => state.assetKeycloak);
  const dispatch = useAppDispatch();
  const [loggedUser, setLoggedUser] = useState<string>();
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] =
    useState<IUserAuthorizations>();
  const [ready, setReady] = useState<boolean>(false);
  const [zoomedTile, setZoomedTile] = useState<string>("");

  const handleMouseEnter = (tile: string) => {
    setZoomedTile(tile);
  };

  const handleMouseLeave = () => {
    setZoomedTile("");
  };

  useEffect(() => {
    let cookieName = COOKIE_DETAILS.COOKIE_NAME;
    let sessionId = cookieUtils.getCookie(cookieName);

    let decryptedSessionThemeType = encryptUtils.decryptURL(sessionId);

    if (
      decryptedSessionThemeType?.sessionId == null ||
      decryptedSessionThemeType?.sessionId === undefined ||
      decryptedSessionThemeType?.sessionId === ""
    ) {
      localStorage.clear();
      // window.location.replace(ASSET_SUITE_LOGIN_URL);
    } else {
      if (
        sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY) !==
        null
      ) {
        let cookieName = COOKIE_DETAILS.COOKIE_NAME;
        let encryptedCookieData = cookieUtils.getCookie(cookieName);
        let decryptedCookieData = encryptUtils.decryptURL(encryptedCookieData);
        if (decryptedCookieData?.themeType === "LIGHT") {
          dispatch(setTheme(false));
        } else if (decryptedCookieData?.themeType === "DARK") {
          dispatch(setTheme(true));
        }

        let userTokenDetails: IUserTokenDetails =
          sessionStorageUtils.getLocalStorage(
            SESSION_KEY.LOCAL_STORAGE_KEY
          )?.token;
        if (userTokenDetails !== null) {
          setUserScopesFromLocalStorage(userTokenDetails?.userAuthorizations);
          setLoggedUser(userTokenDetails?.username);
          dispatch(setTokens(userTokenDetails));

          const newArray = [];
          let data: any = userTokenDetails?.userAuthorizations;
          const trueKeys = Object.keys(data).filter((key) => data[key]);
          trueKeys.forEach((key) => {
            newArray.push(key);
          });
        }
      }
    }
    setReady(true);
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <>
      {ready && (
        <>
          <div className="seqrops-dashboard-wrapper">
            <div className="seqrops-navbar-section">
              <Navbar
                loggeduser={
                  userScopesFromLocalStorage !== undefined
                    ? loggedUser
                    : authorozedScopes.username
                }
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Dashboard;
