import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICustomerData } from "./customer.interface";

const initialState: ICustomerData = {
  consumer: [],
  connectivityData: [],
  storageSummary: [],
  pvData: [],
  pvSummary: [],
  cunsumerDataCheck: false,
};
const customerSlice = createSlice({
  name: "customerSlice",
  initialState: initialState,
  reducers: {
    setCustomerDataRecords: (state, action: PayloadAction<ICustomerData>) => {
      state.consumer = action?.payload?.consumer;
      state.connectivityData = action?.payload?.connectivityData;
      state.storageSummary = action?.payload?.storageSummary;
      state.pvData = action?.payload?.pvData;
      state.pvSummary = action?.payload?.pvSummary;
      if (
        action?.payload?.consumer.length > 0 &&
        action?.payload?.connectivityData.length > 0 &&
        action?.payload?.pvData.length > 0 &&
        action?.payload?.pvSummary.length > 0 &&
        action?.payload?.storageSummary.length > 0
      ) {
        state.cunsumerDataCheck = true;
      }
    },
    setCustomerLoadsDataRecords: (
      state,
      action: PayloadAction<ICustomerData>
    ) => {
      state.consumer = action?.payload?.consumer;
      if (action?.payload?.consumer.length > 0) {
        state.cunsumerDataCheck = true;
      }
    },
    setCustomerPvDataRecords: (state, action: PayloadAction<ICustomerData>) => {
      state.pvData = action?.payload?.pvData;
      if (action?.payload?.pvData.length > 0) {
        state.cunsumerDataCheck = true;
      }
    },
    setCustomerBusesDataRecords: (
      state,
      action: PayloadAction<ICustomerData>
    ) => {
      state.connectivityData = action?.payload?.connectivityData;
      if (action?.payload?.connectivityData.length > 0) {
        state.cunsumerDataCheck = true;
      }
    },
    setCustomerStorageSummaryDataRecords: (
      state,
      action: PayloadAction<ICustomerData>
    ) => {
      state.storageSummary = action?.payload?.storageSummary;
      if (action?.payload?.storageSummary.length > 0) {
        state.cunsumerDataCheck = true;
      }
    },
    setCustomerPvSummaryDataRecords: (
      state,
      action: PayloadAction<ICustomerData>
    ) => {
      state.pvSummary = action?.payload?.pvSummary;
      if (action?.payload?.pvSummary.length > 0) {
        state.cunsumerDataCheck = true;
      }
    },
  },
});

export const {
  setCustomerDataRecords,
  setCustomerLoadsDataRecords,
  setCustomerPvDataRecords,
  setCustomerBusesDataRecords,
  setCustomerPvSummaryDataRecords,
  setCustomerStorageSummaryDataRecords,
} = customerSlice.actions;
export default customerSlice.reducer;
