import React, { useEffect, useRef, useState } from "react";
import { MapContainer } from "react-leaflet";
import { LatLngTuple } from "leaflet";
import "leaflet/dist/leaflet.css";
import "../styles/styles.scss";
import { LayerControlType, useGIS } from "../hooks/useGIS";
import InnerGISComponent from "./InnerGISComponent";
import GISLayerControl from "../layers/GISLayerControl";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Form } from "react-bootstrap";
import ChartComponent from "../../../components/chart.component/chart";
import { commonUtils } from "../../../utils/common.utils";
import GISLayerFilterComponent from "./GISLayerFilterComponent";
import { IDataRecordReq } from "../../../store/customer/customer.interface";
import { mapInfoActions } from "../../../store/map-info/map-info.action";

// const zoom: number = 13; //16;
// const maxZoom: number = 19; //16;
const labels: any = [
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "24:00",
];
const GISComponent = () => {
  const zoomControl = useAppSelector(
    (state) => state.queryBuilderSlice.zoomControl
  );

  const showFilter = useAppSelector(
    (state) => state.queryBuilderSlice.filterVisibility
  );
  const theme = useAppSelector((state) => state.seqrOps.theme);
  const sidebarRef = useRef<any>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const chartRecords = useAppSelector((state) => state.mapInfoSlice.chartData);
  const [dataset, setDatasets] = useState<any>({
    dataset: [],
  });

  const dispatch: any = useAppDispatch();
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        if (event.target.className !== "gis-filter-toggle") {
          // triggerFilter(!showFilter);
        }
      }
    };

    if (showFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilter]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { bounds, layerControl } = useGIS();

  useEffect(() => {
    if (chartRecords.length > 0) {
      const _dataset = commonUtils.getMapViewCircuit(chartRecords);
      setDatasets({
        dataset: _dataset.dataset,
      });
    }
  }, [chartRecords]);

  useEffect(() => {
    if (layerControl[LayerControlType.TRENDS_CIRCUIT_POWER].checked) {
      const getDataRecords = () => {
        const circuitDataReq: IDataRecordReq = {
          rid: "",
          type: "Substation",
          name: "",
          time: commonUtils.getCurrentUTCTime(),
        };
        dispatch(mapInfoActions.getCircuitData(circuitDataReq));
      };

      getDataRecords();

      const intervalId = setInterval(getDataRecords, 300000);
      return () => clearInterval(intervalId);
    }
  }, [layerControl]);

  return (
    <div className="gis-component" ref={sidebarRef}>
      <GISLayerFilterComponent showFilter={showFilter} />
      <div id="mapWrapper">
        <MapContainer
          id="gisContainerId"
          center={zoomControl.latlng as any}
          zoom={zoomControl.zoom}
          bounds={bounds}
          preferCanvas={true}
          maxZoom={zoomControl.maxZoom}
          doubleClickZoom={false}
        >
          <InnerGISComponent maxZoom={zoomControl.maxZoom} />
          <GISLayerControl />
        </MapContainer>
      </div>
      {layerControl[LayerControlType.TRENDS_CIRCUIT_POWER].checked ? (
        <div
          className="layer-filter-bottom"
          style={{
            width: "calc(100% - 10px)",
            // height: "400px",
            position: "absolute",
            bottom: "10px",
            left: "10px",
            zIndex: 999,
          }}
        >
          <div className={`${"col-sm-12"}`}>
            <div className="dtech-map-data-section">
              <ChartComponent
                height={"45vh"}
                labels={labels}
                datasets={dataset?.dataset}
                yAxisTitle={"kW Total"}
                xAxisTitle={"Hour of the Day"}
                labelPosition={"bottom"}
                labelAlign={"start"}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default GISComponent;
