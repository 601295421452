import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMap, GeoJSON, Popup, Polygon } from "react-leaflet";
import L from "leaflet";
import "leaflet.heat";

import { CircleMenu, CircleMenuItem } from "react-circular-menu";
import { MARKER_SELECTED_TYPE } from "../layers/GISLayerControl";
import SubstationPopupComponent from "./SubstationPopupContent";
import TransformerPopupComponent from "./TransformerPopupComponent";
import ConsumerPopupComponent from "./ConsumerPopupComponent";

const PopupComponent: React.FC<{
  popupType: MARKER_SELECTED_TYPE | null;
  feature: any;
  latlng: any;
  resetMarker: (_feature: any) => void;
  showNetwork: (feature: any) => void;
  showInfo: (feature: any) => void;
}> = React.memo(
  ({ feature, latlng, popupType, resetMarker, showNetwork, showInfo }) => {
    const [type, setType] = useState<MARKER_SELECTED_TYPE | null>(null);
    if (!latlng || !feature) return null;

    useEffect(() => {
      setType(popupType);
    }, [popupType]);

    const memoizedPopupActionContent = useMemo(() => {
      if (!feature) return null;

      return (
        <CircleMenu
          startAngle={0}
          rotationAngle={360}
          itemSize={3}
          radius={2}
          open={true}
          menuToggleClassName="action-trigger"
          rotationAngleInclusive={false}
          menuToggleElement={<span></span>}
        >
          <CircleMenuItem
            className="action-item"
            tooltip="Details"
            onClick={() => {
              setType(MARKER_SELECTED_TYPE.INFO);
              showInfo(feature);
            }}
          >
            <i className="fa fa-info"></i>
          </CircleMenuItem>
          <CircleMenuItem
            className="action-item"
            tooltip="Network"
            onClick={() => {
              showNetwork(feature);
            }}
          >
            <i className="fa fa-link"></i>
          </CircleMenuItem>
        </CircleMenu>
      );
    }, [feature, type]);

    const memoizedPopupContent = useMemo(() => {
      if (!feature) return null;

      switch (feature.properties.gType) {
        case "Substation":
          return (
            <SubstationPopupComponent
              key={type}
              substationRid={feature.properties.gRid}
              substationType={feature.properties.gType}
              substationName={feature.properties.gName}
              showNetwork={() => {
                showNetwork(feature);
              }}
            />
          );
        case "PowerTransformer":
          return (
            <TransformerPopupComponent
              transformerRid={feature.properties.gRid}
              transformerType={feature.properties.gType}
              transformerName={feature.properties.gName}
            />
          );
        case "EnergyConsumer":
        case "BatteryUnit":
        case "PhotoVoltaicUnit":
          return (
            <ConsumerPopupComponent
              consumerRid={feature.properties.gRid}
              consumerType={feature.properties.gType}
              consumerName={feature.properties.gName}
            />
          );
        default:
          return null;
      }
    }, [feature, type]);

    if (type === null) return null;

    if (type === MARKER_SELECTED_TYPE.ACTIONS) {
      return (
        <Popup
          key={
            type === MARKER_SELECTED_TYPE.ACTIONS
              ? "gis-popup-actions-wrapper"
              : type === MARKER_SELECTED_TYPE.INFO
              ? "gis-marker-details-popup"
              : ""
          }
          className={
            type === MARKER_SELECTED_TYPE.ACTIONS
              ? "gis-popup-actions-wrapper"
              : type === MARKER_SELECTED_TYPE.INFO
              ? "gis-marker-details-popup"
              : ""
          }
          position={latlng}
          closeOnClick={true}
          autoClose={true}
          closeOnEscapeKey={true}
          eventHandlers={{
            remove: () => {
              // resetMarker(feature);
            },
          }}
        >
          {type === MARKER_SELECTED_TYPE.ACTIONS
            ? memoizedPopupActionContent
            : type === MARKER_SELECTED_TYPE.INFO
            ? memoizedPopupContent
            : null}
        </Popup>
      );
    } else {
      return (
        <Popup
          // key={
          //   type === MARKER_SELECTED_TYPE.INFO ? "gis-marker-details-popup" : ""
          // }
          className={
            type === MARKER_SELECTED_TYPE.INFO ? "gis-marker-details-popup" : ""
          }
          position={latlng}
          closeOnClick
          autoClose
          closeOnEscapeKey
          eventHandlers={{
            remove: () => {
              // resetMarker(feature);
            },
          }}
        >
          {type === MARKER_SELECTED_TYPE.INFO ? memoizedPopupContent : null}
        </Popup>
      );
    }
  }
);

export default PopupComponent;
