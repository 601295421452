import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMap, GeoJSON, Popup, Polygon, Polyline } from "react-leaflet";
import L, { marker } from "leaflet";
import "leaflet.heat";

import HeatmapLayer from "./GISHeatmapLayer";
import {
  IGISLayerData,
  LayerControlType,
  LayerTypes,
  useGIS,
} from "../hooks/useGIS";
import PopupComponent from "../components/PopupComponent";
import PopupComponentActions from "../components/PopupComponentActions";
import { useAppDispatch, useAppSelector } from "../../../store";
import { queryBuilderActions } from "../../../store/query-builder/query-builder.actions";

const { v4: uuidv4 } = require("uuid");
function generateUUID() {
  return uuidv4();
}

export enum MARKER_SELECTED_TYPE {
  ACTIONS = "ACTIONS",
  INFO = "INFO",
  NETWORK = "NETWORK",
}

const GISLayerControl: React.FC = () => {
  const filterSelection = useAppSelector(
    (state) => state.queryBuilderSlice.filterSelection
  );
  const {
    layerControl,
    substationLayerData,
    transformerLayerData,
    consumerLayerData,
    batteryUnitLayerData,
    pvUnitLayerData,
    line_ABC_HV_LayerData,
    line_ABC_MV_LayerData,
    line_ABC_LV_LayerData,
    line_A_MV_LayerData,
    line_A_LV_LayerData,
    line_B_MV_LayerData,
    line_B_LV_LayerData,
    line_C_MV_LayerData,
    line_C_LV_LayerData,
    lineVoltageLayerData,
    lineLoadingLayerData,
    setLayerControl,
    markerSelected,
    setMarkerSelected,
    // getGisData,
    substationReq,
    transformerReq,
    consumerReq,
    markerReferences,
    areaReferences,
    networkScope,
    resetNetworkScope,
    getGisData,
  } = useGIS();

  const dispatch = useAppDispatch();

  const map = useMap();

  const calculateRadius = (zoom: number, type: string) => {
    const baseRadiusMap: { [key: number]: number } = {
      21: 13,
      20: 12,
      19: 11,
      18: 10,
      17: 10,
      16: 10,
      15: 10,
      14: 9,
      13: 8,
      12: 7,
      11: 6,
      0: 5,
    };

    const scalingFactors: { [key: string]: number } = {
      PowerTransformer: 0.4,
      EnergyConsumer: 0.3,
      BatteryUnit: 0.3,
      PhotoVoltaicUnit: 0.3,
    };

    if (zoom > 14) {
      const scalefactor = zoom / 100 + 0.15;
      scalingFactors.PowerTransformer =
        scalingFactors.PowerTransformer + scalefactor;
      scalingFactors.EnergyConsumer =
        scalingFactors.EnergyConsumer + scalefactor;
      scalingFactors.BatteryUnit = scalingFactors.BatteryUnit + scalefactor;
      scalingFactors.PhotoVoltaicUnit =
        scalingFactors.PhotoVoltaicUnit + scalefactor;
    }

    const baseRadius =
      baseRadiusMap[zoom] !== undefined
        ? baseRadiusMap[zoom]
        : baseRadiusMap[0];
    if (type === "Substation") {
      return baseRadius;
    }

    const scalingFactor =
      scalingFactors[type] !== undefined ? scalingFactors[type] : 1;
    return baseRadius * scalingFactor;
  };
  useEffect(() => {
    const handleZoomEnd = () => {
      const size: any = {};
      for (var markerReferenceKey in markerReferences) {
        const gType =
          markerReferences[markerReferenceKey].feature.properties.gType;

        let radius = calculateRadius(map.getZoom(), gType);

        if (!size[gType]) {
          size[gType] = radius;
        }

        // if (map.getZoom() === 12) {
        //   console.log(gType, radius);
        // }
        markerReferences[markerReferenceKey].setStyle({
          radius: radius,
        });
      }
    };
    if (map) {
      map.invalidateSize();
      map.on("zoomend", handleZoomEnd);
    }

    return () => {
      if (map) {
        map.off("zoomend", handleZoomEnd);
      }
    };
  }, [
    map,
    substationLayerData.key,
    transformerLayerData.key,
    consumerLayerData.key,
    lineVoltageLayerData.key,
    lineLoadingLayerData.key,
    line_ABC_HV_LayerData.key,
    line_ABC_MV_LayerData.key,
    line_ABC_LV_LayerData.key,
    line_A_MV_LayerData.key,
    line_A_LV_LayerData.key,
    line_B_MV_LayerData.key,
    line_B_LV_LayerData.key,
    line_C_MV_LayerData.key,
    line_C_LV_LayerData.key,
    batteryUnitLayerData.key,
    pvUnitLayerData.key,
  ]);

  const circleStyle = (feature: any) => {
    const { properties } = feature;
    const fillColor = properties.color || "black";
    const weight = properties.weight || 1;
    const opacity = properties.opacity || 1;
    const fillOpacity = properties.fillOpacity || 1;
    const radius = calculateRadius(map.getZoom(), properties.gType);

    return {
      fillColor: fillColor,
      color: fillColor,
      weight: weight,
      opacity: opacity,
      fillOpacity: fillOpacity,
      radius: radius,
      pane: "tooltipPane",
    };
  };

  const lineStyle = (feature: any) => {
    const { properties } = feature;
    return {
      color: properties?.color || "black",
      weight: Number(properties?.width || "1") * 2.5,
      opacity: 1,
    };
  };

  const handleAreaGeometry = (_feature: any, event: string) => {
    for (const key in areaReferences) {
      areaReferences[key]?.remove();
    }

    if (event === "ADD" && _feature?.properties?.areaGeometry) {
      const geojsonData = {
        type: "FeatureCollection",
        features: [
          {
            ..._feature.properties.areaGeometry,
          },
        ],
      };
      const layer = L.geoJson(geojsonData as any, {
        style: {
          color: "rgba(72, 113, 247, 1)",
          weight: 0.75,
        },
      }).addTo(map);

      areaReferences[_feature.properties.gRid] = layer;

      areaReferences[_feature.properties.gRid].addTo(map);
    }
  };

  const pointToLayer = (feature: any, latlng: any) => {
    const markerReferenceKey = feature.properties.gRid;
    if (!markerReferences[markerReferenceKey]) {
      const circleMarker = L.circleMarker(latlng, circleStyle(feature));

      circleMarker.on("click", (e: any) => {
        let _name = feature.properties.gName;
        let _type = feature.properties.gType;
        let _rid = feature.properties.gRid;
        if (
          feature.properties.gType === "EnergyConsumer" ||
          feature.properties.gType === "BatteryUnit" ||
          feature.properties.gType === "PhotoVoltaicUnit"
        ) {
          const _namePrefix =
            feature.properties.gType === "PhotoVoltaicUnit"
              ? "pv_load_"
              : feature.properties.gType === "BatteryUnit"
              ? "storage_"
              : "load_";

          _name = "load_" + _name.split(_namePrefix)[1];
          _type = "EnergyConsumer";
          if (feature.properties.gType !== "EnergyConsumer") {
            _rid = feature.properties.gConsumerRid;
          }
        }
        // dispatch(
        //   queryBuilderActions.addTab({
        //     value: _type + "_" + _rid,
        //     label: _name,
        //     rid: _rid,
        //     type: _type,
        //   })
        // );

        if (
          feature.properties.gType === "Substation" ||
          feature.properties.gType === "PowerTransformer"
        ) {
          setMarkerSelected({
            key: generateUUID(),
            latlng: e.latlng,
            feature: feature,
            type: MARKER_SELECTED_TYPE.ACTIONS,
          });
          handleAreaGeometry(feature, "ADD");
        } else if (
          feature.properties.gType === "EnergyConsumer" ||
          feature.properties.gType === "BatteryUnit" ||
          feature.properties.gType === "PhotoVoltaicUnit"
        ) {
          setMarkerSelected({
            key: generateUUID(),
            latlng: e.latlng,
            feature: feature,
            type: MARKER_SELECTED_TYPE.INFO,
          });
        }
      });
      markerReferences[markerReferenceKey] = circleMarker;
    }
    return markerReferences[markerReferenceKey];
  };

  const renderLinesNew = (_key: string) => {
    const ___keys: { [key: string]: string } = {
      NETWORK_LINE_ABC_HV: _key + "" + line_ABC_HV_LayerData.key,
      NETWORK_LINE_ABC_MV: _key + "" + line_ABC_MV_LayerData.key,
      NETWORK_LINE_ABC_LV: _key + "" + line_ABC_LV_LayerData.key,
      NETWORK_LINE_A_MV: _key + "" + line_A_MV_LayerData.key,
      NETWORK_LINE_A_LV: _key + "" + line_A_LV_LayerData.key,
      NETWORK_LINE_B_MV: _key + "" + line_B_MV_LayerData.key,
      NETWORK_LINE_B_LV: _key + "" + line_B_LV_LayerData.key,
      NETWORK_LINE_C_MV: _key + "" + line_C_MV_LayerData.key,
      NETWORK_LINE_C_LV: _key + "" + line_C_LV_LayerData.key,
    };

    return (
      <>
        {layerControl[LayerControlType.NETWORK_LINE_ABC_HV].checked ? (
          <GeoJSON
            key={___keys["NETWORK_LINE_ABC_HV"]}
            data={line_ABC_HV_LayerData.LINE_ABC_HV as any}
            style={lineStyle}
          />
        ) : null}
        {layerControl[LayerControlType.NETWORK_LINE_ABC_MV].checked ? (
          <GeoJSON
            key={___keys["NETWORK_LINE_ABC_MV"]}
            data={line_ABC_MV_LayerData.LINE_ABC_MV as any}
            style={lineStyle}
          />
        ) : null}
        {layerControl[LayerControlType.NETWORK_LINE_ABC_LV].checked ? (
          <GeoJSON
            key={___keys["NETWORK_LINE_ABC_LV"]}
            data={line_ABC_LV_LayerData.LINE_ABC_LV as any}
            style={lineStyle}
          />
        ) : null}
        {layerControl[LayerControlType.NETWORK_LINE_A_MV].checked ? (
          <GeoJSON
            key={___keys["NETWORK_LINE_A_MV"]}
            data={line_A_MV_LayerData.LINE_A_MV as any}
            style={lineStyle}
          />
        ) : null}
        {layerControl[LayerControlType.NETWORK_LINE_A_LV].checked ? (
          <GeoJSON
            key={___keys["NETWORK_LINE_A_LV"]}
            data={line_A_LV_LayerData.LINE_A_LV as any}
            style={lineStyle}
          />
        ) : null}
        {layerControl[LayerControlType.NETWORK_LINE_B_MV].checked ? (
          <GeoJSON
            key={___keys["NETWORK_LINE_B_MV"]}
            data={line_B_MV_LayerData.LINE_B_MV as any}
            style={lineStyle}
          />
        ) : null}
        {layerControl[LayerControlType.NETWORK_LINE_B_LV].checked ? (
          <GeoJSON
            key={___keys["NETWORK_LINE_B_LV"]}
            data={line_B_LV_LayerData.LINE_B_LV as any}
            style={lineStyle}
          />
        ) : null}
        {layerControl[LayerControlType.NETWORK_LINE_C_MV].checked ? (
          <GeoJSON
            key={___keys["NETWORK_LINE_C_MV"]}
            data={line_C_MV_LayerData.LINE_C_MV as any}
            style={lineStyle}
          />
        ) : null}
        {layerControl[LayerControlType.NETWORK_LINE_C_LV].checked ? (
          <GeoJSON
            key={___keys["NETWORK_LINE_C_LV"]}
            data={line_C_LV_LayerData.LINE_C_LV as any}
            style={lineStyle}
          />
        ) : null}
      </>
    );
  };

  const renderConsumers = (_key: string) => {
    return (
      <>
        {layerControl[LayerControlType.NETWORK_CONSUMERS].checked && (
          <>
            <GeoJSON
              key={_key + consumerLayerData.key}
              data={consumerLayerData.ENERGY_CONSUMERS as any}
              pointToLayer={pointToLayer}
            />
          </>
        )}
      </>
    );
  };
  const renderBatteryUnit = (_key: string) => {
    return (
      <>
        {layerControl[LayerControlType.NETWORK_BATTERY_UNIT].checked && (
          <>
            <GeoJSON
              key={_key + "" + batteryUnitLayerData.key}
              data={batteryUnitLayerData.BATTERY_UNIT as any}
              pointToLayer={pointToLayer}
            />
          </>
        )}
      </>
    );
  };

  const renderPvUnit = (_key: string) => {
    return (
      <>
        {layerControl[LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT].checked && (
          <>
            <GeoJSON
              key={_key + "" + pvUnitLayerData.key}
              data={pvUnitLayerData.PHOTO_VOLTAIC_UNIT as any}
              pointToLayer={pointToLayer}
            />
          </>
        )}
      </>
    );
  };

  const renderTransformers = (_key: string) => {
    return (
      <>
        {layerControl[LayerControlType.NETWORK_TRANSFORMERS].checked && (
          <>
            <GeoJSON
              key={_key + transformerLayerData.key}
              data={transformerLayerData.TRANSFORMERS as any}
              pointToLayer={pointToLayer}
            />
          </>
        )}
      </>
    );
  };

  const renderSubstations = (_key: string) => {
    return (
      <>
        {layerControl[LayerControlType.NETWORK_SUBSTATIONS].checked ? (
          <GeoJSON
            key={_key}
            data={substationLayerData.SUBSTATIONS as any}
            pointToLayer={pointToLayer}
          />
        ) : null}
      </>
    );
  };

  const renderHeatmap = (
    heatmapData: IGISLayerData,
    prefix: string,
    visibility: boolean
  ) => {
    const _data: any = {
      [LayerControlType.NETWORK_LINE_ABC_HV]: heatmapData.LINE_ABC_HV,
      [LayerControlType.NETWORK_LINE_ABC_MV]: heatmapData.LINE_ABC_MV,
      [LayerControlType.NETWORK_LINE_ABC_LV]: heatmapData.LINE_ABC_LV,
      [LayerControlType.NETWORK_LINE_A_MV]: heatmapData.LINE_A_MV,
      [LayerControlType.NETWORK_LINE_A_LV]: heatmapData.LINE_A_LV,
      [LayerControlType.NETWORK_LINE_B_MV]: heatmapData.LINE_B_MV,
      [LayerControlType.NETWORK_LINE_B_LV]: heatmapData.LINE_B_LV,
      [LayerControlType.NETWORK_LINE_C_MV]: heatmapData.LINE_C_MV,
      [LayerControlType.NETWORK_LINE_C_LV]: heatmapData.LINE_C_LV,
    };

    return (
      visibility && (
        <>
          <HeatmapLayer
            map={map}
            data={_data[LayerControlType.NETWORK_LINE_ABC_HV]}
            show={true}
            key={prefix + "_LINE_ABC_HV"}
          />
        </>
      )
    );
  };

  const memoizedPopupComponentProps = useMemo(
    () => ({
      latlng: markerSelected.latlng,
      feature: markerSelected.feature,
      popupType: markerSelected.type,
      verificationKey: markerSelected.key,
    }),
    [markerSelected]
  );

  return (
    <>
      {renderLinesNew(
        markerSelected.key +
          "-renderLinesNew" +
          substationLayerData.key +
          "-" +
          transformerLayerData.key +
          "-" +
          consumerLayerData.key
      )}
      {renderSubstations(
        markerSelected.key + "-renderSubstations" + substationLayerData.key
      )}
      {renderTransformers(
        markerSelected.key + "-renderTransformers" + substationLayerData.key
      )}
      {renderConsumers(
        markerSelected.key +
          "-renderConsumers" +
          substationLayerData.key +
          "-" +
          transformerLayerData.key
      )}
      {renderBatteryUnit(
        markerSelected.key +
          "-renderBatteryUnit" +
          substationLayerData.key +
          "-" +
          transformerLayerData.key +
          "-" +
          consumerLayerData.key
      )}
      {renderPvUnit(
        markerSelected.key +
          "-renderPvUnit" +
          substationLayerData.key +
          "-" +
          transformerLayerData.key +
          "-" +
          consumerLayerData.key
      )}

      {renderHeatmap(
        lineLoadingLayerData,
        "LOADING-" + lineLoadingLayerData.key,
        layerControl[LayerControlType.HEATMAP_LOADING].checked || false
      )}
      {renderHeatmap(
        lineVoltageLayerData,
        "VOLTAGE-" + lineVoltageLayerData.key,
        layerControl[LayerControlType.HEATMAP_VOLTAGE].checked || false
      )}
      {markerSelected.feature &&
      markerSelected.type === MARKER_SELECTED_TYPE.ACTIONS ? (
        <PopupComponentActions
          {...memoizedPopupComponentProps}
          resetMarker={(_feature: any) => {
            setMarkerSelected({
              key: markerSelected.key,
              latlng: null,
              feature: null,
              type: null,
            });
            for (const key in areaReferences) {
              areaReferences[key]?.remove();
            }
          }}
          showInfo={(_feature: any) => {
            for (const key in areaReferences) {
              areaReferences[key]?.remove();
            }
            setMarkerSelected({
              key: markerSelected.key,
              latlng: markerSelected.latlng,
              feature: _feature,
              type: MARKER_SELECTED_TYPE.INFO,
            });
          }}
          showNetwork={(_feature: any) => {
            const mapping: { [key: string]: string } = {
              [LayerControlType.NETWORK_SUBSTATIONS]: LayerTypes.SUBSTATIONS,
              [LayerControlType.NETWORK_TRANSFORMERS]: LayerTypes.TRANSFORMERS,
              [LayerControlType.NETWORK_CONSUMERS]: LayerTypes.CONSUMERS,
              [LayerControlType.NETWORK_BATTERY_UNIT]: LayerTypes.BATTERY_UNIT,
              [LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT]:
                LayerTypes.PHOTO_VOLTAIC_UNIT,
              [LayerControlType.NETWORK_LINE_ABC_HV]: LayerTypes.LINE_ABC_HV,
              [LayerControlType.NETWORK_LINE_ABC_MV]: LayerTypes.LINE_ABC_MV,
              [LayerControlType.NETWORK_LINE_ABC_LV]: LayerTypes.LINE_ABC_LV,
              [LayerControlType.NETWORK_LINE_A_MV]: LayerTypes.LINE_A_MV,
              [LayerControlType.NETWORK_LINE_A_LV]: LayerTypes.LINE_A_LV,
              [LayerControlType.NETWORK_LINE_B_MV]: LayerTypes.LINE_B_MV,
              [LayerControlType.NETWORK_LINE_B_LV]: LayerTypes.LINE_B_LV,
              [LayerControlType.NETWORK_LINE_C_MV]: LayerTypes.LINE_C_MV,
              [LayerControlType.NETWORK_LINE_C_LV]: LayerTypes.LINE_C_LV,
            };

            let itemsToAdd: string[] = [];
            let itemsToReset: string[] = [];
            let _layerControl = { ...layerControl };

            for (const key in areaReferences) {
              areaReferences[key]?.remove();
            }

            if (_feature?.properties?.gType === "Substation") {
              resetNetworkScope([
                LayerControlType.NETWORK_SUBSTATIONS,
                LayerControlType.NETWORK_SUBSTATIONS +
                  "_" +
                  _feature?.properties?.gRid,
              ]);

              itemsToAdd = [
                LayerControlType.NETWORK_SUBSTATIONS,
                LayerControlType.NETWORK_TRANSFORMERS,
                LayerControlType.NETWORK_LINE_ABC_HV,
                LayerControlType.NETWORK_LINE_ABC_MV,
                LayerControlType.NETWORK_LINE_A_MV,
                LayerControlType.NETWORK_LINE_B_MV,
                LayerControlType.NETWORK_LINE_C_MV,
              ];

              itemsToReset = [
                LayerControlType.NETWORK_SUBSTATIONS,
                LayerControlType.NETWORK_TRANSFORMERS,
                LayerControlType.NETWORK_CONSUMERS,
                LayerControlType.NETWORK_BATTERY_UNIT,
                LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT,
                LayerControlType.NETWORK_LINE_ABC_HV,
                LayerControlType.NETWORK_LINE_ABC_MV,
                LayerControlType.NETWORK_LINE_ABC_LV,
                LayerControlType.NETWORK_LINE_A_MV,
                LayerControlType.NETWORK_LINE_A_LV,
                LayerControlType.NETWORK_LINE_B_MV,
                LayerControlType.NETWORK_LINE_B_LV,
                LayerControlType.NETWORK_LINE_C_MV,
                LayerControlType.NETWORK_LINE_C_LV,
              ];

              for (const item of Array.from(new Set(itemsToReset))) {
                if (mapping[item]) {
                  getGisData("", "", undefined, true, mapping[item]);
                }
              }
              for (const item of Array.from(new Set(itemsToAdd))) {
                if (mapping[item]) {
                  getGisData("", "", undefined, false, mapping[item]);
                }
              }
            } else if (
              networkScope.includes(LayerControlType.NETWORK_SUBSTATIONS) &&
              _feature?.properties?.gType === "PowerTransformer"
            ) {
              resetNetworkScope([
                ...networkScope.filter((item) =>
                  item.startsWith(LayerControlType.NETWORK_SUBSTATIONS)
                ),
                LayerControlType.NETWORK_TRANSFORMERS,
                LayerControlType.NETWORK_TRANSFORMERS +
                  "_" +
                  _feature?.properties?.gRid,
              ]);
              itemsToReset = [
                LayerControlType.NETWORK_CONSUMERS,
                LayerControlType.NETWORK_BATTERY_UNIT,
                LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT,
                LayerControlType.NETWORK_LINE_ABC_LV,
                LayerControlType.NETWORK_LINE_A_LV,
                LayerControlType.NETWORK_LINE_B_LV,
                LayerControlType.NETWORK_LINE_C_LV,
              ];
              itemsToAdd = [
                LayerControlType.NETWORK_SUBSTATIONS,
                LayerControlType.NETWORK_TRANSFORMERS,
                LayerControlType.NETWORK_CONSUMERS,
                LayerControlType.NETWORK_BATTERY_UNIT,
                LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT,
                LayerControlType.NETWORK_LINE_ABC_HV,
                LayerControlType.NETWORK_LINE_ABC_MV,
                LayerControlType.NETWORK_LINE_ABC_LV,
                LayerControlType.NETWORK_LINE_A_MV,
                LayerControlType.NETWORK_LINE_A_LV,
                LayerControlType.NETWORK_LINE_B_MV,
                LayerControlType.NETWORK_LINE_B_LV,
                LayerControlType.NETWORK_LINE_C_MV,
                LayerControlType.NETWORK_LINE_C_LV,
              ];

              for (const item of Array.from(new Set(itemsToReset))) {
                if (mapping[item]) {
                  getGisData("", "", undefined, true, mapping[item]);
                }
              }
              for (const item of Array.from(new Set(itemsToAdd))) {
                if (mapping[item]) {
                  getGisData("", "", undefined, false, mapping[item]);
                }
              }
            } else if (
              !networkScope.includes(LayerControlType.NETWORK_SUBSTATIONS) &&
              _feature?.properties?.gType === "PowerTransformer"
            ) {
              resetNetworkScope([
                LayerControlType.NETWORK_TRANSFORMERS,
                LayerControlType.NETWORK_TRANSFORMERS +
                  "_" +
                  _feature?.properties?.gRid,
              ]);
              itemsToAdd = [
                LayerControlType.NETWORK_SUBSTATIONS,
                LayerControlType.NETWORK_TRANSFORMERS,
                LayerControlType.NETWORK_LINE_ABC_HV.toString(),
                LayerControlType.NETWORK_CONSUMERS.toString(),
                LayerControlType.NETWORK_BATTERY_UNIT,
                LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT,
                LayerControlType.NETWORK_LINE_ABC_LV.toString(),
                LayerControlType.NETWORK_LINE_A_LV.toString(),
                LayerControlType.NETWORK_LINE_B_LV.toString(),
                LayerControlType.NETWORK_LINE_C_LV.toString(),
              ];

              itemsToReset = [
                LayerControlType.NETWORK_SUBSTATIONS,
                LayerControlType.NETWORK_TRANSFORMERS,
                LayerControlType.NETWORK_LINE_ABC_HV.toString(),
                LayerControlType.NETWORK_CONSUMERS.toString(),
                LayerControlType.NETWORK_BATTERY_UNIT,
                LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT,
                LayerControlType.NETWORK_LINE_ABC_LV.toString(),
                LayerControlType.NETWORK_LINE_A_LV.toString(),
                LayerControlType.NETWORK_LINE_B_LV.toString(),
                LayerControlType.NETWORK_LINE_C_LV.toString(),
              ];

              for (const item of Array.from(new Set(itemsToReset))) {
                if (mapping[item]) {
                  getGisData("", "", undefined, true, mapping[item]);
                }
              }
              for (const item of Array.from(new Set(itemsToAdd))) {
                if (mapping[item]) {
                  getGisData("", "", undefined, false, mapping[item]);
                }
              }
            }

            if (_feature?.properties?.gType === "Substation") {
              _layerControl[LayerControlType.__SCOPE__].checked = true;
              _layerControl[LayerControlType.__SCOPE__].label =
                LayerControlType.NETWORK_SUBSTATIONS;
              _layerControl[LayerControlType.__SCOPE__].value =
                _feature?.properties?.gRid;
              _layerControl[LayerControlType.__SCOPE__].color = JSON.stringify({
                itemsToAdd,
                itemsToReset,
              });

              _layerControl[LayerControlType.NETWORK_SUBSTATIONS].checked =
                true;
              _layerControl[LayerControlType.NETWORK_TRANSFORMERS].checked =
                true;
              _layerControl[LayerControlType.NETWORK_CONSUMERS].checked = true;
              _layerControl[LayerControlType.NETWORK_BATTERY_UNIT].checked =
                true;
              _layerControl[
                LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT
              ].checked = true;

              _layerControl[LayerControlType.NETWORK_LINES].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_ABC_LV].checked =
                true;
              _layerControl[LayerControlType.NETWORK_LINE_B_MV].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_ABC_HV].checked =
                true;
              _layerControl[LayerControlType.NETWORK_LINE_ABC_MV].checked =
                true;
              _layerControl[LayerControlType.NETWORK_LINE_B_LV].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_C_MV].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_A_MV].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_C_LV].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_A_LV].checked = true;

              setLayerControl(_layerControl);
              setMarkerSelected({
                key: markerSelected.key,
                latlng: null,
                feature: null,
                type: null,
              });
            } else if (_feature?.properties?.gType === "PowerTransformer") {
              _layerControl[LayerControlType.__SCOPE__].checked = true;
              _layerControl[LayerControlType.__SCOPE__].label =
                LayerControlType.NETWORK_TRANSFORMERS;
              _layerControl[LayerControlType.__SCOPE__].value =
                _feature?.properties?.gRid;
              _layerControl[LayerControlType.__SCOPE__].color = JSON.stringify({
                itemsToAdd,
                itemsToReset,
              });

              _layerControl[LayerControlType.NETWORK_SUBSTATIONS].checked =
                true;
              _layerControl[LayerControlType.NETWORK_TRANSFORMERS].checked =
                true;
              _layerControl[LayerControlType.NETWORK_CONSUMERS].checked = true;
              _layerControl[LayerControlType.NETWORK_BATTERY_UNIT].checked =
                true;
              _layerControl[
                LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT
              ].checked = true;
              _layerControl[LayerControlType.NETWORK_LINES].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_ABC_LV].checked =
                true;
              _layerControl[LayerControlType.NETWORK_LINE_B_MV].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_ABC_HV].checked =
                true;
              _layerControl[LayerControlType.NETWORK_LINE_ABC_MV].checked =
                true;
              _layerControl[LayerControlType.NETWORK_LINE_B_LV].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_C_MV].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_A_MV].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_C_LV].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_A_LV].checked = true;

              setLayerControl(_layerControl);
              setMarkerSelected({
                key: markerSelected.key,
                latlng: null,
                feature: null,
                type: null,
              });
            }
            const checkedItems: any[] = [];
            Object.entries(_layerControl).forEach(([type, controlItem]) => {
              if (controlItem.checked) {
                checkedItems.push(type);
              }
            });
            dispatch(
              queryBuilderActions.setMapFilterSelection({
                prevChecked: filterSelection.checked,
                checked: checkedItems,
              })
            );
          }}
        />
      ) : null}
      {markerSelected.feature &&
      markerSelected.type === MARKER_SELECTED_TYPE.INFO ? (
        <PopupComponent
          {...memoizedPopupComponentProps}
          resetMarker={(_feature: any) => {
            // setMarkerSelected({
            //   key: markerSelected.key,
            //   latlng: null,
            //   feature: null,
            //   type: null,
            // });
            for (const key in areaReferences) {
              areaReferences[key]?.remove();
            }
          }}
          showInfo={(_feature: any) => {
            for (const key in areaReferences) {
              areaReferences[key]?.remove();
            }
          }}
          showNetwork={(_feature: any) => {
            for (const key in areaReferences) {
              areaReferences[key]?.remove();
            }
            let _layerControl = { ...layerControl };
            if (_feature?.properties?.gType === "Substation") {
              _layerControl[LayerControlType.__SCOPE__].checked = true;
              _layerControl[LayerControlType.__SCOPE__].label =
                LayerControlType.NETWORK_SUBSTATIONS;
              _layerControl[LayerControlType.__SCOPE__].value =
                _feature?.properties?.gRid;

              _layerControl[LayerControlType.NETWORK_SUBSTATIONS].checked =
                true;
              _layerControl[LayerControlType.NETWORK_TRANSFORMERS].checked =
                true;
              _layerControl[LayerControlType.NETWORK_CONSUMERS].checked = true;
              _layerControl[LayerControlType.NETWORK_BATTERY_UNIT].checked =
                true;
              _layerControl[
                LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT
              ].checked = true;
              _layerControl[LayerControlType.NETWORK_LINES].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_ABC_LV].checked =
                true;
              _layerControl[LayerControlType.NETWORK_LINE_B_MV].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_ABC_HV].checked =
                true;
              _layerControl[LayerControlType.NETWORK_LINE_ABC_MV].checked =
                true;
              _layerControl[LayerControlType.NETWORK_LINE_B_LV].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_C_MV].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_A_MV].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_C_LV].checked = true;
              _layerControl[LayerControlType.NETWORK_LINE_A_LV].checked = true;

              // getGisData(
              //   "",
              //   substationReq.sourceModel,
              //   undefined,
              //   true,
              //   LayerTypes.SUBSTATIONS
              // );
              // getGisData(
              //   "",
              //   transformerReq.sourceModel,
              //   undefined,
              //   true,
              //   LayerTypes.TRANSFORMERS
              // );
              // getGisData(
              //   "",
              //   consumerReq.sourceModel,
              //   undefined,
              //   true,
              //   LayerTypes.CONSUMERS
              // );

              setLayerControl(_layerControl);
              setMarkerSelected({
                key: markerSelected.key,
                latlng: null,
                feature: null,
                type: null,
              });
            }
            // const checkedItems: any[] = [];
            // Object.entries(_layerControl).forEach(([type, controlItem]) => {
            //   if (controlItem.checked) {
            //     checkedItems.push(type);
            //   }
            // });
            // dispatch(
            //   queryBuilderActions.setMapFilterSelection({
            //     prevChecked: filterSelection.checked,
            //     checked: checkedItems,
            //   })
            // );
          }}
        />
      ) : null}
    </>
  );
};
export default GISLayerControl;
