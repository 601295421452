import React, { useEffect, useRef, useState } from "react";
import Chart, {
  ChartOptions,
  ScriptableContext,
  TimeScaleTimeOptions,
} from "chart.js/auto";
import "chartjs-adapter-moment";
import moment from "moment";
const ScatterChartComponent = (props: any) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const [showLegend, setShowLegend] = useState<boolean>(false);

  useEffect(() => {
    if (!chartRef.current) return;

    const ctx = chartRef.current.getContext("2d");
    if (!ctx) return;
    const myChart = new Chart(ctx, {
      type: "scatter",
      data: {
        datasets: props?.datasets,
      },
      options: {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        events: ["mousemove", "mouseout", "click", "touchstart", "touchmove"],
        interaction: {
          mode: "nearest",
        },
        layout: {
          padding: {
            right: 0,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: props.yAxisTitle,
            },
          },
          x: {
            title: {
              display: true,
              text: props.xAxisTitle,
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: props.title,
          },
          legend: {
            position: "right",
            display: false,
          },
          tooltip: {
            enabled: true,
            intersect: false,
          },
        },
      },
    });
    myChart.update();
    return () => {
      myChart.destroy();
    };
  }, [props.datasets, showLegend]);

  const toggleLegend = () => {
    setShowLegend(!showLegend);
  };

  return (
    <span className="chart-span" style={{ height: 350, display: "block" }}>
      <canvas ref={chartRef} id="myChart" />
    </span>
  );
};

export default ScatterChartComponent;
