import axios from "axios";
import { GRID_MONITORING_API } from "../../config/default.config";
import { IGetDataRecordsReq } from "../substation/substation.interface";
import { IDataRecordReq } from "./customer.interface";

const getCustomerData = async (customerDetailsReq: IGetDataRecordsReq) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_CUSTOMER_DETAILS,
    customerDetailsReq
  );

  return response;
};

const getCustomerLoadsDataRecords = async (
  customerDetailsReq: IDataRecordReq
) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_CUSTOMER_LOADS_DATA_RECORDS,
    customerDetailsReq
  );

  return response;
};

const getCustomerPvDataRecords = async (customerDetailsReq: IDataRecordReq) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_CUSTOMER_PV_DATA_RECORDS,
    customerDetailsReq
  );

  return response;
};

const getCustomerBusesDataRecords = async (
  customerDetailsReq: IDataRecordReq
) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_CUSTOMER_BUSES_DATA_RECORDS,
    customerDetailsReq
  );

  return response;
};

const getCustomerStorageSummaryDataRecords = async (
  customerDetailsReq: IDataRecordReq
) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_CUSTOMER_STORAGE_SUMMARY_DATA_RECORDS,
    customerDetailsReq
  );

  return response;
};

const getCustomerPvSummaryDataRecords = async (
  customerDetailsReq: IDataRecordReq
) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_CUSTOMER_PV_SUMMARY_DATA_RECORDS,
    customerDetailsReq
  );

  return response;
};
export const customerService = {
  getCustomerData,
  getCustomerLoadsDataRecords,
  getCustomerBusesDataRecords,
  getCustomerPvDataRecords,
  getCustomerPvSummaryDataRecords,
  getCustomerStorageSummaryDataRecords,
};
