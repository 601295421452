import { AppThunk } from "../index";
import { seqrOpsActions } from "../seqr-ops/seqr-ops.action";
import {
  IGetDataRecordsReq,
  IGetModelDetailsReq,
  IModelData,
} from "./transformer.interface";
import { transformerService } from "./transformer.services";
import {
  setTransformerBatteryUnitDataRecords,
  setTransformerBusesDataRecords,
  setTransformerEnergyConsumerDataRecords,
  setTransformerLinesDataRecords,
  setTransformerPvUnitDataRecords,
} from "./transformer.slice";

const getAllTransformerDataRecords = (
  customerDetailsReq: IGetDataRecordsReq
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(seqrOpsActions.setLoader(true));

    try {
    const promise1 = transformerService.getTransformerData(
        customerDetailsReq
      );
      
      const promise2 = transformerService.getTransformerBateryUnitDataRecords(
          customerDetailsReq
        );
      
        const promise3 = transformerService.getTransformerPvUnitDataRecords(
        customerDetailsReq
      )
     

      const promise4 = transformerService.getTransformerBusesDataRecords(
        customerDetailsReq
      )
      
      const promise5 = transformerService.getTransformerLinesDataRecords(
        customerDetailsReq
      );

      Promise.all([promise1, promise2,promise3,promise4,promise5]).then((values)=> {
        if (values[0]?.status === 200) {
         
          if (values[0]?.data?.data) {
            dispatch(
              setTransformerEnergyConsumerDataRecords(values[0]?.data?.data)
            );
          }
        }
        if (values[1]?.status === 200) {
         
          if (values[1]?.data?.data) {
            dispatch(setTransformerBatteryUnitDataRecords(values[1]?.data?.data));
          }
        }
        if (values[2]?.status === 200) {
  
          if (values[2]?.data?.data) {
            dispatch(setTransformerPvUnitDataRecords(values[2]?.data?.data));
          }
        }
        

        if (values[3]?.status === 200) {
          if (values[3]?.data?.data) {
            dispatch(setTransformerBusesDataRecords(values[3]?.data?.data));
          }
        }
        
        if (values[4]?.status === 200) {
          if (values[4]?.data?.data) {
            dispatch(setTransformerLinesDataRecords(values[4]?.data?.data));
          }
        }


            dispatch(seqrOpsActions.setLoader(false));
      }).catch((error)=> {
        
        dispatch(seqrOpsActions.setLoader(false))
      }
      );
      
    } catch (error) {
      
      dispatch(seqrOpsActions.setLoader(false));
    }
  };
};
const getTransformerDataRecords = (
  customerDetailsReq: IGetDataRecordsReq
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await transformerService.getTransformerData(
        customerDetailsReq
      );

      if (response?.status === 200) {
        if (response?.data?.data) {
          dispatch(
            setTransformerEnergyConsumerDataRecords(response?.data?.data)
          );
        }
      }
    } catch (error) {}
  };
};

const getTransformerBateryUnitDataRecords = (
  customerDetailsReq: IGetDataRecordsReq
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response =
        await transformerService.getTransformerBateryUnitDataRecords(
          customerDetailsReq
        );

      if (response?.status === 200) {
        if (response?.data?.data) {
          dispatch(setTransformerBatteryUnitDataRecords(response?.data?.data));
        }
      }
      
    } catch (error) {}
  };
};

const getTransformerPvUnitDataRecords = (
  customerDetailsReq: IGetDataRecordsReq
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await transformerService.getTransformerPvUnitDataRecords(
        customerDetailsReq
      );

      if (response?.status === 200) {
        if (response?.data?.data) {
          dispatch(setTransformerPvUnitDataRecords(response?.data?.data));
        }
      }
    } catch (error) {}
  };
};

const getTransformerBusesDataRecords = (
  customerDetailsReq: IGetDataRecordsReq
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await transformerService.getTransformerBusesDataRecords(
        customerDetailsReq
      );

      if (response?.status === 200) {
        if (response?.data?.data) {
          dispatch(setTransformerBusesDataRecords(response?.data?.data));
        }
      }
    } catch (error) {}
  };
};

const getTransformerLinesDataRecords = (
  customerDetailsReq: IGetDataRecordsReq
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await transformerService.getTransformerLinesDataRecords(
        customerDetailsReq
      );

      if (response?.status === 200) {
        if (response?.data?.data) {
          dispatch(setTransformerLinesDataRecords(response?.data?.data));
        }
      }
    } catch (error) {}
  };
};

export const transformerActions = {
  getTransformerDataRecords,
  getTransformerBateryUnitDataRecords,
  getTransformerPvUnitDataRecords,
  getTransformerBusesDataRecords,
  getTransformerLinesDataRecords,
  getAllTransformerDataRecords
};
