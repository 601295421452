import React, { Children, useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import {
  LayerControlItem,
  LayerControlType,
  LayerTypes,
  layerControlInitialState,
  useGIS,
} from "../hooks/useGIS";
import { debounce } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { queryBuilderActions } from "../../../store/query-builder/query-builder.actions";
import { useLocation } from "react-router-dom";
import { mapInfoActions } from "../../../store/map-info/map-info.action";

const Label = ({
  value,
  title,
  color,
}: {
  value: string | null;
  title: string;
  color: string;
}) => (
  <span style={{ fontSize: 12 }} className={value || ""}>
    {color && (
      <span
        style={{
          backgroundColor: color,
          width: "13px",
          height: "13px",
          display: "inline-flex",
          marginRight: "5px",
        }}
      ></span>
    )}
    {title}
  </span>
);

const GISLayerFilterComponent = ({ showFilter }: { showFilter: boolean }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const filterSelection = useAppSelector(
    (state) => state.queryBuilderSlice.filterSelection
  );
  const [expanded, setExpanded] = useState<any>([
    LayerControlType.NETWORK,
    LayerControlType.NETWORK_LINES,
    LayerControlType.HEATMAP_MAIN,
    LayerControlType.TRENDS_MAIN,
  ]);

  const {
    bounds,
    getGisData,
    layerControl,
    getLineVoltageData,
    getLineLoadingData,
    setLayerControl,
    setMarkerSelected,
    areaReferences,
    resetNetworkScope,
    networkScope,
  } = useGIS();

  function findCheckedChanges(
    _prevChecked: string[],
    _currentChecked: string[]
  ): { newChecked: string[]; removed: string[] } {
    return {
      newChecked: _currentChecked.filter(
        (item) => !_prevChecked.includes(item)
      ),
      removed: _prevChecked.filter((item) => !_currentChecked.includes(item)),
    };
  }

  const triggerFilter = (
    _prevChecked: string[],
    _currentChecked: string[],
    pageChanged: boolean
  ) => {
    const mapping: { [key: string]: string } = {
      [LayerControlType.NETWORK_SUBSTATIONS]: LayerTypes.SUBSTATIONS,
      [LayerControlType.NETWORK_TRANSFORMERS]: LayerTypes.TRANSFORMERS,
      [LayerControlType.NETWORK_CONSUMERS]: LayerTypes.CONSUMERS,
      [LayerControlType.NETWORK_BATTERY_UNIT]: LayerTypes.BATTERY_UNIT,
      [LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT]:
        LayerTypes.PHOTO_VOLTAIC_UNIT,
      [LayerControlType.NETWORK_LINE_ABC_HV]: LayerTypes.LINE_ABC_HV,
      [LayerControlType.NETWORK_LINE_ABC_MV]: LayerTypes.LINE_ABC_MV,
      [LayerControlType.NETWORK_LINE_ABC_LV]: LayerTypes.LINE_ABC_LV,
      [LayerControlType.NETWORK_LINE_A_MV]: LayerTypes.LINE_A_MV,
      [LayerControlType.NETWORK_LINE_A_LV]: LayerTypes.LINE_A_LV,
      [LayerControlType.NETWORK_LINE_B_MV]: LayerTypes.LINE_B_MV,
      [LayerControlType.NETWORK_LINE_B_LV]: LayerTypes.LINE_B_LV,
      [LayerControlType.NETWORK_LINE_C_MV]: LayerTypes.LINE_C_MV,
      [LayerControlType.NETWORK_LINE_C_LV]: LayerTypes.LINE_C_LV,
    };
    if (layerControl[LayerControlType.__SCOPE__].color) {
      try {
        const items = JSON.parse(
          layerControl[LayerControlType.__SCOPE__].color
        );

        const itemsToAdd: string[] = items.itemsToAdd || [];
        const itemsToRemove: string[] = items.itemsToReset || [];

        if (networkScope.includes("NETWORK_SUBSTATIONS")) {
          if (layerControl[LayerControlType.NETWORK_LINE_ABC_HV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_ABC_HV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_ABC_MV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_ABC_MV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_ABC_LV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_ABC_LV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_A_MV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_A_MV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_A_LV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_A_LV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_B_MV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_B_MV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_B_LV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_B_LV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_C_MV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_C_MV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_C_LV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_C_LV);
          }
        }
        if (networkScope.includes("NETWORK_TRANSFORMERS")) {
          if (layerControl[LayerControlType.NETWORK_LINE_ABC_HV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_ABC_HV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_ABC_MV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_ABC_MV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_ABC_LV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_ABC_LV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_A_MV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_A_MV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_A_LV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_A_LV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_B_MV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_B_MV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_B_LV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_B_LV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_C_MV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_C_MV);
          }
          if (layerControl[LayerControlType.NETWORK_LINE_C_LV].checked) {
            itemsToAdd.push(LayerControlType.NETWORK_LINE_C_LV);
          }
        }

        for (const item of Array.from(new Set(itemsToRemove))) {
          if (mapping[item]) {
            getGisData("", "", undefined, true, mapping[item]);
          }
        }
        for (const item of Array.from(new Set(itemsToAdd))) {
          if (mapping[item]) {
            getGisData("", "", undefined, false, mapping[item]);
          }
        }
      } catch (err) {}
    } else {
      let ___checked: any[] = [];
      let ___removed: any[] = [];
      const { newChecked, removed } = findCheckedChanges(
        _prevChecked,
        _currentChecked
      );

      if (pageChanged) {
        ___checked = [..._currentChecked];
        ___removed = [];
      } else {
        ___checked = [...newChecked];
        ___removed = [...removed];
      }

      for (const item of Array.from(new Set(___removed))) {
        if (mapping[item]) {
          getGisData("", "", undefined, true, mapping[item]);
        }
      }
      for (const item of Array.from(new Set(___checked))) {
        if (mapping[item]) {
          getGisData("", "", undefined, false, mapping[item]);
        }
      }
    }
  };
  const debounceMoveEnd = debounce(
    (_layerControl: Record<LayerControlType, LayerControlItem>) => {
      updateLayerState(_layerControl);
    },
    800
  );
  const debounceMoveHeatmap = debounce(
    (_layerControl: Record<LayerControlType, LayerControlItem>) => {
      if (_layerControl[LayerControlType.HEATMAP_LOADING].checked) {
        getLineLoadingData("", "", undefined, false);
      }
      if (_layerControl[LayerControlType.HEATMAP_VOLTAGE].checked) {
        getLineVoltageData("", "", undefined, false);
      }
    },
    800
  );

  useEffect(() => {
    debounceMoveEnd(layerControl);
  }, [layerControl]);

  useEffect(() => {
    debounceMoveHeatmap(layerControl);
  }, [bounds, layerControl]);

  useEffect(() => {
    triggerFilter(filterSelection.prevChecked, filterSelection.checked, true);
  }, [filterSelection]);

  useEffect(() => {
    if (location.pathname === "/") {
      triggerFilter(
        filterSelection.prevChecked,
        filterSelection.checked,
        false
      );
    }
  }, [location]);

  const updateLayerState = (
    _layerControl: Record<LayerControlType, LayerControlItem>
  ) => {
    // const checkedItems: any[] = [];
    // Object.entries(_layerControl).forEach(([type, controlItem]) => {
    //   if (controlItem.checked) {
    //     checkedItems.push(type);
    //   }
    // });
    // setChecked({
    //   prevChecked: checked.checked,
    //   checked: checkedItems,
    // });
  };

  if (!showFilter) return null;

  const customIcons: any = {
    parentClose: null,
    parentOpen: null,
    leaf: null,
    check: (
      <span className="rct-filter-component-icon rct-filter-component-icon-check" />
    ),
    uncheck: (
      <span className="rct-filter-component-icon rct-filter-component-icon-uncheck" />
    ),
    halfCheck: (
      <span className="rct-filter-component-icon rct-filter-component-icon-half-check" />
    ),
  };

  const nodes: any[] = [
    {
      value: LayerControlType.NETWORK,
      label: (
        <Label
          title={layerControlInitialState[LayerControlType.NETWORK].label}
          value={layerControlInitialState[LayerControlType.NETWORK].value}
          color={layerControlInitialState[LayerControlType.NETWORK].color}
        />
      ),
      children: Object.entries(layerControlInitialState)
        .filter(
          ([key, value]) =>
            key === LayerControlType.NETWORK_LINES ||
            key === LayerControlType.NETWORK_SUBSTATIONS ||
            key === LayerControlType.NETWORK_TRANSFORMERS ||
            key === LayerControlType.NETWORK_CONSUMERS ||
            key === LayerControlType.NETWORK_BATTERY_UNIT ||
            key === LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT
        )
        .map(([key, value]) => ({
          value: key,
          label: (
            <Label
              value={value.value}
              title={value.label}
              color={value.color}
            />
          ),
          children:
            key === LayerControlType.NETWORK_LINES
              ? Object.entries(layerControlInitialState)
                  .filter(([key2, value2]) =>
                    key2.startsWith(LayerControlType.NETWORK_LINES + "_")
                  )
                  .map(([key2, value2]) => ({
                    value: key2,
                    label: (
                      <Label
                        value={value2.value}
                        title={value2.label}
                        color={value2.color}
                      />
                    ),
                  }))
              : undefined,
        })),
    },
    {
      value: LayerControlType.HEATMAP_MAIN,
      label: (
        <Label
          value={null}
          title={layerControlInitialState[LayerControlType.HEATMAP_MAIN].label}
          color={""}
        />
      ),
      children: [
        {
          value: LayerControlType.HEATMAP_LOADING,
          label: (
            <Label
              value={
                layerControlInitialState[LayerControlType.HEATMAP_LOADING].value
              }
              title={
                layerControlInitialState[LayerControlType.HEATMAP_LOADING].label
              }
              color={
                layerControlInitialState[LayerControlType.HEATMAP_LOADING].color
              }
            />
          ),
        },
        {
          value: LayerControlType.HEATMAP_VOLTAGE,
          label: (
            <Label
              value={
                layerControlInitialState[LayerControlType.HEATMAP_VOLTAGE].value
              }
              title={
                layerControlInitialState[LayerControlType.HEATMAP_VOLTAGE].label
              }
              color={
                layerControlInitialState[LayerControlType.HEATMAP_VOLTAGE].color
              }
            />
          ),
        },
      ],
    },
    {
      value: LayerControlType.TRENDS_MAIN,
      label: (
        <Label
          value={null}
          title={layerControlInitialState[LayerControlType.TRENDS_MAIN].label}
          color={""}
        />
      ),
      children: [
        {
          value: LayerControlType.TRENDS_CIRCUIT_POWER,
          label: (
            <Label
              value={
                layerControlInitialState[LayerControlType.TRENDS_CIRCUIT_POWER]
                  .value
              }
              title={
                layerControlInitialState[LayerControlType.TRENDS_CIRCUIT_POWER]
                  .label
              }
              color={
                layerControlInitialState[LayerControlType.TRENDS_CIRCUIT_POWER]
                  .color
              }
            />
          ),
        },
      ],
    },
  ];

  return (
    <div className="layer-filter">
      <div className="gis-side-canvas">
        <div
          className={
            "tabbed-filter-container " +
            `${
              layerControl[LayerControlType.__SCOPE__].checked
                ? " __SCOPE__" + layerControl[LayerControlType.__SCOPE__].label
                : ""
            }`
          }
        >
          <span className="title-label">Layers</span>
          <CheckboxTree
            nodes={nodes}
            checked={filterSelection.checked}
            expanded={expanded}
            icons={customIcons}
            onCheck={(__checked) => {
              const itemsToRemove = [
                LayerControlType.NETWORK.toString(),
                LayerControlType.NETWORK_LINES.toString(),
                LayerControlType.HEATMAP_MAIN.toString(),
                LayerControlType.TRENDS_MAIN.toString(),
              ];
              const _checked = __checked.filter(
                (item) => !itemsToRemove.includes(item)
              );

              let _layerControl = { ...layerControl };
              const networkItems = [
                LayerControlType.NETWORK_SUBSTATIONS,
                LayerControlType.NETWORK_TRANSFORMERS,
                LayerControlType.NETWORK_CONSUMERS,
                LayerControlType.NETWORK_BATTERY_UNIT,
                LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT,
                LayerControlType.NETWORK_LINE_ABC_HV,
                LayerControlType.NETWORK_LINE_ABC_MV,
                LayerControlType.NETWORK_LINE_ABC_LV,
                LayerControlType.NETWORK_LINE_A_MV,
                LayerControlType.NETWORK_LINE_A_LV,
                LayerControlType.NETWORK_LINE_B_MV,
                LayerControlType.NETWORK_LINE_B_LV,
                LayerControlType.NETWORK_LINE_C_MV,
                LayerControlType.NETWORK_LINE_C_LV,
              ];

              const networkLineItems = [
                LayerControlType.NETWORK_LINE_ABC_HV,
                LayerControlType.NETWORK_LINE_ABC_MV,
                LayerControlType.NETWORK_LINE_ABC_LV,
                LayerControlType.NETWORK_LINE_A_MV,
                LayerControlType.NETWORK_LINE_A_LV,
                LayerControlType.NETWORK_LINE_B_MV,
                LayerControlType.NETWORK_LINE_B_LV,
                LayerControlType.NETWORK_LINE_C_MV,
                LayerControlType.NETWORK_LINE_C_LV,
              ];

              const heatmapItems = [
                LayerControlType.HEATMAP_LOADING,
                LayerControlType.HEATMAP_VOLTAGE,
              ];

              const trendItems = [LayerControlType.TRENDS_CIRCUIT_POWER];

              const fullNetworkEnabled = networkItems.every((item) =>
                _checked.includes(item)
              );

              const fullNetworkLineEnabled = networkLineItems.every((item) =>
                _checked.includes(item)
              );

              const anyNetworkEnabled = _checked.some((item) =>
                item.startsWith("NETWORK")
              );

              if (!anyNetworkEnabled) {
                mapInfoActions.deleteGisData();
              }

              const fullHeatmapEnabled = heatmapItems.every((item) =>
                _checked.includes(item)
              );

              const fullTrendsEnabled = trendItems.every((item) =>
                _checked.includes(item)
              );

              //Network - Start //
              _layerControl[LayerControlType.NETWORK].checked =
                fullNetworkEnabled;

              _layerControl[LayerControlType.NETWORK_SUBSTATIONS].checked =
                fullNetworkEnabled ||
                _checked.includes(LayerControlType.NETWORK_SUBSTATIONS);

              _layerControl[LayerControlType.NETWORK_TRANSFORMERS].checked =
                fullNetworkEnabled ||
                _checked.includes(LayerControlType.NETWORK_TRANSFORMERS);

              _layerControl[LayerControlType.NETWORK_CONSUMERS].checked =
                fullNetworkEnabled ||
                _checked.includes(LayerControlType.NETWORK_CONSUMERS);

              _layerControl[LayerControlType.NETWORK_BATTERY_UNIT].checked =
                fullNetworkEnabled ||
                _checked.includes(LayerControlType.NETWORK_BATTERY_UNIT);

              _layerControl[
                LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT
              ].checked =
                fullNetworkEnabled ||
                _checked.includes(LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT);

              _layerControl[LayerControlType.NETWORK_LINES].checked =
                fullNetworkEnabled || fullNetworkLineEnabled;

              _layerControl[LayerControlType.NETWORK_LINE_ABC_HV].checked =
                fullNetworkEnabled ||
                fullNetworkLineEnabled ||
                _checked.includes(LayerControlType.NETWORK_LINE_ABC_HV);

              _layerControl[LayerControlType.NETWORK_LINE_ABC_MV].checked =
                fullNetworkEnabled ||
                fullNetworkLineEnabled ||
                _checked.includes(LayerControlType.NETWORK_LINE_ABC_MV);

              _layerControl[LayerControlType.NETWORK_LINE_ABC_LV].checked =
                fullNetworkEnabled ||
                fullNetworkLineEnabled ||
                _checked.includes(LayerControlType.NETWORK_LINE_ABC_LV);

              _layerControl[LayerControlType.NETWORK_LINE_A_MV].checked =
                fullNetworkEnabled ||
                fullNetworkLineEnabled ||
                _checked.includes(LayerControlType.NETWORK_LINE_A_MV);

              _layerControl[LayerControlType.NETWORK_LINE_A_LV].checked =
                fullNetworkEnabled ||
                fullNetworkLineEnabled ||
                _checked.includes(LayerControlType.NETWORK_LINE_A_LV);

              _layerControl[LayerControlType.NETWORK_LINE_B_MV].checked =
                fullNetworkEnabled ||
                fullNetworkLineEnabled ||
                _checked.includes(LayerControlType.NETWORK_LINE_B_MV);

              _layerControl[LayerControlType.NETWORK_LINE_B_LV].checked =
                fullNetworkEnabled ||
                fullNetworkLineEnabled ||
                _checked.includes(LayerControlType.NETWORK_LINE_B_LV);

              _layerControl[LayerControlType.NETWORK_LINE_C_MV].checked =
                fullNetworkEnabled ||
                fullNetworkLineEnabled ||
                _checked.includes(LayerControlType.NETWORK_LINE_C_MV);

              _layerControl[LayerControlType.NETWORK_LINE_C_LV].checked =
                fullNetworkEnabled ||
                fullNetworkLineEnabled ||
                _checked.includes(LayerControlType.NETWORK_LINE_C_LV);
              //Network - End //

              _layerControl[LayerControlType.TRENDS_CIRCUIT_POWER].checked =
                fullTrendsEnabled ||
                _checked.includes(LayerControlType.TRENDS_CIRCUIT_POWER);

              _layerControl[LayerControlType.HEATMAP_LOADING].checked =
                fullHeatmapEnabled ||
                _checked.includes(LayerControlType.HEATMAP_LOADING);

              _layerControl[LayerControlType.HEATMAP_VOLTAGE].checked =
                fullHeatmapEnabled ||
                _checked.includes(LayerControlType.HEATMAP_VOLTAGE);

              let prevOtherItemsChanged = false;
              if (
                _checked.includes(LayerControlType.TRENDS_CIRCUIT_POWER) !==
                  filterSelection.checked.includes(
                    LayerControlType.TRENDS_CIRCUIT_POWER
                  ) ||
                _checked.includes(LayerControlType.HEATMAP_LOADING) !==
                  filterSelection.checked.includes(
                    LayerControlType.HEATMAP_LOADING
                  ) ||
                _checked.includes(LayerControlType.HEATMAP_VOLTAGE) !==
                  filterSelection.checked.includes(
                    LayerControlType.HEATMAP_VOLTAGE
                  )
              ) {
                prevOtherItemsChanged = true;
              }

              if (
                (fullNetworkEnabled || !anyNetworkEnabled) &&
                !prevOtherItemsChanged &&
                !_layerControl[LayerControlType.__SCOPE__].checked
              ) {
                _layerControl[LayerControlType.__SCOPE__].checked = false;
                _layerControl[LayerControlType.__SCOPE__].label = "";
                _layerControl[LayerControlType.__SCOPE__].color = "";
                setMarkerSelected({
                  key: "RESET",
                  latlng: null,
                  feature: null,
                  type: null,
                });
                for (const key in areaReferences) {
                  areaReferences[key]?.remove();
                }
                resetNetworkScope([]);
              } else if (!anyNetworkEnabled) {
                _layerControl[LayerControlType.__SCOPE__].checked = false;
                _layerControl[LayerControlType.__SCOPE__].label = "";
                _layerControl[LayerControlType.__SCOPE__].color = "";
                setMarkerSelected({
                  key: "RESET",
                  latlng: null,
                  feature: null,
                  type: null,
                });
                for (const key in areaReferences) {
                  areaReferences[key]?.remove();
                }
                resetNetworkScope([]);
              }

              setLayerControl(_layerControl);
              dispatch(
                queryBuilderActions.setMapFilterSelection({
                  prevChecked: filterSelection.checked,
                  checked: _checked,
                })
              );
            }}
            onExpand={(expanded) => setExpanded(expanded)}
          />
        </div>
      </div>
    </div>
  );
};

export default GISLayerFilterComponent;
