import React, { useEffect, useState } from "react";
import "./grafana.component-style.scss";
import { useAppSelector } from "../../store";
import {
  ASSET_URLS,
  COOKIE_DETAILS,
  SESSION_KEY,
} from "../../config/default.config";
import { cookieUtils } from "../../utils/cookie.utils";
import { encryptUtils } from "../../utils/encryption.utils";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { commonUtils } from "../../utils/common.utils";

const GrafanaComponent = ({ consumerName }: { consumerName: string }) => {
  const [token, setToken] = useState("");
  const [theme, setTheme] = useState<string>("light");
  const themeFromStore = useAppSelector((state) => state.seqrOps.theme);
  const [timeDatas, setTimeDatas] = useState<{
    last24hour: number;
    currentTime: number;
  }>();

  useEffect(() => {
    const endTime = new Date().getTime();
    const startTime = new Date(endTime - 24 * 60 * 60 * 1000).getTime();
    setTimeDatas({ last24hour: startTime, currentTime: endTime });
  }, []);

  useEffect(() => {
    if (
      sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY) !==
      null
    ) {
      let tokenData: any = sessionStorageUtils.getLocalStorage(
        SESSION_KEY.LOCAL_STORAGE_KEY
      );
      setToken(tokenData?.token?.auth_token);
    }

    if (themeFromStore) {
      setTheme("dark");
    } else if (!themeFromStore) {
      setTheme("light");
    }
    let cookieName = COOKIE_DETAILS.COOKIE_NAME;
    let encryptedCookieData = cookieUtils.getCookie(cookieName);
    let decryptedCookieData = encryptUtils.decryptURL(encryptedCookieData);
  }, [themeFromStore]);

  console.log(
    ASSET_URLS.CUSTOMER_LOADS_DASHBOARD +
      "&auth_token=" +
      token +
      "&theme=" +
      theme +
      "&var-consumers=" +
      consumerName +
      "&from=" +
      timeDatas?.last24hour +
      "&to=" +
      timeDatas?.currentTime
  );
  return (
    <div className="dtech-garafana-wrapper">
      <div className="container-fluid">
        <iframe
          className="seqrops-frame-conworx"
          src={
            ASSET_URLS.CUSTOMER_LOADS_DASHBOARD +
            "&auth_token=" +
            token +
            "&theme=" +
            theme +
            "&var-consumers=" +
            consumerName +
            "&from=" +
            timeDatas?.last24hour +
            "&to=" +
            timeDatas?.currentTime
          }
          title="dtech-loads-dashboard"
          width="100%"
          height="100vh"
        ></iframe>
      </div>
    </div>
  );
};

export default GrafanaComponent;
