import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAssetInfo, IAssetListPayload } from "./asset-list.interface";

const initialState:IAssetInfo={
    assetInfo: [],
    assetPayload:[]
}
const AssetInfoSlice = createSlice({
    name: "AssetInfoSlice",
    initialState: initialState,
    reducers: {
        setAssetInfo: (state, action: PayloadAction<[]>) => {
        state.assetInfo = action.payload;
        return state;
    },
    setAssetPayload: (state, action: PayloadAction<Array<IAssetListPayload>>) => {
        state.assetPayload = action.payload;
        return state;
    },
}
})

export const { setAssetInfo,setAssetPayload} = AssetInfoSlice.actions;
export default AssetInfoSlice.reducer;