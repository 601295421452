import { AppThunk } from "../index";
import { seqrOpsActions } from "../seqr-ops/seqr-ops.action";
import { IDataRecordReq } from "./customer.interface";
import { customerService } from "./customer.services";
import {
  setCustomerBusesDataRecords,
  setCustomerDataRecords,
  setCustomerLoadsDataRecords,
  setCustomerPvDataRecords,
  setCustomerPvSummaryDataRecords,
  setCustomerStorageSummaryDataRecords,
} from "./customer.slice";

const getCustomerLoadsDataRecords = (
  customerDetailsReq: IDataRecordReq
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await customerService.getCustomerLoadsDataRecords(
        customerDetailsReq
      );

      if (response?.data?.success) {
        if (response.status === 200) {
          dispatch(setCustomerLoadsDataRecords(response?.data?.data));
        }
      } else if (!response.data.success) {
      }
    } catch (error) {}
  };
};

const getAllCustomerLoadsDataRecords = (
  customerDetailsReq: IDataRecordReq
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(seqrOpsActions.setLoader(true));

    try {
      const promise1 =  customerService.getCustomerLoadsDataRecords(
        customerDetailsReq
      );
      const promise2 =  customerService.getCustomerBusesDataRecords(
        customerDetailsReq
      );
      const promise3 =  customerService.getCustomerPvDataRecords(
        customerDetailsReq
      );
      const promise4 = customerService.getCustomerPvSummaryDataRecords(
        customerDetailsReq
      );
      const promise5 = customerService.getCustomerStorageSummaryDataRecords(
          customerDetailsReq
        );

      Promise.all([promise1, promise2,promise3,promise4,promise5]).then((values)=> {
        if (values[0]?.data?.success) {
          if (values[0].status === 200) {
            dispatch(setCustomerLoadsDataRecords(values[0]?.data?.data));
          }
        }

        if (values[1]?.data?.success) {
          if (values[1]?.status === 200) {
            dispatch(setCustomerBusesDataRecords(values[1]?.data?.data));
          }
        }

        if (values[2]?.data?.success) {
          if (values[2]?.status === 200) {
            dispatch(setCustomerPvDataRecords(values[2]?.data?.data));
          }
        }
        
        if (values[3]?.data?.success) {
          if (values[3]?.status === 200) {
            dispatch(setCustomerPvSummaryDataRecords(values[3]?.data?.data));
          }
        }
        
        if (values[4]?.data?.success) {
          if (values[4].status === 200) {
            dispatch(setCustomerStorageSummaryDataRecords(values[4]?.data?.data));
          }
        }
            dispatch(seqrOpsActions.setLoader(false));
      }).catch((error)=> {  
        dispatch(seqrOpsActions.setLoader(false))
      }
      );

    } catch (error) {
      dispatch(seqrOpsActions.setLoader(false))
    }
  };
};

const getCustomerPvDataRecords = (
  customerDetailsReq: IDataRecordReq
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await customerService.getCustomerPvDataRecords(
        customerDetailsReq
      );

      if (response?.data?.success) {
        if (response.status === 200) {
          dispatch(setCustomerPvDataRecords(response?.data?.data));
        }
      } else if (!response.data.success) {
      }
    } catch (error) {}
  };
};

const getCustomerBusesDataRecords = (
  customerDetailsReq: IDataRecordReq
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await customerService.getCustomerBusesDataRecords(
        customerDetailsReq
      );

      if (response?.data?.success) {
        if (response.status === 200) {
          dispatch(setCustomerBusesDataRecords(response?.data?.data));
        }
      } else if (!response.data.success) {
      }
    } catch (error) {}
  };
};

const getCustomerStorageSummaryDataRecords = (
  customerDetailsReq: IDataRecordReq
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response =
        await customerService.getCustomerStorageSummaryDataRecords(
          customerDetailsReq
        );

      if (response?.data?.success) {
        if (response.status === 200) {
          dispatch(setCustomerStorageSummaryDataRecords(response?.data?.data));
        }
      } else if (!response.data.success) {
      }
    } catch (error) {}
  };
};

const getCustomerPvSummaryDataRecords = (
  customerDetailsReq: IDataRecordReq
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const response = await customerService.getCustomerPvSummaryDataRecords(
        customerDetailsReq
      );

      if (response?.data?.success) {
        if (response.status === 200) {
          dispatch(setCustomerPvSummaryDataRecords(response?.data?.data));
        }
      } else if (!response.data.success) {
      }
    } catch (error) {}
  };
};

export const customerActions = {
  getCustomerLoadsDataRecords,
  getCustomerPvDataRecords,
  getCustomerBusesDataRecords,
  getCustomerStorageSummaryDataRecords,
  getCustomerPvSummaryDataRecords,
  getAllCustomerLoadsDataRecords
};
