import GISComponent from "./components/GISComponent";
import { GISContextProvider } from "./context/GISContext";
import { GISInnerContextProvider } from "./hooks/useGIS";

const GISWrapper = () => {
  return (
    <GISContextProvider>
      <GISInnerContextProvider>
        <GISComponent />
      </GISInnerContextProvider>
    </GISContextProvider>
  );
};
export default GISWrapper;
