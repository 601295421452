import React, { useEffect, useRef } from "react";
import { IGISFeatureCollection, IGISLayerData } from "../hooks/useGIS";
import L from "leaflet";
var customGradient = {
  0.0: "purple",
  0.25: "blue",
  0.5: "transparent",
  0.6: "yellow",
  1.0: "red",
};

const HeatmapLayer = ({
  map,
  data,
  show,
}: {
  map: any;
  data: IGISFeatureCollection;
  show: boolean;
}) => {
  const heatLayerRef = useRef<any>(null);
  function calculateIntensity(value: number, maxValue: number) {
    const threshold = 1.0; // Threshold for intensity increase
    if (value > threshold) {
      return Math.min((value - threshold) / (maxValue - threshold), 1.0);
    } else {
      return 0; // Below threshold, no intensity
    }
  }
  function getValueFromIntensity(intensity: any) {
    let minIntensity = 0.0;
    let maxIntensity = 1.0;

    let minValue = 0;
    let maxValue = 1.5;
    return (
      minValue +
      ((intensity - minIntensity) * (maxValue - minValue)) /
        (maxIntensity - minIntensity)
    );
  }
  useEffect(() => {
    const dataPoints = data.features?.length || 0;

    if (!map || !data || dataPoints === 0) return;

    if (show) {
      if (!heatLayerRef.current) {
        // @ts-ignore
        heatLayerRef.current = L.heatLayer(data.features, {
          radius: 15,
          opacity: 1,
          scaleRadius: true,
          useLocalExtrema: true,
          gradientTextureSize: 128,
          minOpacity: 0.6,
          max: 1.0,
          gradient: {
            0.0: "transparent",
            0.2: "purple",
            0.4: "blue",
            0.6: "yellow",
            0.9: "orange",
            1.0: "red",
          },
        }).addTo(map);
        // var labelMarkers: any[] = [];
        // var points: any[] = [];

        // for (let i = 0; i < data.lines.length; i++) {
        //   const point: any = data.lines[i];
        //   var latitude = point[0];
        //   var longitude = point[1];
        //   var intensity = point[2];

        //   if (points.indexOf(intensity.toFixed(2)) == -1) {
        //     var labelIcon = L.divIcon({
        //       className: "heatmap-label",
        //       html: `<div class="label-text">${intensity}</div>`,
        //       iconSize: [40, 20],
        //       iconAnchor: [20, 10],
        //     });

        //     var labelMarker = L.marker([latitude, longitude], {
        //       icon: labelIcon,
        //     });

        //     labelMarkers.push(labelMarker);
        //     points.push(intensity.toFixed(2));
        //   }
        // }

        // labelMarkers.forEach((marker) => {
        //   marker.addTo(map);
        // });
      } else {
        heatLayerRef.current.setLatLngs(data.features);
      }
    } else {
      if (heatLayerRef.current) {
        map.removeLayer(heatLayerRef.current);
        heatLayerRef.current = null;
      }
    }
    return () => {
      if (heatLayerRef.current) {
        map.removeLayer(heatLayerRef.current);
        heatLayerRef.current = null;
      }
    };
  }, [map, data, show]);

  return null;
};

export default HeatmapLayer;
