import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./alarm.scss";
import { useAppSelector } from "../../store";
import { IAlarmData } from "../../store/alarm-info/alarm-info.interface";
import { commonUtils } from "../../utils/common.utils";

interface Row {
  alarm_title: string;
  dateAndTime: number;
  equipment: string;
  substation: string;
  vl: string;
  bay: string;
}

const Alarm = (props: any) => {
  const [alarmDatas, setAlarmDatas] = useState<IAlarmData[]>();
  const getAlarmInfo: any = useAppSelector(
    (state) => state.alarmInfo.alarmInfo
  );

  let rows: Array<Row> = [];
  rows = getAlarmInfo;

  useEffect(() => {
    if (props?.alarmData != null && props?.alarmData != undefined) {
      setAlarmDatas(props?.alarmData);
    }
  }, [props]);
  return (
    <>
      <div className="table-responsive">
        <TableContainer
          className="table-container"
          sx={{ maxHeight: "32vh", minHeight: "32vh" }}
          component={Paper}
        >
          <Table
            sx={{ color: "red" }}
            stickyHeader
            size="small"
            aria-label="a dense table"
          >
            <TableHead sx={{ borderBottom: "1px solid #d0d3df" }}>
              <TableRow>
                <TableCell className="table-header">Type</TableCell>
                <TableCell className="table-header">Bus</TableCell>
                <TableCell className="table-header">Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.alarmData &&
                props?.alarmData.map((row: IAlarmData) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      border: "1px solid #dddddd",
                    }}
                  >
                    <TableCell
                      className="table-row"
                      color="red"
                      component="th"
                      scope="row"
                    >
                      {row?.alarmtype}
                    </TableCell>
                    <TableCell className="table-row" color="red">
                      {row?.alarmtype === "LINEOVERLOAD"
                        ? row?.bus1 + " - " + row?.bus2
                        : row?.bus}
                    </TableCell>
                    <TableCell className="table-row" color="red">
                      {commonUtils.getUTCTime(row?.time)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
export default Alarm;
