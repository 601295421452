import axios from "axios";
import {
  DTECH_ASSEST_URL,
  GRID_MONITORING_API,
} from "../../config/default.config";
import { IDataRecordReq } from "../customer/customer.interface";

const getAllMapInfo = async () => {
  const response = await axios.get(DTECH_ASSEST_URL.GET_ALL_MAP_INFO, {
    responseType: "arraybuffer",
  });

  if (response) {
    return response;
  }
};

const getSingleMapInfo = async (params: any) => {
  const response = await axios.post(DTECH_ASSEST_URL.GET_MAP_INFO, params);
  return response;
};

const getCircuitData = async (circuitDataReq: IDataRecordReq) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_CIRCUIT_RECORDS,
    circuitDataReq
  );

  return response;
};

export const mapInfoService = {
  getAllMapInfo,
  getSingleMapInfo,
  getCircuitData,
};
