import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./alarm.list.component-style.scss";
import { useAppSelector } from "../../store";
import { IAlarmData } from "../../store/alarm-info/alarm-info.interface";
import { ICustomerPropertyBody } from "../../store/customer/customer.interface";
import { alarmInfoActions } from "../../store/alarm-info/alarm-info.actions";
import { IAlarmsPayload } from "./alarm.interface";
import { TablePagination } from "@mui/material";
import { commonUtils } from "../../utils/common.utils";
import moment from "moment";

interface Row {
  alarm_title: string;
  dateAndTime: number;
  equipment: string;
  substation: string;
  vl: string;
  bay: string;
}

const AlarmListComponentNew = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const initialPaginationData: IAlarmsPayload = {
    totalRecords: 0,
    totalFilteredRecords: 0,
    pageNo: 0,
    pageSize: 15,
    sortKey: "",
    type: "",
    name: "",
    rid: "",
    time: commonUtils.getCurrentUTCTime(),
    sortOrder: "",
    data: {},
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChangeRowsPerPage = (e: any) => {
    const params = {
      ...alarmsPayload,
      pageSize: e.target.value,
      pageNo: 0,
    };
    setAlarmsPayload(params);
    alarmInfoActions.getAlarms(params).then((response: any) => {
      setAlarms(response?.data?.alarms);
      setTotalRecords(response?.data?.totalRecords);
    });
  };

  const handlePageNoChange = (event: any, value: number) => {
    const params = {
      ...alarmsPayload,
      pageNo: value,
    };
    setAlarmsPayload(params);
    alarmInfoActions.getAlarms(params).then((response: any) => {
      setAlarms(response?.data?.alarms);
      setTotalRecords(response?.data?.totalRecords);
    });
  };

  const [alarms, setAlarms] = useState<IAlarmData[]>();
  const getAlarmInfo: any = useAppSelector(
    (state) => state.alarmInfo.alarmInfo
  );
  const [totalRecords, setTotalRecords] = useState<number>(5);
  const [alarmsPayload, setAlarmsPayload] = useState<IAlarmsPayload>(
    initialPaginationData
  );
  useEffect(() => {
    const payload = initialPaginationData;
    setAlarmsPayload({
      ...payload,
    });
    alarmInfoActions.getAlarms(payload).then((response: any) => {
      setAlarms(response?.data?.alarms);
      setTotalRecords(response?.data?.totalRecords);
    });
  }, []);

  const getCurrentUTCTime = (date: string) => {
    const now = new Date(date);
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so we add 1
    const day = String(now.getUTCDate()).padStart(2, "0");
    const hours = String(now.getUTCHours()).padStart(2, "0");
    const minutes = String(now.getUTCMinutes()).padStart(2, "0");
    const seconds = String(now.getUTCSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <TableContainer
          className="table-container"
          sx={{
            maxHeight: windowHeight - 290 + "px",
            minHeight: windowHeight - 280 + "px",
          }}
          component={Paper}
        >
          <Table
            sx={{ color: "red" }}
            stickyHeader
            size="small"
            aria-label="a dense table"
          >
            <TableHead sx={{ borderBottom: "1px solid #d0d3df" }}>
              <TableRow>
                <TableCell className="table-header">Title</TableCell>
                <TableCell className="table-header">Bus</TableCell>
                <TableCell className="table-header">Date and Time</TableCell>
                <TableCell className="table-header">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alarms
                ? alarms.map((alarm: any) => (
                    <TableRow
                      key={alarm.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        border: "1px solid #dddddd",
                      }}
                    >
                      <TableCell
                        className="table-row"
                        color="red"
                        component="th"
                        scope="row"
                      >
                        {alarm?.alarmtype ? alarm?.alarmtype : "No Data"}
                      </TableCell>
                      <TableCell className="table-row">
                        {alarm?.alarmtype === "LINEOVERLOAD"
                          ? alarm?.bus1 + " - " + alarm?.bus2
                          : alarm?.bus}
                      </TableCell>
                      <TableCell className="table-row">
                        {alarm?.time
                          ? getCurrentUTCTime(alarm?.time)
                          : "No Data"}
                      </TableCell>
                      <TableCell className="table-row">
                        {alarm?.status ? alarm?.status : "Active"}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
          {alarms && alarms.length == 0 ? (
            <div className="no-data-available-text">No data available</div>
          ) : null}
        </TableContainer>
        <TablePagination
          component="div"
          count={totalRecords > 0 ? totalRecords : 0}
          rowsPerPageOptions={totalRecords > 0 ? [5, 10, 15, 20, 25] : []}
          page={totalRecords > 0 ? alarmsPayload.pageNo : 0}
          onPageChange={handlePageNoChange}
          rowsPerPage={totalRecords > 0 ? alarmsPayload.pageSize : 0}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};
export default AlarmListComponentNew;
