import React, { useEffect, useState } from "react";
import ChartComponent from "../../../components/chart.component/chart";
import ChartMultiAxisComponent from "../../../components/chart.component/chart-multi-yaxis";
import analyticsIcon from "../../../assets/images/analytical-dashboard.svg";
import GrafanaComponent from "../../../components/grafana.component/garafana.component";
import { commonUtils } from "../../../utils/common.utils";
import { IDataRecordReq } from "../../../store/customer/customer.interface";
import { sidebarActions } from "../../../store/sidebar/sidebar.action";
import { customerService } from "../../../store/customer/customer.services";
import { ISubsstationDatas } from "../../../store/sidebar/sidebar.interface";
import CustomerDataTableWithStickyHeader from "../../../components/customer.component/customer-data-table-sticky";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { TabList, TabPanel } from "@mui/lab";
import { useGIS } from "../hooks/useGIS";
import { useAppDispatch } from "../../../store";
import { queryBuilderActions } from "../../../store/query-builder/query-builder.actions";
const ConsumerPopupComponent = ({
  consumerRid,
  consumerType,
  consumerName,
}: {
  consumerRid: string;
  consumerType: string;
  consumerName: string;
}) => {
  const dispatch = useAppDispatch();
  const { batteryUnitLayerData, pvUnitLayerData } = useGIS();
  const [value, setValue] = React.useState("1");
  const [consumer, setConsumer] = useState<ISubsstationDatas>({
    name: "-",
    instance: {
      line: [],
      connectivityNodes: [],
      name: "",
      transformer: "",
      subStation: "",
      rid: "",
      voltageLevels: [],
    },
    mRID: "-",
    schema: [],
    rid: "-",
    type: "EnergyConsumer",
  });

  const [dataset, setDatasets] = useState<any>({
    kwDataset: [],
    kvarDataset: [],
  });
  const [pvDataset, setPvDatasets] = useState<any>({
    irradianceDataset: [],
    temperatureDataset: [],
  });
  const [connectivityDataset, setConnectivityDataset] = useState<any>({
    currentDataset: [],
    voltageDataset: [],
  });
  const [pvSummaryDataset, setPvSummaryDataset] = useState<any>({
    dataset: [],
  });
  const [storageSummaryDataset, setStorageSummaryDataset] = useState<any>({
    dataset: [],
  });

  const labels: any = commonUtils.generateXAxisLabels();

  const getDataRecords = async (rid: string, type: string, name: string) => {
    const reqDto: IDataRecordReq = {
      rid: rid,
      type: type,
      name: name,
      time: commonUtils.getCurrentUTCTime(),
    };

    const response = sidebarActions.getAllDataByListItem(reqDto);
    response.then((data: any) => {
      if (data?.EnergyConsumer?.instance) {
        setConsumer(data.EnergyConsumer);
      }
    });

    try {
      const promise1 = customerService.getCustomerLoadsDataRecords(reqDto);
      const promise2 = customerService.getCustomerBusesDataRecords(reqDto);
      const promise3 = customerService.getCustomerPvDataRecords(reqDto);
      const promise4 = customerService.getCustomerPvSummaryDataRecords(reqDto);
      const promise5 =
        customerService.getCustomerStorageSummaryDataRecords(reqDto);

      Promise.all([promise1, promise2, promise3, promise4, promise5])
        .then((values) => {
          if (values[0]?.data?.success) {
            if (values[0].status === 200) {
              const _dataset = commonUtils.getCustomerChartProperties(
                values[0]?.data?.data?.consumer || [],
                [
                  "total_site_electricity",
                  "heating",
                  "pumps",
                  "cooling",
                  "clothes_dryer",
                  "motors",
                  "dishwasher",
                  "refrigeration",
                ]
              );
              setDatasets({
                kvarDataset: _dataset.kvarDataset,
                kwDataset: _dataset.kwDataset,
              });
            }
          }

          if (values[1]?.data?.success) {
            if (values[1]?.status === 200) {
              const _dataset = commonUtils.getConnectivityChartProperties(
                values[1]?.data?.data?.connectivityData || []
              );
              setConnectivityDataset({
                currentDataset: _dataset.currentDataset,
                voltageDataset: _dataset.voltageDataset,
              });
            }
          }

          if (values[2]?.data?.success) {
            if (values[2]?.status === 200) {
              const _dataset = commonUtils.getPvChartProperties(
                values[2]?.data?.data?.pVData || []
              );
              setPvDatasets({
                irradianceDataset: _dataset.irradianceDataset,
                temperatureDataset: _dataset.temperatureDataset,
              });
            }
          }

          if (values[3]?.data?.success) {
            if (values[3]?.status === 200) {
              const pvSummary = values[3]?.data?.data?.pvSummary || [];

              if (pvSummary?.length > 0) {
                const _dataset = commonUtils.getConsumerStorageChartProperties(
                  pvSummary?.[0]?.pvUnits || []
                );
                setPvSummaryDataset(_dataset);
              }
            }
          }

          if (values[4]?.data?.success) {
            if (values[4].status === 200) {
              const storageSummary =
                values[4]?.data?.data?.storageSummary || [];

              if (storageSummary?.length > 0) {
                const _dataset = commonUtils.getConsumerStorageChartProperties(
                  storageSummary?.[0]?.batteries || []
                );
                setStorageSummaryDataset(_dataset);
              }
            }
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  useEffect(() => {
    let _consumerId = "";
    if (consumerType === "BatteryUnit") {
      const batteryUnit = batteryUnitLayerData.BATTERY_UNIT.features.find(
        (x) => x.properties?.gName === consumerName
      );
      if (batteryUnit != null) {
        _consumerId = batteryUnit?.properties?.gConsumerRid || "";
      }
    } else if (consumerType === "PhotoVoltaicUnit") {
      const pvUnit = pvUnitLayerData.PHOTO_VOLTAIC_UNIT.features.find(
        (x) => x.properties?.gName === consumerName
      );
      if (pvUnit != null) {
        _consumerId = pvUnit?.properties?.gConsumerRid || "";
      }
    } else {
      _consumerId = consumerRid;
    }

    if (_consumerId) {
      getDataRecords(_consumerId, "EnergyConsumer", consumerName);
    }
  }, [consumerRid]);

  const onDetailsClick = () => {
    let _name = consumer?.name || "";
    let _type = "EnergyConsumer";
    let _rid = consumer?.rid || "";
    dispatch(
      queryBuilderActions.addTab({
        value: _type + "_" + _rid,
        label: _name,
        rid: _rid,
        type: _type,
      })
    );
  };

  useEffect(() => {
    if (
      consumerType === "BatteryUnit" &&
      storageSummaryDataset?.dataset?.length > 0
    ) {
      setValue("3");
    } else if (
      consumerType === "PhotoVoltaicUnit" &&
      pvSummaryDataset?.dataset?.length > 0
    ) {
      setValue("4");
    } else {
      setValue("1");
    }
  }, [consumerType, storageSummaryDataset, pvSummaryDataset]);

  const renderInfo = () => {
    return (
      <div className="v-card-text">
        <div
          className="v-list card-list"
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <div className="v-list-item w-50">
            <div className="v-list-item__content mb-1">
              <div className="v-list-item-subtitle">Consumer</div>
              <div className="v-list-item-title">{consumer?.name || "-"}</div>
            </div>
          </div>

          <div className="v-list-item w-50">
            <div className="v-list-item__content mb-1">
              <div className="v-list-item-subtitle">Bay</div>
              <div className="v-list-item-title">
                {(
                  consumer?.instance?.transformer
                    ?.replaceAll("tr(", "")
                    .replaceAll(")", "") || "-"
                )?.replace("Bay_", "")}
              </div>
            </div>
          </div>
        </div>
        <div
          className="v-list card-list"
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <div className="v-list-item w-50">
            <div className="v-list-item__content mb-1">
              <div className="v-list-item-subtitle">Substation</div>
              <div className="v-list-item-title">
                {consumer?.instance?.subStation || "-"}
              </div>
            </div>
          </div>

          <div className="v-list-item w-50">
            <div className="v-list-item__content mb-1">
              <div className="v-list-item-subtitle">Voltage Levels</div>
              <div className="v-list-item-title">
                {consumer?.instance?.voltageLevels
                  ?.map((item: any) => item + " Kv")
                  .join(", ") || "-"}
              </div>
            </div>
          </div>
        </div>
        <div className="v-list card-list">
          <div className="v-list-item">
            <div className="v-list-item__content mb-1">
              {consumer.name !== "-" ? (
                <i
                  className="fa fa-info-circle v-list-item-details-icon"
                  onClick={() => {
                    onDetailsClick();
                  }}
                  title="View Details"
                ></i>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="dtech-customer-section-main-wrapper static-section">
        <div>
          {renderInfo()}
          <TabContext value={value}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="gis-component-popup-tab"
            >
              <TabList
                onChange={(event: React.SyntheticEvent, newValue: string) => {
                  setValue(newValue);
                }}
                variant="scrollable"
                scrollButtons={"auto"}
                allowScrollButtonsMobile
              >
                <Tab
                  label="Day Load Curve (kW)"
                  value="1"
                  sx={{
                    textTransform: "none !important",
                  }}
                />
                <Tab
                  label="Day Load Curve (kVar)"
                  value="2"
                  sx={{
                    textTransform: "none !important",
                  }}
                />
                {storageSummaryDataset?.dataset?.length > 0 ? (
                  <Tab
                    label="Battery Unit"
                    value="3"
                    sx={{
                      textTransform: "none !important",
                    }}
                  />
                ) : null}
                {pvSummaryDataset?.dataset?.length > 0 ? (
                  <Tab
                    label="Photo Voltaic Unit"
                    value="4"
                    sx={{
                      textTransform: "none !important",
                      width: "170px",
                    }}
                  />
                ) : null}
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className="row gis-component-popup-chart dtech-substation-footer-chart-data-section">
                <div className="middle-chart-div">
                  <ChartComponent
                    labels={labels}
                    datasets={dataset?.kwDataset}
                    title={"Day Load Curve (kW)"}
                    yAxisTitle={"kW Total"}
                    xAxisTitle={"Hour of the Day"}
                    height={"36vh"}
                    labelPosition={"bottom"}
                    labelAlign={"start"}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value="2">
              <div className="row gis-component-popup-chart dtech-substation-footer-chart-data-section">
                <div className="middle-chart-div">
                  <ChartComponent
                    labels={labels}
                    datasets={dataset?.kvarDataset}
                    title={"Day Load Curve (kVar)"}
                    yAxisTitle={"kVar Total"}
                    xAxisTitle={"Hour of the Day"}
                    height={"36vh"}
                    labelPosition={"bottom"}
                    labelAlign={"start"}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value="3">
              <div className="row gis-component-popup-chart dtech-substation-footer-chart-data-section">
                <div className="middle-chart-div">
                  <ChartMultiAxisComponent
                    labels={labels}
                    datasets={storageSummaryDataset?.dataset}
                    title={"Battery Unit"}
                    yAxisTitle={"Total"}
                    xAxisTitle={"Hour of the Day"}
                    height={"36vh"}
                    labelPosition={"bottom"}
                    labelAlign={"start"}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value="4">
              <div className="row gis-component-popup-chart dtech-substation-footer-chart-data-section">
                <div className="middle-chart-div">
                  <ChartMultiAxisComponent
                    labels={labels}
                    datasets={pvSummaryDataset?.dataset}
                    title={"Photo Voltaic Unit"}
                    yAxisTitle={"Total"}
                    xAxisTitle={"Hour of the Day"}
                    height={"36vh"}
                    labelPosition={"bottom"}
                    labelAlign={"start"}
                  />
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </>
  );
};
export default ConsumerPopupComponent;
