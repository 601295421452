import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICircuitChartData, IMapInfoPayload, IMapInfoState } from "./map-info.interface";

const initialState: IMapInfoState = {
  geoInformationState: {
    geoData: {},
    singleMapData: {
      lat: -79.61098865827701,
      long: 36.219576673256626,
      zoom: 14,
    },
    linesMapData: [],
    trasformersMapData: [],
    energyConsumers: [],
    baysMapData: [],
    substationsMapData: [],
    switchingDevices: [],
  },
 

  allMapData: {},
  chartData: [],
  checkBoxData:[],
  listData:{
    rid: "",
    type: "",
    name: ""
  },
  checkedValue:false,
  checkedItem:"",
  alarmData:{}

};
const mapInfoSlice = createSlice({
  name: "mapInfoSlice",
  initialState: initialState,
  reducers: {
    setMapData: (state, action: PayloadAction<any>) => {
      const payload = action.payload;
      state.allMapData = { ...payload };
      return state;
    },
    setLinesMapData(state, actions: PayloadAction<[]>) {
      const payload = actions.payload;
      state.geoInformationState.linesMapData = [...payload];
      return state;
    },
    setTransformersMapData(state, actions: PayloadAction<[]>) {
      const payload = actions.payload;
      state.geoInformationState.trasformersMapData = [...payload];
      return state;
    },
    setenergyConsumersMapData(state, actions: PayloadAction<[]>) {
      const payload = actions.payload;
      state.geoInformationState.energyConsumers = [...payload];
      return state;
    },
    setBayMapData(state, actions: PayloadAction<[]>) {
      const payload = actions.payload;
      state.geoInformationState.baysMapData = [...payload];
      return state;
    },
    setsubstationsMapData(state, actions: PayloadAction<[]>) {
      const payload = actions.payload;
      state.geoInformationState.substationsMapData = [...payload];
      return state;
    },
    setswitchingDevices(state, actions: PayloadAction<[]>) {
      const payload = actions.payload;
      state.geoInformationState.switchingDevices = [...payload];
      return state;
    },
    setSingleMapData: (state, action: PayloadAction<[]>) => {
      state.geoInformationState = {
        ...state.geoInformationState,
        singleMapData: action.payload,
      };
    },
    setChartData: (state, action: PayloadAction<ICircuitChartData[]>) => {
      state.chartData = action?.payload;
    },
    setSidebarData:(state, action: PayloadAction<IMapInfoPayload>) => {
      state.listData=action?.payload;
    },
    setCheckBoxItems:(state,action:PayloadAction<any>)=>{
      state.checkBoxData=action.payload
    },
    setCheckedValues:(state,action:PayloadAction<any>)=>{
state.checkedValue=action.payload
    },
    setCheckedItem:(state,action:PayloadAction<any>)=>{
      state.checkedItem=action.payload
          },
          setAlarmData:(state,action:PayloadAction<any>)=>{
            state.alarmData=action.payload
                }
  },
});

export const {
  setMapData,
  setSingleMapData,
  setLinesMapData,
  setTransformersMapData,
  setenergyConsumersMapData,
  setBayMapData,
  setsubstationsMapData,
  setswitchingDevices,
  setChartData,
  setSidebarData,
  setCheckBoxItems,
  setCheckedValues,
  setCheckedItem,
  setAlarmData
} = mapInfoSlice.actions;
export default mapInfoSlice.reducer;
