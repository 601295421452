import BrtWasm from "../wasm/main";

interface MyModule {
  Datasets: any;
  GisDataProvider: any;
  Substation: any;
}

type getGisDataPostReq = (
  url: string,
  headers: any,
  requestBody: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => void;

type httpPostReq = (
  url: string,
  headers: any,
  requestBody: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => void;

interface IGisDataProvider {
  getGisData: getGisDataPostReq;
  getLineVoltageGisData: httpPostReq;
  getLineLoadingGisData: httpPostReq;
  deleteGisData: any;
}

let brtWasmModuleInstance: any;
let datasetInstance: any;
let gisDataProviderInstance: IGisDataProvider;
let substationInstance: any;

const initializeBrtWasm = async (): Promise<MyModule> => {
  try {
    if (!brtWasmModuleInstance) {
      brtWasmModuleInstance = await BrtWasm();
      console.log("Module created!");
    }
    return brtWasmModuleInstance;
  } catch (error) {
    console.error("Error initializing BrtWasm module:", error);
    throw error;
  }
};

const getDatasetInstance = async (): Promise<any> => {
  if (!datasetInstance) {
    const brtWasmModule = await initializeBrtWasm();
    datasetInstance = new brtWasmModule.Datasets();
  }
  return datasetInstance;
};

const getGisDataProviderInstance = async (): Promise<IGisDataProvider> => {
  if (!gisDataProviderInstance) {
    const brtWasmModule = await initializeBrtWasm();
    gisDataProviderInstance = new brtWasmModule.GisDataProvider();
  }
  return gisDataProviderInstance;
};

const getSubstationInstance = async (): Promise<any> => {
  if (!substationInstance) {
    const brtWasmModule = await initializeBrtWasm();
    substationInstance = new brtWasmModule.Substation();
  }
  return substationInstance;
};

export const brtWasmModule = {
  datasets: getDatasetInstance,
  gisDataProvider: getGisDataProviderInstance,
  substation: getSubstationInstance,
};
