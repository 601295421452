import {
  IAlarmsPayload,
  IGetAllAlarmsPayload,
} from "../../components/alarm.list.component/alarm.interface";
import { alarmInfoService } from "./alarm-info.services";

const setAlarmInfo = async () => {
  try {
    const response = await alarmInfoService.getAlarmInfo();
    if (response) {
      return response;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};
const setCustomerAlarmInfo = async () => {
  try {
    const response = await alarmInfoService.getCustomerAlarmInfo();
    if (response) {
      return response;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};
const setTransformerAlarmInfo = async () => {
  try {
    const response = await alarmInfoService.getTransformerAlarmInfo();
    if (response) {
      return response;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};
const setSubstationAlarmInfo = async () => {
  try {
    const response = await alarmInfoService.getSubstationAlarmInfo();
    if (response) {
      return response;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

const getAllAlarms = async (alarmsPayload: IGetAllAlarmsPayload) => {
  try {
    const response = await alarmInfoService.getAllAlarms(alarmsPayload);
    if (response) {
      return response;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

const getAlarms = async (alarmsPayload: IAlarmsPayload) => {
  try {
    const response = await alarmInfoService.getAlarms(alarmsPayload);
    if (response) {
      return response;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};
export const alarmInfoActions = {
  setAlarmInfo,
  setCustomerAlarmInfo,
  setTransformerAlarmInfo,
  setSubstationAlarmInfo,
  getAllAlarms,
  getAlarms,
};
