import React, { createContext, useState } from "react";
import { LatLngExpression, LatLngBoundsExpression } from "leaflet";

interface GISContextType {
  point: LatLngExpression;
  bounds: LatLngBoundsExpression;
  setPoint: React.Dispatch<React.SetStateAction<LatLngExpression>>;
  setBounds: React.Dispatch<React.SetStateAction<LatLngBoundsExpression>>;
}

const GISContext = createContext<GISContextType>({
  point: [0, 0],
  bounds: [
    [0, 0],
    [0, 0],
  ],
  setPoint: () => {},
  setBounds: () => {},
});

const GISContextProvider = ({ children }: any) => {
  const [point, setPoint] = useState<LatLngExpression>([0, 0]);
  const [bounds, setBounds] = useState<LatLngBoundsExpression>([
    [0, 0],
    [0, 0],
  ]);

  const value: GISContextType = {
    point,
    bounds,
    setPoint,
    setBounds,
  };

  return <GISContext.Provider value={value}>{children}</GISContext.Provider>;
};

export { GISContext, GISContextProvider };
