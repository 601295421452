import { AppThunk } from "..";
import { IGetDataReq } from "./sidebar.interface";
import { sidebarService } from "./sidebar.service";
import { setAllDataByListItem } from "./sidebar.slice";

const getAllDataByListItem = async (getAllDataReq: IGetDataReq) => {
  const params: IGetDataReq = getAllDataReq;
  try {
    const response = await sidebarService.getAllDatasByListItem(params);
    if (response?.data?.success) {
      if (response.status === 200) {
        return response?.data?.data;
      }
    } else if (!response.data.success) {
    }
  } catch (err) {
    return null;
  }
};
export const sidebarActions = {
  getAllDataByListItem,
};
