import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAssestInfoState } from "./assest.info.interface";

const initialState:IAssestInfoState={
    geoInformationState: {
        geoData: [],
      },
}
const assestInfoSlice = createSlice({
    name: "assestInfoSlice",
    initialState: initialState,
    reducers: {
    setMapData: (state, action: PayloadAction<[]>) => {
    state.geoInformationState = {
    ...state.geoInformationState,
    geoData: action.payload,
    };
    },
}
})

export const { } = assestInfoSlice.actions;
export default assestInfoSlice.reducer;