import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAssetListPayload } from "../asset-list/asset-list.interface";
import { IAlarmData, IAlarmInfo } from "./alarm-info.interface";

const initialState: IAlarmInfo = {
  alarmInfo: [],
  customerAlarmInfo: [],
  substationAlarmInfo: [],
  transformerAlarmInfo: [],
};
const AssetInfoSlice = createSlice({
  name: "AlarmInfoSlice",
  initialState: initialState,
  reducers: {
    setAlarmInfo: (state, action: PayloadAction<IAlarmData[]>) => {
      state.alarmInfo = action.payload;
      return state;
    },
    setCustomerAlarmInfo: (state, action: PayloadAction<IAlarmData[]>) => {
      state.customerAlarmInfo = action.payload;
      return state;
    },
    setTransformerAlarmInfo: (state, action: PayloadAction<IAlarmData[]>) => {
      state.transformerAlarmInfo = action.payload;
      return state;
    },
    setSubstationAlarmInfo: (state, action: PayloadAction<IAlarmData[]>) => {
      state.transformerAlarmInfo = action.payload;
      return state;
    },
  },
});

export const {
  setAlarmInfo,
  setCustomerAlarmInfo,
  setTransformerAlarmInfo,
  setSubstationAlarmInfo,
} = AssetInfoSlice.actions;
export default AssetInfoSlice.reducer;
