import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import ChartComponent from "../../../components/chart.component/chart";
import CustomerDataTableWithStickyHeader from "../../../components/customer.component/customer-data-table-sticky";
import { IGetDataRecordsReq } from "../../../store/transformer/transformer.interface";
import { transformerActions } from "../../../store/transformer/transformer.action";
import { commonUtils } from "../../../utils/common.utils";
import { seqrOpsActions } from "../../../store/seqr-ops/seqr-ops.action";
import { transformerService } from "../../../store/transformer/transformer.services";
import { IDataRecordReq } from "../../../store/customer/customer.interface";
import { sidebarActions } from "../../../store/sidebar/sidebar.action";
import { ISubsstationDatas } from "../../../store/sidebar/sidebar.interface";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { TabList, TabPanel } from "@mui/lab";
import { queryBuilderActions } from "../../../store/query-builder/query-builder.actions";
const TransformerPopupComponent = ({
  transformerRid,
  transformerType,
  transformerName,
}: {
  transformerRid: string;
  transformerType: string;
  transformerName: string;
}) => {
  const [value, setValue] = useState("3");
  const dispatch = useAppDispatch();
  const [transformer, setTransformer] = useState<ISubsstationDatas>({
    name: "-",
    instance: {
      customers: 0,
      voltageLevels: [],
    },
    mRID: "-",
    schema: [],
    rid: "-",
    type: "Substation",
  });

  const [connectivityDataset, setConnectivityDataset] = useState<any>({
    currentDataset: [],
    voltageDataset: [],
  });
  const [lineDataset, setLineDataset] = useState<any>({
    currentDataset: [],
  });
  const [consumerSummaryDataset, setConsumerSummaryDataset] = useState<any>({
    columns: ["Consumer", "kW", "kVar"],
    data: [],
  });
  const [pvSummaryDataset, setPvSummaryDataset] = useState<any>({
    columns: ["PV System", "kW", "kVar"],
    data: [],
  });
  const [storageSummaryDataset, setStorageSummaryDataset] = useState<any>({
    columns: ["Battery", "kW", "kVar", "State"],
    data: [],
  });
  const labels: any = commonUtils.generateXAxisLabels();

  const getDataRecords = async (rid: string, type: string, name: string) => {
    const reqDto: IDataRecordReq = {
      rid: rid,
      type: type,
      name: name,
      time: commonUtils.getCurrentUTCTime(),
    };

    const response = sidebarActions.getAllDataByListItem(reqDto);
    response.then((data: any) => {
      if (data?.Bay?.instance) {
        setTransformer(data.Bay);
      }
    });

    try {
      const promise4 =
        transformerService.getTransformerBusesDataRecords(reqDto);

      const promise5 =
        transformerService.getTransformerLinesDataRecords(reqDto);

      Promise.all([promise4, promise5])
        .then((values) => {
          if (values[0]?.status === 200) {
            if (values[0]?.data?.data) {
              const _connectivityDataset =
                commonUtils.getTransformerConnectivityChartProperties(
                  values[0]?.data?.data?.connectivityNodes || []
                );

              setConnectivityDataset({
                currentDataset: _connectivityDataset.currentDataset,
                voltageDataset: _connectivityDataset.voltageDataset,
              });
            }
          }

          if (values[1]?.status === 200) {
            if (values[1]?.data?.data) {
              const _dataset = commonUtils.getTransformerLineChartProperties(
                values[1]?.data?.data?.acLineSegment || []
              );

              setLineDataset({
                currentDataset: _dataset.currentDataset,
              });
            }
          }
        })
        .catch((error) => {});
    } catch (error) {
      console.error("Error fetching data records:", error);
    }
  };

  useEffect(() => {
    if (transformerRid) {
      getDataRecords(transformerRid, transformerType, transformerName);
    }
  }, [transformerRid]);

  const onDetailsClick = () => {
    let _name = (
      transformer?.name?.replaceAll("tr(", "").replaceAll(")", "") || "-"
    )?.replace("Bay_", "");
    let _type = transformerType;
    let _rid = transformerRid;
    dispatch(
      queryBuilderActions.addTab({
        value: _type + "_" + _rid,
        label: _name,
        rid: _rid,
        type: _type,
      })
    );
  };

  const renderInfo = () => {
    return (
      <div className="v-card-text">
        <div
          className="v-list card-list"
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <div className="v-list-item w-55">
            <div className="v-list-item__content mb-1">
              <div className="v-list-item-subtitle">Bay</div>
              <div className="v-list-item-title">
                {(
                  transformer?.name
                    ?.replaceAll("tr(", "")
                    .replaceAll(")", "") || "-"
                )?.replace("Bay_", "")}
              </div>
            </div>
          </div>

          <div className="v-list-item w-45">
            <div className="v-list-item__content mb-1">
              <div className="v-list-item-subtitle">Substation</div>
              <div className="v-list-item-title">
                {transformer?.instance?.subStation}
              </div>
            </div>
          </div>
        </div>
        <div className="v-list card-list">
          <div className="v-list-item">
            <div className="v-list-item__content mb-1">
              <div className="v-list-item-subtitle">Voltage Levels</div>
              <div className="v-list-item-title">
                {transformer?.instance?.voltageLevels
                  ?.map((item: any) => item + " Kv")
                  .join(", ") || "-"}
              </div>
            </div>
          </div>
        </div>
        <div className="v-list card-list">
          <div className="v-list-item">
            <div className="v-list-item__content mb-1">
              {transformer.name !== "-" ? (
                <i
                  className="fa fa-info-circle v-list-item-details-icon"
                  onClick={() => {
                    onDetailsClick();
                  }}
                  title="View Details"
                ></i>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="dtech-transformer-section-main-wrapper static-section">
      <div>
        {renderInfo()}
        <TabContext value={value}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="gis-component-popup-tab"
          >
            <TabList
              onChange={(event: React.SyntheticEvent, newValue: string) => {
                setValue(newValue);
              }}
            >
              <Tab
                label="Voltage (p.u)"
                value="1"
                sx={{
                  textTransform: "none !important",
                }}
              />
              {/* <Tab label="Current (loading%)" value="2" /> */}
              <Tab
                label="Current (loading%)"
                value="3"
                sx={{
                  textTransform: "none !important",
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="row gis-component-popup-chart dtech-substation-footer-chart-data-section">
              <div className="middle-chart-div">
                <ChartComponent
                  labels={labels}
                  datasets={connectivityDataset?.voltageDataset}
                  title={"Voltage (p.u)"}
                  yAxisTitle={"Voltage (p.u)"}
                  xAxisTitle={"Time (24 hours)"}
                  suggestedMax={1.05}
                  height={"36vh"}
                  labelPosition={"bottom"}
                  labelAlign={"start"}
                />
              </div>
            </div>
          </TabPanel>
          {/* <TabPanel value="2">
            <div className="row gis-component-popup-chart dtech-substation-footer-chart-data-section">
              <div className="middle-chart-div">
                <ChartComponent
                  labels={labels}
                  datasets={connectivityDataset?.currentDataset}
                  title={"Current (loading%)"}
                  yAxisTitle={"Current (loading%)"}
                  xAxisTitle={"Time (24 hours)"}
                  suggestedMax={5}
                  height={"36vh"}     labelPosition={"bottom"}
                  labelAlign ={ "center"}
                />
              </div>
            </div>
          </TabPanel> */}
          <TabPanel value="3">
            <div className="row gis-component-popup-chart dtech-substation-footer-chart-data-section">
              <div className="middle-chart-div">
                <ChartComponent
                  labels={labels}
                  datasets={lineDataset?.currentDataset}
                  title={"Current (loading%)"}
                  yAxisTitle={"Current (loading%)"}
                  xAxisTitle={"Time (24 hours)"}
                  suggestedMax={5}
                  height={"36vh"}
                  labelPosition={"bottom"}
                  labelAlign={"start"}
                />
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};

export default TransformerPopupComponent;
