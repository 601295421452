import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import Alarm from "../alarm.component/alarm";
import ChartComponent from "../chart.component/chart";
import CustomerDataTable from "../customer.component/customer-data-table";
import "./transformer-style.scss";
import { IAlarmsPayload } from "../alarm.list.component/alarm.interface";
import { alarmInfoActions } from "../../store/alarm-info/alarm-info.actions";
import { IAlarmData } from "../../store/alarm-info/alarm-info.interface";
import CustomerDataTableWithStickyHeader from "../customer.component/customer-data-table-sticky";
import { IGetDataRecordsReq } from "../../store/transformer/transformer.interface";
import { transformerActions } from "../../store/transformer/transformer.action";
import { commonUtils } from "../../utils/common.utils";
import { seqrOpsActions } from "../../store/seqr-ops/seqr-ops.action";
import { useLocation, useParams } from "react-router-dom";
import { encryptUtils } from "../../utils/encryption.utils";
import { IDataRecordReq } from "../../store/customer/customer.interface";
import { sidebarActions } from "../../store/sidebar/sidebar.action";
import { setSidebarTransformerItem } from "../../store/sidebar/sidebar.slice";

const Transformer = () => {
  const dispatch = useAppDispatch();
  const [alarms, setAlarms] = useState<IAlarmData[]>();
  const transformerData = useAppSelector((state) => state.sidebarSlice.Bay);
  const location = useLocation();
  const locationParams = useParams();

  const consumersData = useAppSelector(
    (state) => state.transformerSlice.consumerData
  );
  const consumerSummary = useAppSelector(
    (state) => state.transformerSlice.consumerSummary
  );
  const storageSummary = useAppSelector(
    (state) => state.transformerSlice.storageData
  );
  const storageData = useAppSelector(
    (state) => state.transformerSlice.storageData
  );
  const pvSummary = useAppSelector((state) => state.transformerSlice.pvData);
  const connectivityData = useAppSelector(
    (state) => state.transformerSlice.connectivity
  );
  const lineData = useAppSelector((state) => {
    return state.transformerSlice.lines;
  });

  const acLineSegment = useAppSelector(
    (state) => state.transformerSlice.acLineSegment
  );
  const connectivityNodes = useAppSelector(
    (state) => state.transformerSlice.connectivityNodes
  );
  const consumerData: any = useAppSelector(
    (state) => state.transformerSlice.consumerData
  );

  const alarmData: any = useAppSelector(
    (state) => state.alarmInfo.transformerAlarmInfo
  );

  const [connectivityDataset, setConnectivityDataset] = useState<any>({
    currentDataset: [],
    voltageDataset: [],
  });
  const [lineDataset, setLineDataset] = useState<any>({
    currentDataset: [],
  });
  const [consumerSummaryDataset, setConsumerSummaryDataset] = useState<any>({
    columns: ["Consumer", "kW", "kVar"],
    data: [],
  });
  const [pvSummaryDataset, setPvSummaryDataset] = useState<any>({
    columns: ["PV System", "kW", "kVar"],
    data: [],
  });
  const [storageSummaryDataset, setStorageSummaryDataset] = useState<any>({
    columns: ["Battery", "kW", "kVar", "State"],
    data: [],
  });
  const labels: any = commonUtils.generateXAxisLabels();

  const data = [
    {
      name: "clothesDryerkvar",
      value: 1,
      unit: "6",
    },
    {
      name: "clothesDryerkw",
      value: 2,
      unit: "5",
    },
    {
      name: "clothesDryerkw",
      value: 2,
      unit: "5",
    },
    {
      name: "clothesDryerkw",
      value: 2,
      unit: "5",
    },
    {
      name: "clothesDryerkw",
      value: 2,
      unit: "5",
    },

    {
      name: "clothesDryerkw",
      value: 2,
      unit: "5",
    },
  ];
  const columnsToShow = ["name", "value", "unit"];
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const getAlarms = () => {
    if (transformerData?.rid) {
      const alarmRequest: IAlarmsPayload = {
        name: transformerData.name,
        pageNo: 0,
        pageSize: 5,
        rid: transformerData.rid,
        searchKey: "",
        sortKey: "",
        sortOrder: "",
        time: commonUtils.getCurrentUTCTime(),
        type: "PowerTransformer",
      };

      alarmInfoActions.getAlarms(alarmRequest).then((response: any) => {
        setAlarms(response?.data?.alarms);
      });
    }
  };
  useEffect(() => {
    getAlarms();
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };
    if (connectivityNodes) {
      const _connectivityDataset =
        commonUtils.getTransformerConnectivityChartProperties(
          connectivityNodes
        );

      setConnectivityDataset({
        currentDataset: _connectivityDataset.currentDataset,
        voltageDataset: _connectivityDataset.voltageDataset,
      });
    }
    const _lineDataset =
      commonUtils.getTransformerLineChartProperties(acLineSegment);

    setLineDataset({
      currentDataset: _lineDataset.currentDataset,
    });

    setConsumerSummaryDataset({
      ...consumerSummaryDataset,
      data: [],
    });
    setPvSummaryDataset({
      ...pvSummaryDataset,
      data: [],
    });
    setStorageSummaryDataset({
      ...storageSummaryDataset,
      data: [],
    });

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const { id } = locationParams;
    try {
      var __param = encryptUtils.decryptURL(id);
      if (__param?.rid) {
        const sideBarReq: IDataRecordReq = {
          rid: __param?.rid,
          type: __param.type,
          name: __param.label,
          time: commonUtils.getCurrentUTCTime(),
        };
        sidebarActions.getAllDataByListItem(sideBarReq).then((data: any) => {
          dispatch(setSidebarTransformerItem(data.Bay));
        });
      }
    } catch (err) {}
  }, [location]);

  useEffect(() => {
    getAlarms();
    const getDataRecords = async () => {
      if (transformerData?.rid) {
        const transformerDetailsReq: IGetDataRecordsReq = {
          rid: transformerData.rid,
          type: transformerData.type,
          name: transformerData.name,
          time: commonUtils.getCurrentUTCTime(),
        };

        try {
          dispatch(
            transformerActions.getAllTransformerDataRecords(
              transformerDetailsReq
            )
          );
        } catch (error) {
          console.error("Error fetching data records:", error);
        }
      } else {
        dispatch(seqrOpsActions.setLoader(false));
      }
    };
    getDataRecords();

    const intervalId = setInterval(getDataRecords, 300000);

    return () => clearInterval(intervalId);
  }, [transformerData]);

  useEffect(() => {
    if (connectivityNodes?.length > 0) {
      const _dataset =
        commonUtils.getTransformerConnectivityChartProperties(
          connectivityNodes
        );
      setConnectivityDataset({
        currentDataset: _dataset.currentDataset,
        voltagetDataset: _dataset.voltageDataset,
      });
    }
  }, [connectivityNodes]);

  useEffect(() => {
    if (acLineSegment?.length > 0) {
      const _dataset =
        commonUtils.getTransformerLineChartProperties(acLineSegment);

      setLineDataset({
        currentDataset: _dataset.currentDataset,
      });
    }
  }, [acLineSegment]);

  useEffect(() => {
    if (pvSummary?.length > 0) {
      const flatList = pvSummary.flatMap((pvUnit) => {
        const { name, data } = pvUnit;
        const kwValues = data.map((item: any) => ({
          name: name || "-",
          time: item.time,
          kW: (item.kw || 0).toFixed(2),
          kVar: (item.kvar || 0).toFixed(2),
        }));

        return kwValues;
      });

      const uniqueMap = new Map();
      flatList.forEach((item) => {
        if (
          !uniqueMap.has(item.name) ||
          item.time > uniqueMap.get(item.name).time
        ) {
          uniqueMap.set(item.name, item);
        }
      });

      const uniqueFlatList = Array.from(uniqueMap.values());

      setPvSummaryDataset((prevState: any) => ({
        ...prevState,
        data: uniqueFlatList.filter(
          (value) => value !== null && value !== undefined
        ),
      }));
    }
  }, [pvSummary]);

  useEffect(() => {
    if (storageData?.length > 0) {
      const flatList = storageData.flatMap((battery: any) => {
        const { name, data } = battery;
        const kwValues = data.map((item: any) => ({
          name: name || "-",
          time: item.time,
          kW: (item.kw || 0).toFixed(2),
          kVar: (item.kvar || 0).toFixed(2),
          state: item.state,
        }));
        return kwValues;
      });

      const uniqueMap = new Map();
      flatList.forEach((item) => {
        if (
          !uniqueMap.has(item.name) ||
          item.time > uniqueMap.get(item.name).time
        ) {
          uniqueMap.set(item.name, item);
        }
      });

      const uniqueFlatList = Array.from(uniqueMap.values());

      setStorageSummaryDataset((prevState: any) => ({
        ...prevState,
        data: uniqueFlatList.filter(
          (value) => value !== null && value !== undefined
        ),
      }));
    }
  }, [storageData]);

  useEffect(() => {
    if (consumersData?.length > 0) {
      const flatList = consumersData.flatMap((consumer) => {
        const { name, data } = consumer;
        const kwValues = data.map((item: any) => ({
          name: name || "-",
          time: item.time,
          kW: (item.total_site_electricity_kw || 0).toFixed(2),
          kVar: (item.total_site_electricity_kvar || 0).toFixed(2),
        }));
        return kwValues;
      });

      const uniqueMap = new Map();
      flatList.forEach((item) => {
        if (
          !uniqueMap.has(item.name) ||
          item.time > uniqueMap.get(item.name).time
        ) {
          uniqueMap.set(item.name, item);
        }
      });

      const uniqueFlatList = Array.from(uniqueMap.values());

      setConsumerSummaryDataset((prevState: any) => ({
        ...prevState,
        data: uniqueFlatList.filter(
          (value) => value !== null && value !== undefined
        ),
      }));
    }
  }, [consumersData]);

  return (
    <div className="dtech-transformer-section-wrapper mt-3 static-section">
      <div className="container-fluid">
        <div
          className="container-fluid"
          style={{ height: windowHeight - 240 + "px", overflow: "scroll" }}
        >
          <div className="dtech-transformer-header-section">
            <div>
              <span className="dtech-transformer-heading">{"Bay - "}</span>
              <span className="transformer-name-value">
                {(
                  transformerData?.name
                    ?.replaceAll("tr(", "")
                    .replaceAll(")", "") || "-"
                )?.replace("Bay_", "")}
              </span>
            </div>
            <div className="transformer-header-inner-grid-container">
              <div className="transformer-header-inner-grid">
                <span
                  className="transformer-header-title"
                  style={{ fontSize: 12 }}
                >
                  Substation
                </span>
                <span className="transformer-header-title-value">
                  {transformerData?.instance.subStation || "-"}
                </span>
              </div>
              <div className="transformer-header-inner-grid">
                <span
                  className="transformer-header-title"
                  style={{ fontSize: 12 }}
                >
                  Voltage level
                </span>
                <span className="transformer-header-title-value">
                  {transformerData?.instance.voltageLevels?.join(", ") || "-"}
                </span>
              </div>
            </div>
          </div>
          <div
            className="dtech-transformer-chart-first-section col-sm-12 row"
            style={{ marginLeft: 1, marginRight: 1 }}
          >
            <div
              className={`${
                windowWidth < 1200 ? "col-sm-12 p-0" : "col-sm-7 row"
              }`}
            >
              <div className="dtech-transformer-chart-first-section-charts">
                <ChartComponent
                  labels={labels}
                  datasets={connectivityDataset?.voltagetDataset}
                  title={"Voltage (p.u)"}
                  yAxisTitle={"Voltage (p.u)"}
                  xAxisTitle={"Time (24 hours)"}
                  suggestedMax={1.05}
                />
              </div>
              <div className="dtech-transformer-chart-first-section-charts second-chart">
                <ChartComponent
                  labels={labels}
                  datasets={connectivityDataset?.currentDataset}
                  title={"Current (loading%)"}
                  yAxisTitle={"Current (loading%)"}
                  xAxisTitle={"Time (24 hours)"}
                  suggestedMax={5}
                />
              </div>
              <div className="dtech-transformer-chart-first-section-charts">
                <div className="dtech-map-data-section">
                  <ChartComponent
                    labels={labels}
                    datasets={lineDataset?.currentDataset}
                    title={"Current (loading%)"}
                    yAxisTitle={"Current (loading%)"}
                    xAxisTitle={"Time (24 hours)"}
                    suggestedMax={5}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className={`${
                windowWidth < 1200 ? "col-sm-12 mt-2 p-0" : "col-sm-5"
              }`}
            >
              <div className="dtech-transformer-chart-first-section-tables">
                <CustomerDataTableWithStickyHeader
                  data={consumerSummaryDataset.data}
                  columnsToShow={consumerSummaryDataset.columns}
                  showConnectivity={false}
                />
                {/* <CustomerDataTable data={data} columnsToShow={columnsToShow} /> */}
              </div>
              <div className="dtech-transformer-chart-first-section-tables">
                <CustomerDataTableWithStickyHeader
                  data={pvSummaryDataset.data}
                  columnsToShow={pvSummaryDataset.columns}
                  showConnectivity={false}
                />
              </div>
              <div className="dtech-transformer-chart-first-section-tables">
                <CustomerDataTableWithStickyHeader
                  data={storageSummaryDataset.data}
                  columnsToShow={storageSummaryDataset.columns}
                  showConnectivity={false}
                />
              </div>
              <div className="dtech-transformer-chart-first-section-tables">
                <div className="dtech-map-data-section">
                  <Alarm alarmData={alarms} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transformer;
