import { AppThunk } from "../index";
import { sidebarService } from "../sidebar/sidebar.service";
import { IQBTab } from "./query-builder.interface";
import {
  setActiveTab,
  addTab,
  removeTab,
  setMapFilterVisibility,
  setMapZoom,
  setMapFilterSelection,
  setWeatherData,
} from "./query-builder.slice";

const setActiveTabAction = (data: IQBTab): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setActiveTab(data));
  };
};

const addTabAction = (data: IQBTab): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(addTab(data));
  };
};

const removeTabAction = (data: IQBTab): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(removeTab(data));
  };
};

const setMapFilterVisibilityAction = (visibility: boolean): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setMapFilterVisibility(visibility));
  };
};

const setZoomAction = (data: { zoom: number; latlng: number[] }): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setMapZoom(data));
  };
};

const setMapFilterSelectionAction = (data: {
  checked: string[];
  prevChecked: string[];
}): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setMapFilterSelection(data));
  };
};

const getWeatherDataAction = (): AppThunk => {
  return async (dispatch, getState) => {
    const response = await sidebarService.getWeaatherData();

    if (response?.data?.data?.[0]) {
      dispatch(setWeatherData(response?.data?.data?.[0]));
    }
  };
};

export const queryBuilderActions = {
  setActiveTab: setActiveTabAction,
  addTab: addTabAction,
  removeTab: removeTabAction,
  setMapFilterVisiblity: setMapFilterVisibilityAction,
  setZoom: setZoomAction,
  setMapFilterSelection: setMapFilterSelectionAction,
  getWeatherData: getWeatherDataAction,
};
