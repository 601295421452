import axios from "axios";
import {
  ASSET_AUTH_API,
  COOKIE_DETAILS,
  KEYCLOAK_CLIENTS,
  KEYCLOAK_CLIENT_SECRETS,
  KEYCLOAK_GRANT_TYPES,
  SESSION_KEY,
} from "../config/default.config";
import { setTokens } from "../store/keycloak/asset-keycloak.slice";
import { store } from "../store";
import {
  ITokenDetails,
  IValidateTokenPayload,
} from "../store/keycloak/asset-keycloak.interface";
import { sessionStorageUtils } from "./session-storage.utils";
import jwt_decode from "jwt-decode";
import { setLoading } from "../store/seqr-ops/seqr-ops.slice";
import { encryptUtils } from "./encryption.utils";
import { cookieUtils } from "./cookie.utils";

const initialize = () => {
  let data: any;

  // Request interceptor
  axios.interceptors.request.use(
    (config) => {
      let tokenData: any = sessionStorageUtils.getLocalStorage(
        SESSION_KEY.LOCAL_STORAGE_KEY
      );
      if (tokenData !== null) {
        data = tokenData;
        const token = data?.token?.auth_token;

        if (token) {
          config.headers["Authorization"] = "Bearer " + token;
        }
        if (
          config.url === ASSET_AUTH_API.GET_LOGIN_TOKEN ||
          config.url === ASSET_AUTH_API.LOGOUT_TOKEN ||
          config.url === ASSET_AUTH_API.VERIFY_LOGIN_TOKEN
        ) {
          config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        }
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // Response interceptor
  axios.interceptors.response?.use(
    (response) => {
      if (response?.config.url === ASSET_AUTH_API.VERIFY_LOGIN_TOKEN) {
        let data = response.data;
        if (response?.data?.active === false) {
          const refreshToken = data?.refresh_token;
          const session_id = data?.session_state;

          const params = new URLSearchParams();
          params.append("username", "");
          params.append("password", "");
          params.append("grant_type", KEYCLOAK_GRANT_TYPES.REFRESH_TOKENS);
          params.append("client_secret", KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE);
          params.append("client_id", KEYCLOAK_CLIENTS.ASSET_SUITE);
          params.append("refresh_token", refreshToken);
          params.append("session_id", encryptUtils.encryptAuthKey(session_id));
          axios.post(ASSET_AUTH_API.GET_LOGIN_TOKEN, params).then((res) => {
            if (res?.status === 201) {
              let cookieName = COOKIE_DETAILS.COOKIE_NAME;
              let domainName = COOKIE_DETAILS.COOKIE_DOMAIN;
              let sessionId = res?.data?.session_state;
              let sessionThemeType = {
                sessionId: sessionId,
                themeType: "",
              };
              let encryptedSessionThemeType =
                encryptUtils.encryptURL(sessionThemeType);
              cookieUtils.setCookie(cookieName, encryptedSessionThemeType, {
                domain: domainName,
              });

              cookieUtils.setCookie(cookieName, sessionId, {
                domain: domainName,
              });

              let auth_token = res?.data?.access_token;
              let refresh_token = res?.data?.refresh_token;
              let username = "";
              try {
                let decoded: any = jwt_decode(auth_token);
                username = decoded.preferred_username;
              } catch (err) {
                username = "";
              }

              const ITokenDetails: ITokenDetails = {
                auth_token: auth_token,
                refresh_token: refresh_token,
                client_id: KEYCLOAK_CLIENTS.ASSET_SUITE,
                client_secret: KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE,
                username: username || "",
                password: "",
                session_id: res?.data?.session_state,
              };
              store.dispatch(setTokens(ITokenDetails));
              const IValidateTokenPayload: IValidateTokenPayload = {
                token: data?.token?.auth_token,
                client_id: data?.token?.client_id,
                client_secret: data?.token?.client_secret,
                username: data?.token?.username,
                password: data?.token?.password,
                grant_type: KEYCLOAK_GRANT_TYPES.PASSWORD,
              };
              // assetKeycloakActions.validateToken(IValidateTokenPayload, data?.token?.refresh_token, ITokenDetails);
              sessionStorageUtils.setLocalStorage(
                SESSION_KEY.LOCAL_STORAGE_KEY,
                res?.data
              );
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + auth_token;
            }
          });
        }
      }
      return response;
    },
    function (error: any) {
      const originalRequest = error.config;

      if (
        error?.response?.status === 401 &&
        originalRequest.url === ASSET_AUTH_API.GET_LOGIN_TOKEN
      ) {
        return Promise.reject(error);
      }

      if (error?.response?.status === 401) {
        originalRequest._retry = true;
        const refreshToken = data?.token?.refresh_token;
        const session_id = data.token.session_state;

        const params = new URLSearchParams();
        params.append("password", "");
        params.append("username", "");
        params.append("grant_type", KEYCLOAK_GRANT_TYPES.REFRESH_TOKENS);
        params.append("client_secret", KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE);
        params.append("client_id", KEYCLOAK_CLIENTS.ASSET_SUITE);
        params.append("refresh_token", refreshToken || "");
        params.append("session_id", session_id || "");

        return axios
          .post(ASSET_AUTH_API.GET_LOGIN_TOKEN, params)
          .then((res) => {
            if (res?.status === 201) {
              let cookieName = COOKIE_DETAILS.COOKIE_NAME;
              let domainName = COOKIE_DETAILS.COOKIE_DOMAIN;
              let sessionId = res?.data?.session_state;
              let sessionThemeType = {
                sessionId: sessionId,
                themeType: "",
              };
              let encryptedSessionThemeType =
                encryptUtils.encryptURL(sessionThemeType);
              cookieUtils.setCookie(cookieName, encryptedSessionThemeType, {
                domain: domainName,
              });

              let auth_token = res?.data?.access_token;
              let refresh_token = res?.data?.refresh_token;
              let username = "";
              try {
                let decoded: any = jwt_decode(auth_token);
                username = decoded.preferred_username;
              } catch (error) {
                username = "";
              }

              const ITokenDetails: ITokenDetails = {
                auth_token: auth_token,
                refresh_token: refresh_token,
                client_id: KEYCLOAK_CLIENTS.ASSET_SUITE,
                client_secret: KEYCLOAK_CLIENT_SECRETS.ASSET_SUITE,
                username: username || "",
                password: "",
                session_id: res?.data?.session_state,
              };
              store.dispatch(setTokens(ITokenDetails));
              localStorage.setItem(SESSION_KEY.LOCAL_STORAGE_KEY, res?.data);
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + auth_token;
              return axios(originalRequest);
            }
          });
      } else if (error?.response?.status === 400) {
        store.dispatch(setLoading(false));
      }
      // return Promise.reject(error);
    }
  );
};

export const interceptiorUtils = {
  initialize,
};
