import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IModelData, ITransformerData } from "./transformer.interface";

const initialState: ITransformerData = {
  lines: [],
  connectivity: [],
  storageSummary: [],
  consumerSummary: [],
  pvSummary: [],
  consumers: [],
  acLineSegment: [],
  connectivityNodes: [],
  consumerData: [],
  pvData: [],
  storageData: [],
  transformerDataCheck: false,
};
const transformerSlice = createSlice({
  name: "transformerSlice",
  initialState: initialState,
  reducers: {
    setTransformerDataRecords: (
      state,
      action: PayloadAction<ITransformerData>
    ) => {
      state.consumers = action?.payload?.consumers;
      state.lines = action?.payload?.lines;
      state.connectivity = action?.payload?.connectivity;
      state.acLineSegment = action?.payload?.acLineSegment;
      state.connectivityNodes = action?.payload?.connectivityNodes;
      state.pvData = action?.payload?.pvData;
      state.consumerData = action?.payload?.consumerData;
      state.storageData = action?.payload?.storageData;

      if (
        action?.payload?.acLineSegment.length > 0 &&
        action?.payload?.connectivityNodes.length > 0 &&
        action?.payload?.pvData.length > 0 &&
        action?.payload?.consumerData.length > 0 &&
        action?.payload?.storageData.length > 0
      ) {
        state.transformerDataCheck = true;
      }

      // state.battery = action?.payload?.battery;
    },
    setTransformerEnergyConsumerDataRecords: (
      state,
      action: PayloadAction<ITransformerData>
    ) => {
      state.consumerData = action?.payload?.consumerData;

      if (action?.payload?.consumerData.length > 0) {
        state.transformerDataCheck = true;
      }

      // state.battery = action?.payload?.battery;
    },
    setTransformerBatteryUnitDataRecords: (
      state,
      action: PayloadAction<ITransformerData>
    ) => {
      state.storageData = action?.payload?.storageData;

      if (action?.payload?.storageData.length > 0) {
        state.transformerDataCheck = true;
      }

      // state.battery = action?.payload?.battery;
    },
    setTransformerPvUnitDataRecords: (
      state,
      action: PayloadAction<ITransformerData>
    ) => {
      state.pvData = action?.payload?.pvData;

      if (action?.payload?.pvData.length > 0) {
        state.transformerDataCheck = true;
      }

      // state.battery = action?.payload?.battery;
    },
    setTransformerBusesDataRecords: (
      state,
      action: PayloadAction<ITransformerData>
    ) => {
      state.connectivityNodes = action?.payload?.connectivityNodes;

      if (action?.payload?.connectivityNodes.length > 0) {
        state.transformerDataCheck = true;
      }

      // state.battery = action?.payload?.battery;
    },
    setTransformerLinesDataRecords: (
      state,
      action: PayloadAction<ITransformerData>
    ) => {
      state.acLineSegment = action?.payload?.acLineSegment;

      if (action?.payload?.acLineSegment?.length > 0) {
        state.transformerDataCheck = true;
      }

      // state.battery = action?.payload?.battery;
    },
  },
});

export const {
  setTransformerDataRecords,
  setTransformerEnergyConsumerDataRecords,
  setTransformerBatteryUnitDataRecords,
  setTransformerPvUnitDataRecords,
  setTransformerBusesDataRecords,
  setTransformerLinesDataRecords,
} = transformerSlice.actions;
export default transformerSlice.reducer;
