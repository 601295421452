import axios from "axios";
import {
  ASSET_AUTH_API,
  GRID_MONITORING_API,
} from "../../config/default.config";
import { IGetModelDetailsReq } from "./substation.interface";
import { IDataRecordReq } from "../customer/customer.interface";

const getSubstationData = async (getSubststionReq: IGetModelDetailsReq) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_MODEL_DETAILS,
    getSubststionReq
  );

  return response;
};

const getSubstationDataRecords = async (getSubststionReq: IDataRecordReq) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_SUBSTATION_DATA_RECORDS,
    getSubststionReq
  );

  return response;
};

const getSubstationCircuitDataRecords = async (
  getSubststionReq: IDataRecordReq
) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_SUBSTATION_CIRCUIT_DATA_RECORDS,
    getSubststionReq
  );

  return response;
};
export const substationService = {
  getSubstationData,
  getSubstationDataRecords,
  getSubstationCircuitDataRecords,
};
