import { LatLngBounds, LatLngBoundsExpression } from "leaflet";
import { mapInfoActions } from "../../../store/map-info/map-info.action";

async function getGisData(
  substationId: string,
  transformerId: string,
  sourceModel: string,
  targetModel: string,
  boundsData: LatLngBounds | undefined
) {
  // if (!boundsData) return [];
  // const minLat = boundsData.getSouthWest().lat;
  // const minLng = boundsData.getSouthWest().lng;
  // const maxLat = boundsData.getNorthEast().lat;
  // const maxLng = boundsData.getNorthEast().lng;

  try {
    const reqBody = {
      sourceModel,
      targetModel,
      connectedModels: [],
      sourceId: "",
      minLong: 0,
      minLat: 0,
      maxLong: 0,
      maxLat: 0,
      substationId,
      transformerId,
    };

    return new Promise((resolve, reject) => {
      mapInfoActions
        .getGisData(reqBody)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Error calling getSubstationGisData:", error);
          reject(error);
        });
    });
  } catch (error) {
    console.error("Error fetching QGIS data:", error);
    throw error;
  }
}

async function getLineVoltageGisData(
  sourceId: string,
  sourceModel: string,
  targetModel: string,
  boundsData: LatLngBounds | undefined
) {
  if (!boundsData) return [];
  const minLat = boundsData.getSouthWest().lat;
  const minLng = boundsData.getSouthWest().lng;
  const maxLat = boundsData.getNorthEast().lat;
  const maxLng = boundsData.getNorthEast().lng;

  try {
    const reqBody = {
      sourceModel,
      targetModel,
      connectedModels: ["ACLineSegment"],
      sourceId,
      minLong: minLng,
      minLat,
      maxLong: maxLng,
      maxLat,
    };

    return new Promise((resolve, reject) => {
      mapInfoActions
        .getLineVoltageGisData(reqBody)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Error calling getSubstationGisData:", error);
          reject(error);
        });
    });
  } catch (error) {
    console.error("Error fetching QGIS data:", error);
    throw error;
  }
}
async function getLineLoadingGisData(
  sourceId: string,
  sourceModel: string,
  targetModel: string,
  boundsData: LatLngBounds | undefined
) {
  if (!boundsData) return [];
  const minLat = boundsData.getSouthWest().lat;
  const minLng = boundsData.getSouthWest().lng;
  const maxLat = boundsData.getNorthEast().lat;
  const maxLng = boundsData.getNorthEast().lng;

  try {
    const reqBody = {
      sourceModel,
      targetModel,
      connectedModels: ["ACLineSegment"],
      sourceId,
      minLong: minLng,
      minLat,
      maxLong: maxLng,
      maxLat,
    };

    return new Promise((resolve, reject) => {
      mapInfoActions
        .getLineLoadingGisData(reqBody)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("Error calling getSubstationGisData:", error);
          reject(error);
        });
    });
  } catch (error) {
    console.error("Error fetching QGIS data:", error);
    throw error;
  }
}

export const GISUtils = {
  getGisData,
  getLineVoltageGisData,
  getLineLoadingGisData,
};
