import React, { useEffect } from "react";
import { TileLayer, useMap } from "react-leaflet";
import { useGIS } from "../hooks/useGIS";
import { debounce } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../store";
import { queryBuilderActions } from "../../../store/query-builder/query-builder.actions";
const InnerGISComponent = ({ maxZoom }: { maxZoom: number }) => {
  const zoomControl = useAppSelector(
    (state) => state.queryBuilderSlice.zoomControl
  );
  const dispatch = useAppDispatch();
  const { setBounds } = useGIS();
  const map = useMap();

  const debounceMoveEnd = debounce(() => {
    if (map) {
      try {
        const newBounds = map.getBounds();
        setBounds(newBounds);
      } catch (err) {}
    } else {
      console.error("Map object is undefined.");
    }
  }, 800);

  const handleMoveEnd = () => {
    debounceMoveEnd();
  };

  useEffect(() => {
    if (map) {
      handleMoveEnd();
    }
  }, [map, setBounds]);

  useEffect(() => {
    if (map) {
      map.on("moveend", handleMoveEnd);
    }

    return () => {
      if (map) {
        map.off("moveend", handleMoveEnd);
      }
    };
  }, [map, setBounds]);

  useEffect(() => {
    const handleZoomEnd = () => {
      dispatch(
        queryBuilderActions.setZoom({
          zoom: map.getZoom(),
          latlng: [map.getCenter().lat, map.getCenter().lng],
        })
      );
    };

    if (map) {
      map.on("zoomend", handleZoomEnd);
    }

    return () => {
      if (map) {
        map.off("zoomend", handleZoomEnd);
      }
    };
  }, [map]);

  return (
    <>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        maxNativeZoom={maxZoom}
        maxZoom={maxZoom}
      />
    </>
  );
};

export default InnerGISComponent;
