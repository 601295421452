import axios from "axios";
import { GRID_MONITORING_API } from "../../config/default.config";
import { IGetDataReq } from "./sidebar.interface";

const getAllDatasByListItem = async (getAllDataReq: IGetDataReq) => {
  const response = await axios.post(
    GRID_MONITORING_API.GET_ALL_DATA_RECORDS,
    getAllDataReq
  );

  return response;
};

const getWeaatherData = async () => {
  const response = await axios.post(GRID_MONITORING_API.GET_WEATHER_DATA);

  return response;
};

export const sidebarService = {
  getAllDatasByListItem,
  getWeaatherData,
};
