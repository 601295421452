import {
  COOKIE_DETAILS,
  KEYCLOAK_CLIENTS,
  KEYCLOAK_CLIENT_SECRETS,
  KEYCLOAK_GRANT_TYPES,
  MESSAGES,
  UNAUTHORIZED_LOGIN,
} from "../../config/default.config";
import { interceptiorUtils } from "../../utils/interceptor.utils";
import {
  ILoginCredentials,
  ILogoutTokenPayload,
  ISessionDetails,
  ITokenDetails,
  IValidateTokenPayload,
} from "./asset-keycloak.interface";
import { assetKeycloakService } from "./asset-keycloak.service";
import { TokenServices } from "./token-services";
import { AppThunk } from "..";
import { setTokens } from "./asset-keycloak.slice";
import { toasterUtils } from "../../utils/toaster.utils";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { cookieUtils } from "../../utils/cookie.utils";
import { encryptUtils } from "../../utils/encryption.utils";
import { setLoading } from "../seqr-ops/seqr-ops.slice";
import { seqrOpsActions } from "../seqr-ops/seqr-ops.action";

export const validateToken = async (sessionId: string, setReady: any) => {
  // let encryptedSessionId = encryptUtils.encryptAuthKey(sessionId);
  const params = new URLSearchParams();
  params.append("session_id", encryptUtils.encryptAuthKey(sessionId));

  try {
    const response = await assetKeycloakService.validateToken(params);
    if (response?.data?.active) {
      let authToken = response?.data?.access_token;
      let refreshToken = response?.data?.refresh_token;
      let sessionId = response?.data?.session_state;

      interceptiorUtils.initialize();
      TokenServices.processToken(authToken, refreshToken, sessionId, setReady);
      return true;
    } else if (response?.data?.active === false) {
      const refreshTokenReqParams = new URLSearchParams();
      params.append("username", "");
      params.append("password", "");
      params.append("client_id", KEYCLOAK_CLIENTS.ASSET_SUITE);
      params.append("client_secret", KEYCLOAK_CLIENTS.ASSET_SUITE);
      params.append("grant_type", KEYCLOAK_GRANT_TYPES.REFRESH_TOKENS);
      params.append("refresh_token", response?.data?.refresh_token);
      params.append("session_id", sessionId);

      const getTokenResponse = await assetKeycloakService.getLoginToken(
        refreshTokenReqParams
      );
      if (getTokenResponse?.data?.active === true) {
        let authToken = response?.data?.access_token;
        let refreshToken = response?.data?.refresh_token;
        let sessionId = response?.data?.session_state;

        TokenServices.processToken(
          authToken,
          refreshToken,
          sessionId,
          setReady
        );
        return true;
      } else if (!getTokenResponse?.status) {
        return null;
      }
      return null;
    }
  } catch (err) {
    return null;
  }
};
const logoutToken = async (
  ILogoutTokenPayload: ILogoutTokenPayload,
  setSuccess: (state: boolean) => void
) => {
  const params = new URLSearchParams();
  params.append("refresh_token", ILogoutTokenPayload.refresh_token);
  params.append("session_id", ILogoutTokenPayload.session_id);

  try {
    const response = await assetKeycloakService.logoutToken(params);
    if (response.status) {
      let cookieName = `${process.env.REACT_APP_COOKIE_PREFIX}_user_session_id`;
      cookieUtils.deleteCookie(cookieName);
      toasterUtils.showSuccess(MESSAGES.LOGGED_OUT);
      setSuccess(true);
      toasterUtils.showSuccess(MESSAGES.LOGGED_OUT);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const assetKeycloakActions = {
  validateToken,
  logoutToken,
};
