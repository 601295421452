import React, { createContext, useContext, useEffect, useState } from "react";
import { LatLngBounds, Map } from "leaflet";
import { GISUtils } from "../utils/GISUtils";
import { MARKER_SELECTED_TYPE } from "../layers/GISLayerControl";
import { useLocation } from "react-router-dom";
const { v4: uuidv4 } = require("uuid");

export function generateUUID() {
  return uuidv4();
}
let markerReferences: { [key: string]: any } = {};
const areaReferences: { [key: string]: any } = {};

export enum LayerControlType {
  __SCOPE__ = "__SCOPE__",
  NETWORK = "NETWORK",
  NETWORK_SUBSTATIONS = "NETWORK_SUBSTATIONS",
  NETWORK_TRANSFORMERS = "NETWORK_TRANSFORMERS",
  NETWORK_CONSUMERS = "NETWORK_CONSUMERS",
  NETWORK_BATTERY_UNIT = "NETWORK_BATTERY_UNIT",
  NETWORK_PHOTO_VOLTAIC_UNIT = "NETWORK_PHOTO_VOLTAIC_UNIT",
  NETWORK_LINES = "NETWORK_LINE",
  NETWORK_LINE_ABC_LV = "NETWORK_LINE_ABC_LV",
  NETWORK_LINE_B_MV = "NETWORK_LINE_B_MV",
  NETWORK_LINE_ABC_HV = "NETWORK_LINE_ABC_HV",
  NETWORK_LINE_ABC_MV = "NETWORK_LINE_ABC_MV",
  NETWORK_LINE_B_LV = "NETWORK_LINE_B_LV",
  NETWORK_LINE_C_MV = "NETWORK_LINE_C_MV",
  NETWORK_LINE_A_MV = "NETWORK_LINE_A_MV",
  NETWORK_LINE_C_LV = "NETWORK_LINE_C_LV",
  NETWORK_LINE_A_LV = "NETWORK_LINE_A_LV",
  HEATMAP_LOADING = "HEATMAP_LOADING",
  HEATMAP_VOLTAGE = "HEATMAP_VOLTAGE",
  HEATMAP_MAIN = "HEATMAP_MAIN",
  ALARMS = "ALARMS",
  TRENDS_MAIN = "TRENDS_MAIN",
  TRENDS_CIRCUIT_POWER = "TRENDS_CIRCUIT_POWER",
}

export const LayerTypes = {
  SUBSTATIONS: "SUBSTATIONS",
  TRANSFORMERS: "TRANSFORMERS",
  CONSUMERS: "ENERGY_CONSUMERS",
  LINE_ABC_HV: "LINE_ABC_HV",
  LINE_ABC_MV: "LINE_ABC_MV",
  LINE_ABC_LV: "LINE_ABC_LV",
  LINE_A_MV: "LINE_A_MV",
  LINE_A_LV: "LINE_A_LV",
  LINE_B_MV: "LINE_B_MV",
  LINE_B_LV: "LINE_B_LV",
  LINE_C_MV: "LINE_C_MV",
  LINE_C_LV: "LINE_C_LV",
  PHOTO_VOLTAIC_UNIT: "PHOTO_VOLTAIC_UNIT",
  BATTERY_UNIT: "BATTERY_UNIT",
};

let networkScope: string[] = [];

export interface LayerControlItem {
  label: string;
  value: LayerControlType | null;
  checked: boolean | null;
  color: string;
}

export const layerControlInitialState: Record<
  LayerControlType,
  LayerControlItem
> = {
  [LayerControlType.__SCOPE__]: {
    label: "",
    value: null,
    checked: null,
    color: "",
  },
  [LayerControlType.NETWORK]: {
    label: "Network",
    value: LayerControlType.NETWORK,
    checked: false,
    color: "",
  },
  [LayerControlType.NETWORK_SUBSTATIONS]: {
    label: "Substations",
    value: LayerControlType.NETWORK_SUBSTATIONS,
    checked: false,
    color: "#2d7513",
  },
  [LayerControlType.NETWORK_TRANSFORMERS]: {
    label: "Transformers",
    value: LayerControlType.NETWORK_TRANSFORMERS,
    checked: false,
    color: "#9124e3",
  },
  [LayerControlType.NETWORK_CONSUMERS]: {
    label: "Consumers",
    value: LayerControlType.NETWORK_CONSUMERS,
    checked: false,
    color: "#000000",
  },
  [LayerControlType.NETWORK_BATTERY_UNIT]: {
    label: "Storages",
    value: LayerControlType.NETWORK_BATTERY_UNIT,
    checked: false,
    color: "#FFFF00",
  },
  [LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT]: {
    label: "PV Units",
    value: LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT,
    checked: false,
    color: "#00FF00",
  },
  [LayerControlType.NETWORK_LINES]: {
    label: "ACLineSegments",
    value: LayerControlType.NETWORK_LINES,
    checked: false,
    color: "",
  },
  [LayerControlType.NETWORK_LINE_ABC_HV]: {
    label: "ABC_HV",
    value: LayerControlType.NETWORK_LINE_ABC_HV,
    checked: true,
    color: "#000000",
  },
  [LayerControlType.NETWORK_LINE_ABC_MV]: {
    label: "ABC_MV",
    value: LayerControlType.NETWORK_LINE_ABC_MV,
    checked: true,
    color: "#000000",
  },
  [LayerControlType.NETWORK_LINE_ABC_LV]: {
    label: "ABC_LV",
    value: LayerControlType.NETWORK_LINE_ABC_LV,
    checked: true,
    color: "#000000",
  },
  [LayerControlType.NETWORK_LINE_A_MV]: {
    label: "A_MV",
    value: LayerControlType.NETWORK_LINE_A_MV,
    checked: true,
    color: "#dc0003",
  },
  [LayerControlType.NETWORK_LINE_A_LV]: {
    label: "A_LV",
    value: LayerControlType.NETWORK_LINE_A_LV,
    checked: true,
    color: "#e20003",
  },
  [LayerControlType.NETWORK_LINE_B_MV]: {
    label: "B_MV",
    value: LayerControlType.NETWORK_LINE_B_MV,
    checked: true,
    color: "#0cb207",
  },
  [LayerControlType.NETWORK_LINE_B_LV]: {
    label: "B_LV",
    value: LayerControlType.NETWORK_LINE_B_LV,
    checked: true,
    color: "#00b026",
  },
  [LayerControlType.NETWORK_LINE_C_MV]: {
    label: "C_MV",
    value: LayerControlType.NETWORK_LINE_C_MV,
    checked: true,
    color: "#0033e7",
  },
  [LayerControlType.NETWORK_LINE_C_LV]: {
    label: "C_LV",
    value: LayerControlType.NETWORK_LINE_C_LV,
    checked: true,
    color: "#0019d6",
  },

  [LayerControlType.HEATMAP_LOADING]: {
    label: "Loading(%)",
    value: LayerControlType.HEATMAP_LOADING,
    checked: false,
    color: "",
  },
  [LayerControlType.HEATMAP_VOLTAGE]: {
    label: "Voltage(p.u)",
    value: LayerControlType.HEATMAP_VOLTAGE,
    checked: true,
    color: "",
  },
  [LayerControlType.HEATMAP_MAIN]: {
    label: "Heatmap",
    value: LayerControlType.HEATMAP_MAIN,
    checked: false,
    color: "",
  },
  [LayerControlType.ALARMS]: {
    label: "Alarms",
    value: LayerControlType.ALARMS,
    checked: false,
    color: "",
  },
  [LayerControlType.TRENDS_CIRCUIT_POWER]: {
    label: "Circuit Power",
    value: LayerControlType.TRENDS_CIRCUIT_POWER,
    checked: false,
    color: "",
  },
  [LayerControlType.TRENDS_MAIN]: {
    label: "Trends",
    value: LayerControlType.TRENDS_MAIN,
    checked: false,
    color: "",
  },
};

// Define LayerControlType as an enum

// Define GISInnerContextProps interface
interface GISInnerContextProps {
  bounds: LatLngBounds | undefined;
  setBounds: (bounds: LatLngBounds | undefined) => void;

  layerControl: Record<LayerControlType, LayerControlItem>;
  setLayerControl: (
    controls: Record<LayerControlType, LayerControlItem>
  ) => void;
  networkScope: string[];
  resetNetworkScope: (data: string[]) => void;
  markerReferences: { [key: string]: any };
  areaReferences: { [key: string]: any };
  markerSelected: {
    key: string;
    latlng: any;
    feature: any;
    type: MARKER_SELECTED_TYPE | null;
  };
  setMarkerSelected: (data: {
    key: string;
    latlng: any;
    feature: any;
    type: MARKER_SELECTED_TYPE | null;
  }) => void;
  transformerLayerData: IGISLayerData;
  consumerLayerData: IGISLayerData;
  line_ABC_HV_LayerData: IGISLayerData;
  line_ABC_MV_LayerData: IGISLayerData;
  line_ABC_LV_LayerData: IGISLayerData;
  line_A_MV_LayerData: IGISLayerData;
  line_A_LV_LayerData: IGISLayerData;
  line_B_MV_LayerData: IGISLayerData;
  line_B_LV_LayerData: IGISLayerData;
  line_C_MV_LayerData: IGISLayerData;
  line_C_LV_LayerData: IGISLayerData;
  substationLayerData: IGISLayerData;
  lineVoltageLayerData: IGISLayerData;
  lineLoadingLayerData: IGISLayerData;
  batteryUnitLayerData: IGISLayerData;
  pvUnitLayerData: IGISLayerData;
  getGisData: (
    sourceId: string,
    targetModel: string,
    map: Map | undefined,
    reset: boolean,
    type: string
  ) => void;
  getLineVoltageData: (
    sourceId: string,
    targetModel: string,
    map: Map | undefined,
    reset: boolean | undefined
  ) => void;
  getLineLoadingData: (
    sourceId: string,
    targetModel: string,
    map: Map | undefined,
    reset: boolean | undefined
  ) => void;
  consumerReq: IGisReq;
  substationReq: IGisReq;
  transformerReq: IGisReq;
}

export type LayerControlVisibility = {
  [LayerControlType.NETWORK]: boolean;
  [LayerControlType.NETWORK_SUBSTATIONS]: boolean;
  [LayerControlType.NETWORK_TRANSFORMERS]: boolean;
  [LayerControlType.NETWORK_CONSUMERS]: boolean;
  [LayerControlType.NETWORK_BATTERY_UNIT]: boolean;
  [LayerControlType.NETWORK_PHOTO_VOLTAIC_UNIT]: boolean;
  [LayerControlType.HEATMAP_LOADING]: boolean;
  [LayerControlType.HEATMAP_VOLTAGE]: boolean;
  [LayerControlType.ALARMS]: boolean;
  [LayerControlType.TRENDS_CIRCUIT_POWER]: boolean;
};

const getfeatureCollectionInitialValues = () => {
  return {
    key: generateUUID(),
    SUBSTATIONS: { type: "FeatureCollection", features: [] },
    TRANSFORMERS: { type: "FeatureCollection", features: [] },
    ENERGY_CONSUMERS: { type: "FeatureCollection", features: [] },
    LINE_ABC_LV: { type: "FeatureCollection", features: [] },
    LINE_B_MV: { type: "FeatureCollection", features: [] },
    LINE_ABC_HV: { type: "FeatureCollection", features: [] },
    LINE_ABC_MV: { type: "FeatureCollection", features: [] },
    LINE_B_LV: { type: "FeatureCollection", features: [] },
    LINE_C_MV: { type: "FeatureCollection", features: [] },
    LINE_A_MV: { type: "FeatureCollection", features: [] },
    LINE_C_LV: { type: "FeatureCollection", features: [] },
    LINE_A_LV: { type: "FeatureCollection", features: [] },
    BATTERY_UNIT: { type: "FeatureCollection", features: [] },
    PHOTO_VOLTAIC_UNIT: { type: "FeatureCollection", features: [] },
  };
};

const GISInnerContext = createContext<GISInnerContextProps>({
  bounds: undefined,
  networkScope: [],
  resetNetworkScope: () => {},
  markerReferences: {},
  areaReferences: {},
  markerSelected: {
    key: "-",
    latlng: null,
    feature: null,
    type: null,
  },

  setMarkerSelected: () => {},
  setBounds: () => {},
  layerControl: layerControlInitialState,
  setLayerControl: () => {},
  transformerLayerData: getfeatureCollectionInitialValues(),
  consumerLayerData: getfeatureCollectionInitialValues(),
  line_ABC_HV_LayerData: getfeatureCollectionInitialValues(),
  line_ABC_MV_LayerData: getfeatureCollectionInitialValues(),
  line_ABC_LV_LayerData: getfeatureCollectionInitialValues(),
  line_A_MV_LayerData: getfeatureCollectionInitialValues(),
  line_A_LV_LayerData: getfeatureCollectionInitialValues(),
  line_B_MV_LayerData: getfeatureCollectionInitialValues(),
  line_B_LV_LayerData: getfeatureCollectionInitialValues(),
  line_C_MV_LayerData: getfeatureCollectionInitialValues(),
  line_C_LV_LayerData: getfeatureCollectionInitialValues(),
  substationLayerData: getfeatureCollectionInitialValues(),
  lineVoltageLayerData: getfeatureCollectionInitialValues(),
  lineLoadingLayerData: getfeatureCollectionInitialValues(),
  batteryUnitLayerData: getfeatureCollectionInitialValues(),
  pvUnitLayerData: getfeatureCollectionInitialValues(),
  getGisData: () => {},
  getLineVoltageData: () => {},
  getLineLoadingData: () => {},
  consumerReq: {
    sourceId: "",
    sourceModel: "EnergyConsumer",
    targetModel: "",
  },
  substationReq: {
    sourceId: "",
    sourceModel: "Substation",
    targetModel: "",
  },
  transformerReq: {
    sourceId: "",
    sourceModel: "PowerTransformer",
    targetModel: "",
  },
});

export const useGIS = () => useContext(GISInnerContext);

export interface IGISFeature {
  geometry: {
    type: string;
    coordinates: number[][] | number[][][];
  };
  id: number;
  type: string;
  properties: {
    gType: string;
    gName: string;
    color: string;
    gRid: string;
    gConsumerRid: string;
    name: string;
    phasesv?: string;
    nodeb?: string;
    equip?: string;
    feeder?: string;
    nodea?: string;
    phases?: string;
    areaGeometry?: {
      geometry: {
        type: string;
        coordinates: number[][] | number[][][];
      };
      id: number;
      type: string;
    };
  };
}

export interface IGISFeatureCollection {
  type: string;
  features: IGISFeature[];
}
export interface IGISLayerData {
  key: string;
  SUBSTATIONS: IGISFeatureCollection;
  TRANSFORMERS: IGISFeatureCollection;
  ENERGY_CONSUMERS: IGISFeatureCollection;
  LINE_ABC_LV: IGISFeatureCollection;
  LINE_B_MV: IGISFeatureCollection;
  LINE_ABC_HV: IGISFeatureCollection;
  LINE_ABC_MV: IGISFeatureCollection;
  LINE_B_LV: IGISFeatureCollection;
  LINE_C_MV: IGISFeatureCollection;
  LINE_A_MV: IGISFeatureCollection;
  LINE_C_LV: IGISFeatureCollection;
  LINE_A_LV: IGISFeatureCollection;
  BATTERY_UNIT: IGISFeatureCollection;
  PHOTO_VOLTAIC_UNIT: IGISFeatureCollection;
}
interface IGisReq {
  sourceId: string;
  sourceModel: string;
  targetModel: string;
}
export const GISInnerContextProvider = ({ children }: any) => {
  const [bounds, setBounds] = useState<LatLngBounds | undefined>(undefined);
  const location = useLocation();
  const [layerControl, setLayerControl] = useState(layerControlInitialState);
  const [initiated, setInitiated] = useState(false);
  const [markerSelected, setMarkerSelected] = useState<{
    key: string;
    latlng: any;
    feature: any;
    type: MARKER_SELECTED_TYPE | null;
  }>({
    key: generateUUID(),
    latlng: null,
    feature: null,
    type: null,
  });
  const [transformerReq, setTransformerReq] = useState<IGisReq>({
    sourceId: "",
    sourceModel: "",
    targetModel: "",
  });

  const [substationReq, setSubstationReq] = useState<IGisReq>({
    sourceId: "",
    sourceModel: "",
    targetModel: "",
  });

  const [consumerReq, setConsumerReq] = useState<IGisReq>({
    sourceId: "",
    sourceModel: "",
    targetModel: "",
  });

  const [checked, setChecked] = useState<{
    checked: string[];
    prevChecked: string[];
  }>({ checked: [], prevChecked: [] });
  const [transformerLayerData, setTransformerLayerData] =
    useState<IGISLayerData>(getfeatureCollectionInitialValues());

  const [consumerLayerData, setConsumerLayerData] = useState<IGISLayerData>(
    getfeatureCollectionInitialValues()
  );
  const [batteryUnitLayerData, setBatteryUnitLayerData] =
    useState<IGISLayerData>(getfeatureCollectionInitialValues());
  const [pvUnitLayerData, setPvUnitLayerData] = useState<IGISLayerData>(
    getfeatureCollectionInitialValues()
  );
  const [substationLayerData, setSubstationLayerData] = useState<IGISLayerData>(
    getfeatureCollectionInitialValues()
  );
  const [line_ABC_HV_LayerData, setLine_ABC_HV_LayerData] =
    useState<IGISLayerData>(getfeatureCollectionInitialValues());
  const [line_ABC_MV_LayerData, setLine_ABC_MV_LayerData] =
    useState<IGISLayerData>(getfeatureCollectionInitialValues());
  const [line_ABC_LV_LayerData, setLine_ABC_LV_LayerData] =
    useState<IGISLayerData>(getfeatureCollectionInitialValues());
  const [line_A_MV_LayerData, setLine_A_MV_LayerData] = useState<IGISLayerData>(
    getfeatureCollectionInitialValues()
  );
  const [line_A_LV_LayerData, setLine_A_LV_LayerData] = useState<IGISLayerData>(
    getfeatureCollectionInitialValues()
  );
  const [line_B_MV_LayerData, setLine_B_MV_LayerData] = useState<IGISLayerData>(
    getfeatureCollectionInitialValues()
  );
  const [line_B_LV_LayerData, setLine_B_LV_LayerData] = useState<IGISLayerData>(
    getfeatureCollectionInitialValues()
  );
  const [line_C_MV_LayerData, setLine_C_MV_LayerData] = useState<IGISLayerData>(
    getfeatureCollectionInitialValues()
  );
  const [line_C_LV_LayerData, setLine_C_LV_LayerData] = useState<IGISLayerData>(
    getfeatureCollectionInitialValues()
  );

  const [lineVoltageLayerData, setLineVoltageLayerData] =
    useState<IGISLayerData>(getfeatureCollectionInitialValues());

  const [lineLoadingLayerData, setLineLoadingLayerData] =
    useState<IGISLayerData>(getfeatureCollectionInitialValues());

  useEffect(() => {
    if (bounds && !initiated) {
      initialLoad();
    }
  }, [bounds, initiated]);

  useEffect(() => {
    markerReferences = {};
  }, [location]);

  const formatResponseData = (data: any) => {
    if (data === null) {
      return getfeatureCollectionInitialValues();
    }
    return {
      key: generateUUID(),
      SUBSTATIONS: data?.SUBSTATIONS,
      TRANSFORMERS: data?.TRANSFORMERS,
      ENERGY_CONSUMERS: data?.ENERGY_CONSUMERS,
      LINE_ABC_LV: data?.LINE_ABC_LV,
      LINE_B_MV: data?.LINE_B_MV,
      LINE_ABC_HV: data?.LINE_ABC_HV,
      LINE_ABC_MV: data?.LINE_ABC_MV,
      LINE_B_LV: data?.LINE_B_LV,
      LINE_C_MV: data?.LINE_C_MV,
      LINE_A_MV: data?.LINE_A_MV,
      LINE_C_LV: data?.LINE_C_LV,
      LINE_A_LV: data?.LINE_A_LV,
      BATTERY_UNIT: data?.BATTERY_UNIT,
      PHOTO_VOLTAIC_UNIT: data?.PHOTO_VOLTAIC_UNIT,
    };
  };

  function formatResponse(type: string, _data: any) {
    if (type === LayerTypes.SUBSTATIONS) {
      setSubstationLayerData(_data);
    } else if (type === LayerTypes.TRANSFORMERS) {
      setTransformerLayerData(_data);
    } else if (type === LayerTypes.CONSUMERS) {
      setConsumerLayerData(_data);
    } else if (type === LayerTypes.BATTERY_UNIT) {
      setBatteryUnitLayerData(_data);
    } else if (type === LayerTypes.PHOTO_VOLTAIC_UNIT) {
      setPvUnitLayerData(_data);
    } else if (type === LayerTypes.LINE_ABC_HV) {
      setLine_ABC_HV_LayerData(_data);
    } else if (type === LayerTypes.LINE_ABC_MV) {
      setLine_ABC_MV_LayerData(_data);
    } else if (type === LayerTypes.LINE_ABC_LV) {
      setLine_ABC_LV_LayerData(_data);
    } else if (type === LayerTypes.LINE_A_MV) {
      setLine_A_MV_LayerData(_data);
    } else if (type === LayerTypes.LINE_A_LV) {
      setLine_A_LV_LayerData(_data);
    } else if (type === LayerTypes.LINE_B_MV) {
      setLine_B_MV_LayerData(_data);
    } else if (type === LayerTypes.LINE_B_LV) {
      setLine_B_LV_LayerData(_data);
    } else if (type === LayerTypes.LINE_C_MV) {
      setLine_C_MV_LayerData(_data);
    } else if (type === LayerTypes.LINE_C_LV) {
      setLine_C_LV_LayerData(_data);
    }
  }

  function getGisData(
    sourceId: string = "",
    targetModel: string = "",
    map: Map | undefined = undefined,
    reset: boolean,
    type: string
  ) {
    if (reset) {
      const _data: IGISLayerData = formatResponseData(null);
      formatResponse(type, _data);
    } else {
      let substationId = "";
      let transformerId = "";
      if (networkScope.includes(LayerControlType.NETWORK_SUBSTATIONS)) {
        substationId = networkScope
          .filter((item) =>
            item.startsWith(LayerControlType.NETWORK_SUBSTATIONS + "_")
          )[0]
          .replace(LayerControlType.NETWORK_SUBSTATIONS + "_", "");

        if ([LayerTypes.LINE_ABC_HV].includes(type)) {
          substationId = "";
        }
      }
      if (networkScope.includes(LayerControlType.NETWORK_TRANSFORMERS)) {
        transformerId = networkScope
          .filter((item) =>
            item.startsWith(LayerControlType.NETWORK_TRANSFORMERS + "_")
          )[0]
          .replace(LayerControlType.NETWORK_TRANSFORMERS + "_", "");

        if (
          !substationId &&
          [
            LayerTypes.LINE_ABC_HV,
            LayerTypes.LINE_ABC_MV,
            LayerTypes.LINE_A_MV,
            LayerTypes.LINE_B_MV,
            LayerTypes.LINE_C_MV,
          ].includes(type)
        ) {
          transformerId = "";
        } else {
          if (
            [
              LayerTypes.LINE_ABC_HV,
              LayerTypes.LINE_ABC_MV,
              LayerTypes.LINE_A_MV,
              LayerTypes.LINE_B_MV,
              LayerTypes.LINE_C_MV,
            ].includes(type)
          ) {
            transformerId = "";
          }
        }
      }

      GISUtils.getGisData(
        substationId,
        transformerId,
        type,
        targetModel,
        bounds
      )
        .then((data: any) => {
          const _data: IGISLayerData = formatResponseData(data);
          formatResponse(type, _data);
        })
        .catch((error) => {
          const _data: IGISLayerData = formatResponseData(null);
          formatResponse(type, _data);
        })
        .finally(() => {
          if (map) {
            map.closePopup();
          }
        });
    }
  }
  function getLineVoltageData(
    sourceId: string = "ACLineSegment",
    targetModel: string = "",
    map: Map | undefined = undefined,
    reset: boolean = false
  ) {
    if (reset) {
      const _data: IGISLayerData = formatResponseData(null);
      setLineVoltageLayerData(_data);
    } else {
      GISUtils.getLineVoltageGisData(
        sourceId,
        "HEATMAP_VOLTAGE",
        targetModel,
        bounds
      )
        .then((data: any) => {
          setLineVoltageLayerData({
            ...formatResponseData(null),
            LINE_ABC_HV: {
              ...(formatResponseData(null).LINE_ABC_HV || {}),
              features: data || [],
            },
          });
        })
        .catch((error) => {
          const _data: IGISLayerData = formatResponseData(null);
          setLineVoltageLayerData(_data);
        })
        .finally(() => {
          if (map) {
            map.closePopup();
          }
        });
    }
  }
  function getLineLoadingData(
    sourceId: string = "ACLineSegment",
    targetModel: string = "",
    map: Map | undefined = undefined,
    reset: boolean = false
  ) {
    if (reset) {
      const _data: IGISLayerData = formatResponseData(null);
      setLineLoadingLayerData(_data);
    } else {
      GISUtils.getLineLoadingGisData(
        sourceId,
        "HEATMAP_LOADING",
        targetModel,
        bounds
      )
        .then((data: any) => {
          setLineLoadingLayerData({
            ...formatResponseData(null),
            LINE_ABC_HV: {
              ...(formatResponseData(null).LINE_ABC_HV || {}),
              features: data || [],
            },
          });
        })
        .catch((error) => {
          const _data: IGISLayerData = formatResponseData(null);
          setLineLoadingLayerData(_data);
        })
        .finally(() => {
          if (map) {
            map.closePopup();
          }
        });
    }
  }

  function initialLoad() {
    if (initiated) {
    }
  }

  const resetNetworkScope = (__data: string[] = []) => {
    networkScope = __data;
  };
  return (
    <GISInnerContext.Provider
      value={{
        bounds,
        setBounds,
        networkScope,
        resetNetworkScope,
        layerControl,
        setLayerControl,
        substationLayerData,
        transformerLayerData,
        consumerLayerData,
        batteryUnitLayerData,
        pvUnitLayerData,
        line_ABC_HV_LayerData,
        line_ABC_MV_LayerData,
        line_ABC_LV_LayerData,
        line_A_MV_LayerData,
        line_A_LV_LayerData,
        line_B_MV_LayerData,
        line_B_LV_LayerData,
        line_C_MV_LayerData,
        line_C_LV_LayerData,
        lineVoltageLayerData,
        lineLoadingLayerData,
        getGisData,
        getLineVoltageData,
        getLineLoadingData,
        consumerReq,
        substationReq,
        transformerReq,
        markerSelected,
        setMarkerSelected,
        markerReferences,
        areaReferences,
      }}
    >
      {children}
    </GISInnerContext.Provider>
  );
};
