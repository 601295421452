import React, { useEffect, useMemo, useState } from "react";
import { Popup } from "react-leaflet";
import "leaflet.heat";

import { CircleMenu, CircleMenuItem } from "react-circular-menu";
import { MARKER_SELECTED_TYPE } from "../layers/GISLayerControl";

const PopupComponentActions: React.FC<{
  popupType: MARKER_SELECTED_TYPE | null;
  verificationKey: string;
  feature: any;
  latlng: any;
  resetMarker: (_feature: any) => void;
  showNetwork: (feature: any) => void;
  showInfo: (feature: any) => void;
}> = React.memo(
  ({
    feature,
    latlng,
    popupType,
    verificationKey,
    resetMarker,
    showNetwork,
    showInfo,
  }) => {
    const [type, setType] = useState<MARKER_SELECTED_TYPE | null>(null);
    if (!latlng || !feature) return null;

    useEffect(() => {
      setType(popupType);
    }, [popupType, verificationKey]);

    const memoizedPopupActionContent = useMemo(() => {
      if (!feature) return null;

      if (feature?.properties?.gType === "PowerTransformer") {
        return (
          <CircleMenu
            startAngle={0}
            rotationAngle={360}
            itemSize={3}
            radius={2}
            open={true}
            menuToggleClassName="action-trigger"
            rotationAngleInclusive={false}
            menuToggleElement={<span></span>}
          >
            <CircleMenuItem
              className="action-item"
              tooltip="Details"
              onClick={() => {
                setType(MARKER_SELECTED_TYPE.INFO);
                showInfo(feature);
              }}
            >
              <i className="fa fa-info"></i>
            </CircleMenuItem>
            <CircleMenuItem
              className="action-item"
              tooltip="Network"
              onClick={() => {
                showNetwork(feature);
              }}
            >
              <i className="fa fa-link"></i>
            </CircleMenuItem>
          </CircleMenu>
        );
      }

      return (
        <CircleMenu
          startAngle={0}
          rotationAngle={360}
          itemSize={3}
          radius={2}
          open={true}
          menuToggleClassName="action-trigger"
          rotationAngleInclusive={false}
          menuToggleElement={<span></span>}
        >
          <CircleMenuItem
            className="action-item"
            tooltip="Details"
            onClick={() => {
              setType(MARKER_SELECTED_TYPE.INFO);
              showInfo(feature);
            }}
          >
            <i className="fa fa-info"></i>
          </CircleMenuItem>

          <CircleMenuItem
            className="action-item"
            tooltip="Network"
            onClick={() => {
              showNetwork(feature);
            }}
          >
            <i className="fa fa-link"></i>
          </CircleMenuItem>
        </CircleMenu>
      );
    }, [feature, type, verificationKey]);

    if (type === null) return null;

    return (
      <Popup
        key={verificationKey}
        className={
          type === MARKER_SELECTED_TYPE.ACTIONS
            ? "gis-popup-actions-wrapper"
            : type === MARKER_SELECTED_TYPE.INFO
            ? "gis-marker-details-popup"
            : ""
        }
        position={latlng}
        closeOnClick={true}
        autoClose={true}
        closeOnEscapeKey={true}
        eventHandlers={{
          remove: () => {
            // resetMarker(feature);
          },
        }}
      >
        {type === MARKER_SELECTED_TYPE.ACTIONS
          ? memoizedPopupActionContent
          : null}
      </Popup>
    );
  }
);

export default PopupComponentActions;
