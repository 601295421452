import { useDispatch } from "react-redux";
import { AppThunk, store } from "../index";
import { IAssetListPayload } from "./asset-list.interface";
import { assetInfoService } from "./asset-list.service";
import { setAssetInfo } from "./asset-list.slice";

// const setMapData=(data: Array<{}>): AppThunk => {
//   console.log("iiii",data);

//    dispatch(setMapData(data))
//   };

// const getMapData = async (
//   IMapInfoPayload: IMapInfoPayload,

// ) => {
//   const params = IMapInfoPayload;
//   try {
//     const response = await assetInfoService.getMapInfo(params);
//     if (response.status) {
//       console.log("response.data",response.data);

//       setMapData(response.data)

//     } else {
//       return null;
//     }
//   } catch (err) {
//     return null;
//   }
// };

export const getAssetInfo = async (data: Array<IAssetListPayload>) => {
  try {
    const response = await assetInfoService.getAssetInfo(data);
    // const dispatch = useDispatch();
    if (response.status) {
      // dispatch(setMapData(response.data.data))
      return response.data.data;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const assetInfoActions = {
  getAssetInfo,
};
